import React from 'react';
import { useQuery } from '@apollo/client';

import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

import { GET_REPORTS } from '../../_lib/graphql/queries';
import { ReportsHomeDetail } from './detail';
import { logError } from '../../_lib/error';

function ReportsHomeListSubscription({
  subscribeToMore,
}: {
  subscribeToMore: () => () => void;
}) {
  React.useEffect(() => {
    return subscribeToMore();
  }, [subscribeToMore]);
  return null;
}

export function ReportsHomeList() {
  const [reports, setReports] = React.useState<any>([]);

  const { data, loading, error, subscribeToMore } = useQuery(GET_REPORTS);

  // ---------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (data) {
      setReports(data.getReports);
    }
  }, [data, setReports]);

  React.useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  // ---------------------------------------------------------------------------------------------------

  if (loading || !data) return <Skeleton variant="rectangular" height={300} />;

  return (
    <Grid container spacing={2}>
      {reports.map((report: any) => (
        <ReportsHomeDetail key={report.id} report={report} />
      ))}
    </Grid>
  );
}
