import React from 'react';
import { useTranslation } from 'react-i18next';

import List from '@mui/material/List';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';

import { ReportsContext } from '../../../_lib/context/reports-context';

export function ReportsEditFiltersProgramme() {
  const { t } = useTranslation();

  const [programme, setProgramme] = React.useState<any>('none');

  const { report, setReport, reportData } = React.useContext(ReportsContext);

  React.useEffect(() => {
    setProgramme(report?.filterProgramme ? report.filterProgramme.id : 'none');
  }, [report]);

  const handleChange = (e: any) => {
    setReport({
      ...report,
      filterProgramme: {
        id: e.target.value,
      },
    });
  };

  if (!reportData || !reportData.dashboard) {
    return <Skeleton variant="rectangular" height={70} />;
  }

  if (!reportData.dashboard.programmes) {
    return null;
  }

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      aria-labelledby="filter-programme-list-subheader"
      subheader={
        <ListSubheader component="div" id="filter-programme-list-subheader">
          {t('Programme')}
        </ListSubheader>
      }
      dense
    >
      <ListItem>
        <FormControl fullWidth size="small">
          <InputLabel id="filter-programme-select-label">
            {t('Programme')}
          </InputLabel>
          <Select
            labelId="filter-programme-select-label"
            id="filter-programme-select"
            value={programme}
            label={t('Programme')}
            onChange={handleChange}
          >
            <MenuItem value="none" sx={{ color: 'grey.600' }}>
              <em>- {t('None')} -</em>
            </MenuItem>
            {reportData.dashboard.programmes.map((up: any) => (
              <MenuItem key={up.id} value={up.id}>
                {up.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>
    </List>
  );
}
