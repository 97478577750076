import { reverse } from 'lodash';

import { deepCopy } from './general-utils';

// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------
// aggregate priority area targets
// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------

export const aggregateTargets = (ogPriorityAreaTargets: any) => {
  // aggregate priority area targets
  const priorityAreaTargets = deepCopy(ogPriorityAreaTargets || []);

  // Aggregate targets according to the unit
  // e.g targetsByUnit['kgs'] = [{target}, {target}]
  const targetsByUnit = {} as any;
  priorityAreaTargets.forEach((t: any) => {
    const unit = t.targetUnit.name.toLowerCase().replace(/\s/g, '');

    if (targetsByUnit[unit]) targetsByUnit[unit].push(t);
    else targetsByUnit[unit] = [t];
  });

  // Change targets by unit object to an array of targets
  // and determine the time bounds from the updates.
  // ---
  // A target would be the line graph and updates would be updates
  return Object.keys(targetsByUnit).map((unit) => {
    // Create the default aggregated target
    const aggregatedTarget = {
      isAggregated: true,
      id: targetsByUnit[unit][0].id,
      targetUnit: {
        name: '',
      } as any,
      targetAmount: 0,
      targetFinancedAmount: 0,
      startAmount: 0,
      startedOn: '',
      deadline: '',
      updates: [] as any,
      updatesAll: [] as any,
      interventions: [] as any,
      priorityAreas: [] as any,
      latestAmount: 0,
      latestDate: null as any,
      partner: '',
      dashboard: {} as any,
      progressPerc: 0,
    };

    // Loop through each intervention's target and aggregate
    targetsByUnit[unit].forEach((t: any) => {
      // set id, unit, and dashboard
      aggregatedTarget.targetUnit = t.targetUnit;
      aggregatedTarget.dashboard = t.dashboard;

      // Set date extremes
      if (
        !aggregatedTarget.deadline ||
        new Date(t.deadline) > new Date(aggregatedTarget.deadline)
      ) {
        aggregatedTarget.deadline = t.deadline;
      }
      if (
        !aggregatedTarget.startedOn ||
        new Date(t.startedOn) < new Date(aggregatedTarget.startedOn)
      ) {
        aggregatedTarget.startedOn = t.startedOn;
      }

      // Sum of all targets and start amounts
      aggregatedTarget.targetAmount += t.targetAmount;
      aggregatedTarget.targetFinancedAmount += t.targetFinancedAmount;
      aggregatedTarget.startAmount += t.startAmount;

      // Add intervention and priority area to list of interventions
      if (t.intervention) {
        aggregatedTarget.interventions.push(t.intervention);
      }
      if (t.priorityArea) {
        aggregatedTarget.priorityAreas.push(t.priorityArea);
      }

      // Add latest amount and date
      if (
        aggregatedTarget.latestDate === null ||
        new Date(t.latestDate) > new Date(aggregatedTarget.latestDate)
      ) {
        aggregatedTarget.latestAmount = t.latestAmount;
        aggregatedTarget.latestDate = t.latestDate;
      }

      // Add partners
      aggregatedTarget.partner = t.partner
        ? `${aggregatedTarget.partner}, ${t.partner}`
        : aggregatedTarget.partner;

      const target = {
        updates: [] as any,
      } as any;
      // Add start amount as update
      target.updates.push({
        id: `start-${t.id}`,
        date: t.startedOn,
        value: t.startAmount,
        intervention: t.intervention,
        priorityArea: t.priorityArea,
        isTargetStart: true,
        target: t,
      });
      // Add all updates and add intervention info
      target.updates = target.updates.concat(
        t.updates.map((update: any) => ({
          ...update,
          intervention: t.intervention,
          priorityArea: t.priorityArea,
        }))
      );

      // Sort updates by date : 2021 to 2020
      target.updates = target.updates.sort(
        (a: any, b: any) =>
          new Date(b.date).valueOf() - new Date(a.date).valueOf()
      );
      target.updatesAll = target.updates;

      // push updates to aggregated target
      aggregatedTarget.updates.push(...target.updates);
    });

    // Sort updates by date : 2021 to 2020
    aggregatedTarget.updates = aggregatedTarget.updates.sort(
      (a: any, b: any) =>
        new Date(b.date).valueOf() - new Date(a.date).valueOf()
    );

    // Aggregate update values to get latest date and amount
    aggregatedTarget.latestDate = aggregatedTarget.updates[0].date;
    let cummulativeValue = 0;
    const targetUpdates = reverse(deepCopy(aggregatedTarget.updates));
    targetUpdates.forEach((update: any, i: number) => {
      if (i === 0) {
        cummulativeValue += update.value;
      } else {
        const prevTgtUpdates = targetUpdates
          .slice(0, i)
          .findLast((upd: any) => upd.target.id === update.target.id);
        const prevTgtUpdateValue = prevTgtUpdates ? prevTgtUpdates.value : 0;
        cummulativeValue += update.value - prevTgtUpdateValue;
      }
    });
    aggregatedTarget.latestAmount = cummulativeValue;

    // calculate progress percentage

    aggregatedTarget.progressPerc = 0;
    if (aggregatedTarget.targetAmount - aggregatedTarget.startAmount === 0) {
      // target amount and start amount are the same
      if (aggregatedTarget.latestAmount - aggregatedTarget.targetAmount === 0) {
        // latest amount is the same as target amount
        aggregatedTarget.progressPerc = 100;
      } else {
        // latest amount is not the same as target amount
        aggregatedTarget.progressPerc =
          ((aggregatedTarget.latestAmount - aggregatedTarget.targetAmount) /
            aggregatedTarget.targetAmount) *
          100;
      }
    } else {
      aggregatedTarget.progressPerc =
        ((aggregatedTarget.latestAmount - aggregatedTarget.startAmount) /
          (aggregatedTarget.targetAmount - aggregatedTarget.startAmount)) *
        100;
    }

    if (Number.isNaN(aggregatedTarget.progressPerc)) {
      aggregatedTarget.progressPerc = 0;
    }

    return aggregatedTarget;
  });
};

// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------
// get targets
// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------

export const getTargets = (areas: any) => {
  return areas.flatMap((area: any) => {
    return aggregateTargets(deepCopy(area?.targets));
  });
};
