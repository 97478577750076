import React from 'react';

import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';
import LinearProgress from '@mui/material/LinearProgress';

import { ScrollToTop } from './scroll-to-top';
import { DashboardNavbar } from '../navbar/dashboard-navbar';
import { Snackbar } from '../components/snackbar';

import { DashboardContext } from '../_lib/context/dashboard-context';

import { ParentDashboardAlert } from '../components/parent-dashboard-alert';

export function BaseLayout({ children }: any) {
  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarIsDelete,
    setSnackbarIsDelete,
    snackbarIsError,
    setSnackbarIsError,
    snackbarMessage,
    setSnackbarMessage,
    loading,
    setLoading,
  } = React.useContext(DashboardContext);

  React.useEffect(() => {
    setLoading(false);
  });

  return (
    <>
      <style>{`html, body { background-color: ${grey[200]}; }`}</style>
      <ScrollToTop>
        {/* navbar */}
        <DashboardNavbar />

        {loading && <LinearProgress />}

        {/* dashboard of dashboards alert */}
        <ParentDashboardAlert includeContainer />

        <Box component="main">{children}</Box>
      </ScrollToTop>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        isDelete={snackbarIsDelete}
        setIsDelete={setSnackbarIsDelete}
        isError={snackbarIsError}
        setIsError={setSnackbarIsError}
        message={snackbarMessage}
        setMessage={setSnackbarMessage}
      />
    </>
  );
}
