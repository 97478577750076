import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { BreadcrumbLayout } from './_layout';
import { DashboardContext } from '../_lib/context/dashboard-context';

export function ActivityTrackerBreadcrumb() {
  const { t } = useTranslation();

  const location = useLocation();
  const { dashboard } = React.useContext(DashboardContext);

  const isPlanning = location.pathname.startsWith(t('/planning'));
  const homeRoute =
    ((isPlanning || dashboard.inPlanning) && t('/planning')) || '/';

  const planningTeamsRoute = `${t('/planning')}/${dashboard.priorityAreasSlug}`;
  const isInPlanningTeamsRoute =
    location.pathname.startsWith(planningTeamsRoute);

  return (
    <BreadcrumbLayout id="activity-tracker-breadcrumb">
      <Link
        id="home-crumb"
        component={RouterLink}
        color={!isInPlanningTeamsRoute ? 'text.primary' : 'inherit'}
        to={homeRoute}
      >
        <HomeRoundedIcon />
      </Link>

      {!!isPlanning && !!isInPlanningTeamsRoute && (
        <Typography color="text.primary">
          {dashboard.priorityAreaName}
        </Typography>
      )}
      {!isPlanning && location.pathname !== '/' && (
        <Typography color="text.primary">{t('Tracker')}</Typography>
      )}
    </BreadcrumbLayout>
  );
}

export default ActivityTrackerBreadcrumb;
