import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { LoadingButton } from '@mui/lab';
import MenuItem from '@mui/material/MenuItem';

import { DashboardContext } from '../../../_lib/context/dashboard-context';
import { DELETE_REPORT } from '../../../_lib/graphql/mutations';
import { logError } from '../../../_lib/error';

export function ReportsEditActionsDelete({
  reportId,
  loading,
  setLoading,
  isMoreMenuItem,
  handleMoreMenuClose,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const { setSnackbarIsDelete, setSnackbarOpen } = useContext(DashboardContext);

  const [deleteReportMutation] = useMutation(DELETE_REPORT);

  const handleDelete = () => {
    if (setLoading) setLoading(true);
    if (handleMoreMenuClose) handleMoreMenuClose();

    deleteReportMutation({
      variables: {
        id: reportId,
      },
    })
      .then((r: any) => {
        if (r.data.deleteReport.success) {
          setSnackbarIsDelete(true);
          setSnackbarOpen(true);
          history.push(t('/reports'));
        }
      })
      .catch((e: any) => {
        logError(e);
      })
      .finally(() => {
        if (setLoading) setLoading(false);
      });
  };
  return (
    <>
      {!isMoreMenuItem && (
        <LoadingButton
          variant="outlined"
          color="error"
          onClick={handleDelete}
          loading={loading}
        >
          {t('Move to trash')}
        </LoadingButton>
      )}
      {isMoreMenuItem && (
        <MenuItem onClick={handleDelete}>{t('Delete')}</MenuItem>
      )}
    </>
  );
}
