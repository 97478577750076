import createDashboard from './mutations/create-dashboard.graphql';
import launchDashboard from './mutations/launch-dashboard.graphql';
import setupDashboard from './mutations/setup-dashboard.graphql';
import deleteFinancier from './mutations/delete-financier.graphql';
import deleteTarget from './mutations/delete-target.graphql';
import changeContent from './mutations/change-content.graphql';
import deleteContent from './mutations/delete-content.graphql';
import createContentUpdate from './mutations/create-content-update.graphql';
import changeContentUpdate from './mutations/change-content-update.graphql';
import acceptContentUpdate from './mutations/accept-content-update.graphql';
import rejectContentUpdate from './mutations/reject-content-update.graphql';
import deleteContentUpdate from './mutations/delete-content-update.graphql';
import markUpdatesAsViewed from './mutations/mark-updates-as-viewed.graphql';

import switchDashboard from './mutations/switch-dashboard.graphql';
import switchMember from './mutations/switch-member.graphql';

import createContent from './mutations/create-content.graphql';

import createUser from './mutations/accounts/create-user.graphql';
import changeUserDetails from './mutations/accounts/change-user-details.graphql';
import changePassword from './mutations/accounts/change-password.graphql';

import dashboardUpdate from './mutations/dashboard/update.graphql';

import setupPriorityArea from './mutations/area/priority-area/setup-priority-area.graphql';
import priorityAreaMove from './mutations/area/priority-area/move.graphql';
import priorityAreaDelete from './mutations/area/priority-area/delete.graphql';
import createPriorityArea from './mutations/area/priority-area/create.graphql';
import priorityAreasUpdateOrder from './mutations/area/priority-area/update-order.graphql';

import setupIntervention from './mutations/area/intervention/setup-intervention.graphql';
import createIntervention from './mutations/area/intervention/create-intervention.graphql';
import interventionMove from './mutations/area/intervention/move.graphql';
import interventionDelete from './mutations/area/intervention/delete.graphql';
import createInterventions from './mutations/area/intervention/multi-create.graphql';

import areaPermissionAdd from './mutations/area/permission/add.graphql';
import areaPermissionUpdate from './mutations/area/permission/update.graphql';
import areaPermissionRemove from './mutations/area/permission/remove.graphql';

import interventionStatusCreate from './mutations/dashboard/intervention-status/create.graphql';
import interventionStatusUpdate from './mutations/dashboard/intervention-status/update.graphql';
import interventionStatusDelete from './mutations/dashboard/intervention-status/delete.graphql';
import interventionStatusUpdateOrder from './mutations/dashboard/intervention-status/update-order.graphql';

import targetUpdateCreate from './mutations/target/update-create.graphql';
import targetUpdateDelete from './mutations/target/update-delete.graphql';

import contentBlockerCreate from './mutations/content/blocker/create.graphql';
import contentBlockerEdit from './mutations/content/blocker/edit.graphql';
import contentBlockerComplete from './mutations/content/blocker/complete.graphql';
import contentBlockerDelete from './mutations/content/blocker/delete.graphql';
import contentBlockersUpdateOrder from './mutations/content/blocker/update-order.graphql';

import updateReport from './mutations/report/update.graphql';
import deleteReport from './mutations/report/delete.graphql';

import programmeCreate from './mutations/content/programme/create.graphql';
import programmeUpdate from './mutations/content/programme/update.graphql';
import programmeDelete from './mutations/content/programme/delete.graphql';
import programmeUpdateOrder from './mutations/content/programme/update-order.graphql';
import programmeInterventionsUpdate from './mutations/content/programme/interventions-update.graphql';

import contentGroupCreate from './mutations/dashboard/content-group/create.graphql';
import contentGroupUpdate from './mutations/dashboard/content-group/update.graphql';
import contentGroupDelete from './mutations/dashboard/content-group/delete.graphql';
import contentGroupUpdateOrder from './mutations/dashboard/content-group/update-order.graphql';

import areaLevelCreate from './mutations/area/area-level/create.graphql';
import areaLevelUpdate from './mutations/area/area-level/update.graphql';
import areaLevelDelete from './mutations/area/area-level/delete.graphql';
import areaLevelUpdateOrder from './mutations/area/area-level/update-order.graphql';

export const DELETE_DONOR = deleteFinancier;
export const DELETE_TARGET = deleteTarget;
export const CREATE_CONTENT = createContent;
export const CHANGE_CONTENT = changeContent;
export const DELETE_CONTENT = deleteContent;
export const CREATE_CONTENT_UPDATE = createContentUpdate;
export const CHANGE_CONTENT_UPDATE = changeContentUpdate;
export const ACCEPT_CONTENT_UPDATE = acceptContentUpdate;
export const REJECT_CONTENT_UPDATE = rejectContentUpdate;
export const DELETE_CONTENT_UPDATE = deleteContentUpdate;
export const MARK_UPDATES_AS_VIEWED = markUpdatesAsViewed;

export const CREATE_USER = createUser;
export const CHANGE_USER_DETAILS = changeUserDetails;
export const CHANGE_PASSWORD = changePassword;

export const SWITCH_DASHBOARD = switchDashboard;
export const SWITCH_MEMBER = switchMember;

export const DASHBOARD_UPDATE = dashboardUpdate;
export const CREATE_DASHBOARD = createDashboard;
export const LAUNCH_DASHBOARD = launchDashboard;
export const SETUP_DASHBOARD = setupDashboard;

export const AREA_PERMISSION_ADD = areaPermissionAdd;
export const AREA_PERMISSION_UPDATE = areaPermissionUpdate;
export const AREA_PERMISSION_REMOVE = areaPermissionRemove;

export const CREATE_PRIORITY_AREA = createPriorityArea;
export const PRIORITY_AREA_MOVE = priorityAreaMove;
export const PRIORITY_AREA_DELETE = priorityAreaDelete;
export const SETUP_PRIORITY_AREA = setupPriorityArea;
export const PRIORITY_AREAS_UPDATE_ORDER = priorityAreasUpdateOrder;

export const INTERVENTION_MOVE = interventionMove;
export const INTERVENTION_DELETE = interventionDelete;
export const CREATE_INTERVENTION = createIntervention;
export const CREATE_INTERVENTIONS = createInterventions;
export const SETUP_INTERVENTION = setupIntervention;

export const INTERVENTION_STATUS_CREATE = interventionStatusCreate;
export const INTERVENTION_STATUS_UPDATE = interventionStatusUpdate;
export const INTERVENTION_STATUS_DELETE = interventionStatusDelete;
export const INTERVENTION_STATUS_UPDATE_ORDER = interventionStatusUpdateOrder;

export const TARGET_UPDATE_CREATE = targetUpdateCreate;
export const TARGET_UPDATE_DELETE = targetUpdateDelete;

export const CONTENT_BLOCKER_CREATE = contentBlockerCreate;
export const CONTENT_BLOCKER_EDIT = contentBlockerEdit;
export const CONTENT_BLOCKER_COMPLETE = contentBlockerComplete;
export const CONTENT_BLOCKER_DELETE = contentBlockerDelete;
export const CONTENT_BLOCKERS_UPDATE_ORDER = contentBlockersUpdateOrder;

export const UPDATE_REPORT = updateReport;
export const DELETE_REPORT = deleteReport;

export const PROGRAMME_CREATE = programmeCreate;
export const PROGRAMME_UPDATE = programmeUpdate;
export const PROGRAMME_DELETE = programmeDelete;
export const PROGRAMME_UPDATE_ORDER = programmeUpdateOrder;
export const PROGRAMME_INTERVENTIONS_UPDATE = programmeInterventionsUpdate;

export const CONTENT_GROUP_CREATE = contentGroupCreate;
export const CONTENT_GROUP_UPDATE = contentGroupUpdate;
export const CONTENT_GROUP_DELETE = contentGroupDelete;
export const CONTENT_GROUP_UPDATE_ORDER = contentGroupUpdateOrder;

export const AREA_LEVEL_CREATE = areaLevelCreate;
export const AREA_LEVEL_UPDATE = areaLevelUpdate;
export const AREA_LEVEL_DELETE = areaLevelDelete;
export const AREA_LEVEL_UPDATE_ORDER = areaLevelUpdateOrder;
