import { ReportsPdfDocument } from './ui/document';
import { PdftTemplatePreziCountryPageOverview } from './pages/overview';
import { PdftTemplatePreziCountryPageArea } from './pages/area';

export function ReportsPdfTemplatesPreziCountryDocument({
  dashboard,
  user,
  isNew,
  report,
  reportData,
  filterAreas,
  t,
}: any) {
  const orderedFilterAreas = [] as any;

  function pushOrderAreas(pa: any) {
    if (filterAreas.includes(`pa-${pa.id}`)) {
      orderedFilterAreas.push(`pa-${pa.id}`);
    }

    // children
    pa.children.forEach((child: any) => {
      if (filterAreas.includes(`pa-${child.id}`)) {
        orderedFilterAreas.push(`pa-${child.id}`);
      }
    });

    // interventions
    pa.interventions?.forEach((intv: any) => {
      if (filterAreas.includes(`intv-${intv.id}`)) {
        orderedFilterAreas.push(`intv-${intv.id}`);
      }
    });
  }

  reportData.priorityAreas.forEach((pa: any) => {
    if (orderedFilterAreas.includes(`pa-${pa.id}`)) return; // already pushed
    pushOrderAreas(pa);
  });

  const isFiltered =
    filterAreas.length !==
    reportData.priorityAreas.flatMap((pa: any) => pa.interventions).length +
      reportData.priorityAreas.length;

  return (
    <ReportsPdfDocument dashboard={dashboard} user={user} report={report}>
      {!isFiltered && (
        <PdftTemplatePreziCountryPageOverview
          dashboard={dashboard}
          user={user}
          isNew={isNew}
          report={report}
          reportData={reportData}
          filterAreas={filterAreas}
          t={t}
        />
      )}
      {orderedFilterAreas.map((filterArea: any) => (
        <PdftTemplatePreziCountryPageArea
          key={filterArea}
          filterArea={filterArea}
          dashboard={dashboard}
          user={user}
          isNew={isNew}
          report={report}
          reportData={reportData}
          filterAreas={filterAreas}
          t={t}
        />
      ))}
    </ReportsPdfDocument>
  );
}
