import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name || 'avatar'),
    },
    children: `${name?.split(' ')[0][0]}${
      name?.split(' ')[1] ? name?.split(' ')[1][0] : ''
    }`,
  };
}

export default function BackgroundLetterAvatar({ name, includeName }: any) {
  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <Tooltip title={name} arrow disableHoverListener={includeName}>
        <Avatar {...stringAvatar(name)} />
      </Tooltip>
      {!!includeName && (
        <Typography
          component="span"
          variant="body1"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pl: 1,
          }}
        >
          {name}
        </Typography>
      )}
    </Box>
  );
}
