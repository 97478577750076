import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

export function ContextLoading() {
  const { t } = useTranslation();

  const [showLoading, setShowLoading] = React.useState(false);
  const [showReload, setShowReload] = React.useState(false);

  const handleReload = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  React.useEffect(() => {
    const loadingTimer = setTimeout(() => setShowLoading(true), 3000);
    const reloadTimer = setTimeout(() => setShowReload(true), 10000);
    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(reloadTimer);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <LinearProgress />

      <Box
        sx={{
          my: 'auto',
          textAlign: 'center',
          py: 3,
          px: 2,
        }}
      >
        <Stack spacing={2}>
          <Fade in={showLoading}>
            <Typography variant="body1" color="primary">
              {t('Loading')}...
            </Typography>
          </Fade>
          <Fade in={showReload}>
            <Box>
              <Button
                variant="outlined"
                startIcon={<CachedRoundedIcon />}
                onClick={handleReload}
              >
                {t('Reload')}
              </Button>
            </Box>
          </Fade>
        </Stack>
      </Box>
    </Box>
  );
}
