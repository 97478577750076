import React from 'react';
import { useTranslation } from 'react-i18next';

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import { useOnlineStatus } from './use-online-status';

export function OfflineSnackbar() {
  const { t } = useTranslation();

  const isOnline = useOnlineStatus();

  const [open, setOpen] = React.useState(false);

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(!isOnline);
  }, [isOnline]);

  // ----------------------------------------------------------------------------------------------------

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      message={t('You are currently offline.')}
      color="warning"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      action={
        <>
          <Button
            color="secondary"
            size="small"
            onClick={() => window.location.reload()}
          >
            {t('Reconnect')}
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
}
