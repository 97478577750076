import { View, Text } from '@react-pdf/renderer';
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from '@david.kucsai/react-pdf-table';

import { Page } from '../page';
import { getStringDate } from '../../../_utils/general-utils';

export function ReportsPdfPagesMembers({ t, reportData, dashboard }: any) {
  const members = reportData.dashboard.users.map((user: any) => {
    return {
      name: user.user.name,
      email: user.user.email,
      isAdmin: user.isAdmin,
      lastLogin: user.user.lastLogin,
    };
  });

  return (
    <Page>
      <View>
        <Text style={{ fontSize: 25, marginBottom: 10 }}>
          {t('Members Info')}
        </Text>
        <Table data={members}>
          <TableHeader>
            <TableCell style={{ padding: '5px' }} weighting={0.4}>
              {t('Name')}
            </TableCell>
            <TableCell style={{ padding: '5px' }}>{t('Email')}</TableCell>
            <TableCell
              style={{ padding: '5px', textAlign: 'center' }}
              weighting={0.2}
            >
              {t('Is Admin')}
            </TableCell>
            <TableCell
              style={{ padding: '5px', textAlign: 'center' }}
              weighting={0.3}
            >
              {t('Last Login')}
            </TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell
              style={{ padding: '5px' }}
              weighting={0.4}
              getContent={(r) => r.name}
            />
            <DataTableCell
              style={{ padding: '5px' }}
              getContent={(r) => r.email}
            />
            <DataTableCell
              style={{ padding: '5px', textAlign: 'center' }}
              weighting={0.2}
              getContent={(r) => (r.isAdmin ? t('Yes') : t('No'))}
            />
            <DataTableCell
              style={{ padding: '5px', textAlign: 'center' }}
              weighting={0.3}
              getContent={(r) =>
                r.lastLogin
                  ? getStringDate(r.lastLogin, dashboard.language, true)
                  : t('Unknown')
              }
            />
          </TableBody>
        </Table>
      </View>
    </Page>
  );
}
