import React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export interface DialogTitleProps {
  id?: string;
  children?: React.ReactNode | null;
  onClose?: () => void;
  className?: any;
  variant?: any;
  sx?: any;
  disabled?: boolean;
}

export function DialogTitle(props: DialogTitleProps) {
  const { children, onClose, sx, disabled, ...other } = props;
  return (
    <MuiDialogTitle
      sx={{
        m: 0,
        py: 2,
        pl: 2,
        paddingRight: '48px',
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disabled={disabled}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

DialogTitle.defaultProps = {
  id: 'dialog-title',
  children: undefined,
  onClose: undefined,
  className: undefined,
  variant: 'h6',
  sx: undefined,
  disabled: false,
};
