import { CsvBuilder } from 'filefy';

function downloadFile(url: string, fileName: string) {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);

  link.click();
}

function downloadCsv(columns: string[], rows: string[][], fileName: string) {
  const builder = new CsvBuilder(fileName);
  builder.setDelimeter(',').setColumns(columns).addRows(rows).exportFile();
}

function getColumns(dataRowObj: any[]) {
  return Object.keys(dataRowObj).filter(
    (key) => key !== '__typename' && key !== 'percDone' && key !== 'id'
  );
}

function getRows(columns: string[], dataRowsObj: any[]) {
  return dataRowsObj.map((row: any) =>
    columns.map((key) => {
      return row[key];
    })
  );
}

function getAreaRows(columns: string[], dataRowsObj: any[]) {
  return dataRowsObj.map((area: any) =>
    columns.map((key) => {
      if (key === 'lead') {
        return area.lead.name;
      }
      if (key === 'coLead') {
        return area.coLead.name;
      }
      if (key === 'targets') {
        return area.targets ? area.targets.length : 0;
      }
      if (key === 'interventions') {
        return !area.interventions && area.interventions.length !== 0
          ? `${area.interventions.length} : ${area.interventions
              .map((intervention: any) => intervention.name)
              .join(', ')}`
          : '0';
      }
      return area[key];
    })
  );
}

function getTargetRows(columns: string[], dataRowsObj: any[]) {
  return dataRowsObj.map((target: any) =>
    columns.map((key) => {
      if (key === 'updates') {
        return target.updates ? target.updates.length : 0;
      }
      return target[key];
    })
  );
}

function getContentRows(columns: string[], dataRowsObj: any[]) {
  return dataRowsObj.map((content: any) =>
    columns.map((key) => {
      if (key === 'intervention') {
        return content.intervention ? content.intervention.name : '';
      }
      if (key === 'updates') {
        return content.updates ? content.updates.length : 0;
      }
      if (key === 'author' || key === 'assignee') {
        return content[key] ? content[key].name : '';
      }
      return content[key];
    })
  );
}

export const handleReportDownload = ({
  type,
  report,
  reportData,
  documentBlob,
}: any) => {
  const url = window.URL.createObjectURL(documentBlob);
  downloadFile(url, 'report.pdf');

  if (type === 'pdf') return;

  // dashboard
  if (reportData.dashboard) {
    const columns = getColumns(reportData.dashboard).filter(
      (key) => key !== 'logo'
    );
    downloadCsv(
      columns,
      [
        columns.map((key) => {
          if (key === 'users') {
            return reportData.dashboard[key].length;
          }
          return reportData.dashboard[key];
        }),
      ],
      'dashboard.csv'
    );

    // dashboard members
    if (!report.filterMembers) {
      const members = reportData.dashboard.users.map((user: any) => ({
        name: user.user.name,
        email: user.user.email,
        isAdmin: user.isAdmin,
      }));
      const membersColumns = getColumns(members[0]);
      downloadCsv(
        membersColumns,
        getRows(membersColumns, members),
        'members.csv'
      );
    }
  }

  // priority areas
  if (reportData.priorityAreas) {
    const areas = reportData.priorityAreas;
    const areasColumns = getColumns(areas[0]).filter(
      (key) => key !== 'summary'
    );
    downloadCsv(
      areasColumns,
      getAreaRows(areasColumns, areas),
      'priority-areas.csv'
    );

    // interventions
    const interventions = areas.reduce((acc: any, area: any) => {
      return [...acc, ...area.interventions];
    }, []);
    if (interventions.length > 0) {
      const interventionsColumns = getColumns(interventions[0]).filter(
        (key) => key !== 'summary'
      );
      downloadCsv(
        interventionsColumns,
        getAreaRows(interventionsColumns, interventions),
        'interventions.csv'
      );
    }

    // targets
    const targets = areas.reduce((acc: any, area: any) => {
      return [
        ...acc,
        ...area.targets.map((target: any) => ({
          ...target,
          area: area.name,
          isPriorityArea: true,
          isIntervention: false,
        })),
      ];
    }, []);
    targets.push(
      ...interventions.reduce((acc: any, area: any) => {
        return [
          ...acc,
          ...area.targets.map((target: any) => ({
            ...target,
            area: area.name,
            isPriorityArea: false,
            isIntervention: true,
          })),
        ];
      }, [])
    );
    if (targets.length > 0) {
      const targetsColumns = getColumns(targets[0]).filter(
        (key) => key !== 'intervention'
      );
      downloadCsv(
        targetsColumns,
        getTargetRows(targetsColumns, targets),
        'targets.csv'
      );
    }
  }

  // todo: find a better way to download this
  // content
  // if (reportData.getReportContent) {
  //   const content = reportData.getReportContent;
  //   const columns = getColumns(content[0]);
  //   downloadCsv(columns, getContentRows(columns, content), 'content.csv');
  // }
};
