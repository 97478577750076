export const routesPermissions = ({
  user,
  dashboard,
  requestedUrl,
  redirect,
  t,
  allowedRoutes,
}: any) => {
  const { priorityAreasSlug, interventionsSlug } = dashboard;

  const defaultRoutes = [t('/my-content')];

  let urlType = 'regular';

  // ----------------------------------------------------------------------------------------------------
  // set url type
  if (dashboard?.inPlanning) {
    // in planning
    if (user.isAdmin) {
      if (dashboard.mode === 'TRACKER') {
        urlType = 'activity-tracker-planning';
      } else {
        urlType = 'full-dashboard-planning';
      }
    }
  } else if (dashboard.mode === 'TRACKER') {
    // activity tracker only
    if (user.isAdmin) {
      urlType = 'activity-tracker-admin';
    } else if (user.priorityArea) {
      urlType = 'activity-tracker-team-lead';
    }
  } else if (user.isAdmin) {
    urlType = 'full-dashboard-admin';
  } else if (user.priorityAreas || user.interventions) {
    urlType = 'full-dashboard';
  }

  // ----------------------------------------------------------------------------------------------------
  // use url type to determine allowed routes
  if (
    urlType === 'full-dashboard' ||
    urlType === 'full-dashboard-admin' ||
    urlType === 'full-dashboard-planning'
  ) {
    const { priorityAreas } = user;
    const { interventions } = user;

    if (urlType !== 'full-dashboard-planning') {
      if (urlType === 'full-dashboard-admin' || priorityAreas?.length > 1) {
        allowedRoutes.push('/');
      }

      priorityAreas?.forEach((priorityArea: any) => {
        allowedRoutes.push(priorityArea.urlRelative);
        allowedRoutes.push(`${priorityArea.urlRelative}${t('/tracker')}`);
      });
      interventions.forEach((i: any) => allowedRoutes.push(i.urlRelative));
    }

    if (
      urlType === 'full-dashboard-admin' ||
      urlType === 'full-dashboard-planning'
    ) {
      // reports
      if (
        urlType === 'full-dashboard-admin' &&
        requestedUrl.startsWith(t('/reports'))
      )
        return;

      // planning
      allowedRoutes.push(t('/planning'));
      allowedRoutes.push(`${t('/planning')}${t('/dashboard')}`);
      allowedRoutes.push(
        `${t('/planning')}${t('/dashboard')}${t('/advanced')}`
      );
      allowedRoutes.push(`${t('/planning')}${t('/dashboard')}${t('/members')}`);

      allowedRoutes.push(`${t('/planning')}/${dashboard.priorityAreasSlug}`);
      allowedRoutes.push(`${t('/planning')}/${dashboard.areaLevels[0].slug}`);

      priorityAreas?.forEach((pa: any) => {
        if (pa.levelNext) {
          allowedRoutes.push(`${t('/planning')}${pa.urlRelative}`);
          allowedRoutes.push(
            `${t('/planning')}${pa.urlRelative}/${pa.levelNext.slug}`
          );
        }
        allowedRoutes.push(`${t('/planning')}${pa.urlRelativeLevel}`);
        allowedRoutes.push(
          `${t('/planning')}${pa.urlRelative}/${interventionsSlug}`
        );
      });
      interventions?.forEach((intv: any) => {
        allowedRoutes.push(`${t('/planning')}${intv.urlRelative}`);
        allowedRoutes.push(
          `${t('/planning')}${intv.urlRelative}${t('/milestones')}`
        );
      });
    }
  } else if (urlType === 'activity-tracker-team-lead') {
    // Activity Tracker Team Lead:
    // - Similar to priority area lead
    // - Should be able to see activities assigned to their team
    allowedRoutes.push('/tracker');
  } else if (urlType === 'activity-tracker-planning') {
    // Activity Tracker Admin (Still Planning):
    //  - Able to make edits to settings
    allowedRoutes.push(
      ...[t('/planning'), `${t('/planning')}/${priorityAreasSlug}`]
    );
  } else if (urlType === 'activity-tracker-admin') {
    // Activity Tracker Admin:
    // - should be able to edit everything
    allowedRoutes.push(
      ...[
        '/',
        t('/tracker'),
        t('/planning'),
        `${t('/planning')}/${priorityAreasSlug}`,
      ]
    );
  }

  const allRoutes = [];
  allowedRoutes.forEach((route: any) => {
    allRoutes.push(route);
    allRoutes.push(`${route}/`);
  });
  allRoutes.push(...defaultRoutes);

  if (!allRoutes.includes(requestedUrl)) redirect(allRoutes[0]);
};
