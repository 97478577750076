import React from 'react';
import { useQuery } from '@apollo/client';

import {
  GET_AREA_PERMISSIONS,
  INTERVENTIONS,
  PRIORITY_AREA,
  PRIORITY_AREA_TRACKER,
} from '../../queries';
import {
  AREA_PERMISSIONS_SUBSCRIPTION,
  INTERVENTIONS_SUBSCRIPTION,
  PRIORITY_AREA_SUBSCRIPTION,
  PRIORITY_AREA_TRACKER_SUBSCRIPTION,
} from '../../subscriptions';

import { GraphqlLoadDataQueriesIntervention } from './intervention';

export function GraphqlLoadDataQueriesPriorityArea({ priorityAreaId }: any) {
  const {
    data: dataPriorityArea,
    subscribeToMore: subscribeToMorePriorityArea,
  } = useQuery(PRIORITY_AREA, {
    variables: {
      priorityAreaId,
    },
  });

  const {
    data: dataPriorityAreaTracker,
    subscribeToMore: subscribeToMorePriorityAreaTracker,
  } = useQuery(PRIORITY_AREA_TRACKER, {
    variables: {
      priorityAreaId,
    },
  });

  const { data: dataIntvs, subscribeToMore: subscribeToMoreIntvs } = useQuery(
    INTERVENTIONS,
    {
      variables: { priorityAreaId },
    }
  );

  const {
    data: dataAreaPermissions,
    subscribeToMore: subscribeToMoreAreaPermissions,
  } = useQuery(GET_AREA_PERMISSIONS, {
    variables: {
      areaType: 'PriorityAreaType',
      areaId: priorityAreaId,
    },
  });

  React.useEffect(() => {
    subscribeToMorePriorityArea({
      document: PRIORITY_AREA_SUBSCRIPTION,
      variables: {
        priorityAreaId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });

    subscribeToMorePriorityAreaTracker({
      document: PRIORITY_AREA_TRACKER_SUBSCRIPTION,
      variables: {
        priorityAreaId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });

    subscribeToMoreIntvs({
      document: INTERVENTIONS_SUBSCRIPTION,
      variables: {
        priorityAreaId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });

    subscribeToMoreAreaPermissions({
      document: AREA_PERMISSIONS_SUBSCRIPTION,
      variables: {
        areaType: 'PriorityAreaType',
        areaId: priorityAreaId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* {dataPriorityArea?.priorityArea?.interventions?.map(
        (intervention: any) => (
          <GraphqlLoadDataQueriesIntervention
            key={intervention.id}
            interventionId={intervention.id}
          />
        )
      )} */}
      {/* other */}
    </>
  );
}
