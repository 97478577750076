import { alpha, styled } from '@mui/material/styles';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useOnlineStatus } from '../../_lib/offline/use-online-status';

const DarkTextField: typeof TextField | any = styled(TextField)(
  ({ theme }) => ({
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    'label, legend, & .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused, & .MuiOutlinedInput-root, & .MuiButtonBase-root':
      {
        color: theme.palette.common.white,
      },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  })
);

export function DashboardAppBarFilter({
  isDark,
  label,
  options,
  selectedFilters,
  onChange,
}: any) {
  const isOnline = useOnlineStatus();

  if (!options || options.length === 0) return null;

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      disabled={!isOnline}
      options={options}
      getOptionLabel={(option: any) => `${option.typeLabel}: ${option.name}`}
      value={selectedFilters}
      isOptionEqualToValue={(option: any, value: any) => {
        if (option.id) return option.id === value.id;
        return option.type === value.type && option.name === value.name;
      }}
      filterSelectedOptions
      size="small"
      fullWidth
      limitTags={1}
      renderTags={(value, getTagProps) =>
        value.map((option: any, index) => (
          <Chip
            variant="outlined"
            label={
              <>
                <small
                  style={{
                    opacity: 0.7,
                    textTransform: 'uppercase',
                  }}
                >
                  {option.typeLabel}:
                </small>{' '}
                {option.name}
              </>
            }
            size="small"
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) =>
        isDark ? (
          <DarkTextField {...params} label={label} placeholder={label} />
        ) : (
          <TextField {...params} label={label} placeholder={label} />
        )
      }
      renderOption={(props, option: any) => (
        <li {...props}>
          <Box>
            <Typography
              variant="caption"
              sx={{
                opacity: 0.7,
                textTransform: 'uppercase',
              }}
            >
              {option.typeLabel}
            </Typography>
            <Typography variant="body2">{option.name}</Typography>
          </Box>
        </li>
      )}
      onChange={onChange}
      sx={{
        '& .MuiAutocomplete-tag': {
          maxWidth: isDark && 'calc(100% - 70px)',
        },
      }}
    />
  );
}
