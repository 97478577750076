import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

const TrackerLandingLoader = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'tracker-landing' */
      './routes/tracker-landing-route'
    )
);
const ActivityTrackerLoader = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'activity-tracker' */
      './routes/activity-tracker-route'
    )
);
const DashboardPlanningLoader = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'dashboard-planning' */
      './routes/planning-routes'
    )
);

const MyContentLoader = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'my-content' */
      './routes/my-content-route'
    )
);

export function ActivityTrackerRouter() {
  const { t } = useTranslation();

  return (
    <Switch>
      <Route exact path="/" component={TrackerLandingLoader} />
      <Route exact path={t('/tracker')} component={ActivityTrackerLoader} />

      <Route path={t('/planning')} component={DashboardPlanningLoader} />

      <Route exact path={t('/my-content')} component={MyContentLoader} />
    </Switch>
  );
}

export default ActivityTrackerRouter;
