import { View, Text } from '@react-pdf/renderer';

export function ReportsPdfTemplatesPreziCountryUiBoxed({
  width,
  title,
  content,
  flexDirection,
  titleBgColor,
  titleColor,
  titleBorder,
  titleJustifyContent,
  titleAlignItems,
  titleTextAlign,
  titleFlexGrow,
  titleFlex,
  contentBorder,
  contentTextAlign,
  contentJustifyContent,
  contentAlignItems,
  contentFlexGrow,
  contentFlex,
  spacing,
  wrap,
}: any) {
  const spacingPx = spacing ? `${spacing * 4}px` : 0;
  const spacingPxhalf = spacing ? `${spacing * 2}px` : 0;

  return (
    <View style={{ width, display: 'flex', flexDirection }} wrap={wrap}>
      <View
        style={{
          backgroundColor: titleBgColor || '#eeeeee',
          color: titleColor || '#424242',
          width:
            flexDirection === 'row' ? `calc(50% - ${spacingPxhalf})` : '100%',
          padding: '5px',
          display: 'flex',
          justifyContent: titleJustifyContent,
          alignItems: titleAlignItems,
          textAlign: titleTextAlign,
          flexGrow: titleFlexGrow,
          flex: titleFlex,
        }}
      >
        <Text style={{ fontWeight: 'bold' }}>{title || 'Title Goes Here'}</Text>
      </View>
      {spacing && (
        <View
          style={{
            width: flexDirection === 'row' ? spacingPx : '100%',
            height: flexDirection === 'row' ? '100%' : spacingPx,
          }}
        />
      )}
      <View
        style={{
          border: contentBorder || '2px solid #333333',
          padding: '5px',
          width:
            flexDirection === 'row' ? `calc(50% - ${spacingPxhalf})` : '100%',
          display: 'flex',
          justifyContent: contentJustifyContent,
          alignItems: contentAlignItems,
          textAlign: contentTextAlign,
          flexGrow: contentFlexGrow,
          flex: contentFlex,
        }}
      >
        {!content ? (
          <Text>Content goes here</Text>
        ) : typeof content === 'string' ? (
          <Text>{content}</Text>
        ) : (
          content
        )}
      </View>
    </View>
  );
}

ReportsPdfTemplatesPreziCountryUiBoxed.defaultProps = {
  flexDirection: 'row',
  titleFlexGrow: 1,
  titleJustifyContent: 'center',
  titleAlignItems: 'center',
  titleTextAlign: 'center',
  contentFlexGrow: 0,
  contentJustifyContent: 'center',
  contentAlignItems: 'center',
  contentTextAlign: 'center',
};

export default ReportsPdfTemplatesPreziCountryUiBoxed;
