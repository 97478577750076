import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ReportsEditActionsDownload from './download/button';
import { ReportsEditActionsDelete } from './delete';
import { ReportsContext } from '../../../_lib/context/reports-context';

export function ReportsEditActions({ loading, setLoading }: any) {
  const { t } = useTranslation();
  const { report } = useContext(ReportsContext);
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t('Status & Visibility')}
      </Typography>

      {/* TODO */}
      {/* <Box>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label={`${t('Pin to top')}`}
            sx={{ mb: 2 }}
          />
        </Box> */}

      <Stack spacing={2}>
        <ReportsEditActionsDownload />
        {!!report && report.id !== 'new' && (
          <ReportsEditActionsDelete
            reportId={report.id}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </Stack>
    </Box>
  );
}
