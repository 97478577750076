import { View, Text, Image } from '@react-pdf/renderer';

import grey from '@mui/material/colors/grey';

import { Page } from '../../../page';
import { getStringDate } from '../../../../../_utils/general-utils';

export function ReportsPdfTemplatesSpirePagesTitle({
  t,
  dashboard,
  report,
  startDate,
  endDate,
}: any) {
  // logo
  let logo = '/static/images/tbilogo.jpg';
  let showLogo = true;
  if (!dashboard.logo.startsWith('/static/images/tbilogo.jpg')) {
    logo = dashboard.logo;
    const logoUrl = new URL(logo);
    if (
      !logoUrl.pathname.endsWith('.png') &&
      !logoUrl.pathname.endsWith('.jpg')
    ) {
      showLogo = false;
    }
  }

  // report date
  const reportDate = getStringDate(new Date().toString(), dashboard.language);

  return (
    <Page>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        {showLogo && !!logo && (
          <Image
            src={logo}
            style={{ width: 70, height: 70, marginBottom: 20 }}
          />
        )}
        <Text style={{ fontSize: 30, fontWeight: 'bold' }}>
          {dashboard.name}
        </Text>
        <Text style={{ marginTop: 5 }}>{dashboard.goal || '-'}</Text>
        <Text style={{ marginTop: 20, marginBottom: 20 }}>-</Text>
        <Text>{t('Projects Report')}</Text>
        <Text style={{ marginTop: 10, fontSize: 15, color: grey[700] }}>
          {reportDate || '-'}
        </Text>

        {!!startDate && !!endDate && (
          <Text style={{ marginTop: 20, fontSize: 15, color: grey[900] }}>
            {t('Period')}: {startDate} - {endDate}
          </Text>
        )}
      </View>
    </Page>
  );
}
