import React from 'react';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

export function ScreenInfoLoading({ message }: any) {
  const { t } = useTranslation();

  const [showReload, setShowReload] = React.useState(false);

  const handleReload = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  React.useEffect(() => {
    const reloadTimer = setTimeout(() => setShowReload(true), 10000);
    return () => {
      clearTimeout(reloadTimer);
    };
  }, []);

  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh', p: 4, bgcolor: 'grey.200' }}
    >
      <CircularProgress />
      <Typography variant="body1" align="center" color="primary">
        {message || t('Loading')}..
      </Typography>

      <Fade in={showReload}>
        <Box>
          <Button
            variant="outlined"
            startIcon={<CachedRoundedIcon />}
            onClick={handleReload}
          >
            {t('Reload')}
          </Button>
        </Box>
      </Fade>
    </Stack>
  );
}
