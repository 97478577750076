import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { DashboardContext } from '@institutegc/digital-dashboard-ui/src/_lib/context/dashboard-context';
import { ReportsScreen } from '@institutegc/digital-dashboard-ui/src/_screens/reports';

const InterventionLoader = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'intervention' */
      './routes/intervention-route'
    )
);
const PriorityAreaLoader = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'priority-area' */
      './routes/priority-area-route'
    )
);
const PriorityAreasLoader = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'priority-areas' */
      './routes/priority-areas-route'
    )
);
const PriorityAreaTrackerLoader = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'tracker' */
      './routes/priority-area-tracker-route'
    )
);
const MyContentLoader = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'my-content' */
      './routes/my-content-route'
    )
);
const PlanningRoutes = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'planning-routes' */
      './routes/planning-routes'
    )
);

export function FullDashboardRouter() {
  const { t } = useTranslation();

  const {
    dashboard: { interventionsSlug, areaLevels },
  } = React.useContext(DashboardContext);

  const priorityAreaPaths = areaLevels.reduce(
    (acc: any, level: any, index: number) => {
      const paPath = `${
        acc.length === 0 ? '' : `${acc[index - 1]}_${areaLevels[index - 1].id}`
      }/${level.slug}/:priorityAreaId`;
      acc.push(paPath);
      return acc;
    },
    []
  );

  return (
    <Switch>
      <Route exact path="/" component={PriorityAreasLoader} />
      <Route exact path={t('/my-content')} component={MyContentLoader} />
      {priorityAreaPaths.map((path: string) => [
        <Route exact path={path} component={PriorityAreaLoader} />,
        <Route
          exact
          path={`${path}/${t('tracker')}`}
          component={PriorityAreaTrackerLoader}
        />,
        <Route
          exact
          path={`${path}/${interventionsSlug}/:interventionId`}
          component={InterventionLoader}
        />,
      ])}

      {/*  Planning */}
      <Route path={t('/planning')} component={PlanningRoutes} />

      {/* Reports */}
      <Route path={t('/reports')} component={ReportsScreen} />
    </Switch>
  );
}

export default FullDashboardRouter;
