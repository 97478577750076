import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  subDays,
  subWeeks,
  startOfWeek,
  endOfWeek,
  subMonths,
  startOfMonth,
  endOfMonth,
  subQuarters,
  startOfQuarter,
  endOfQuarter,
  subYears,
  startOfYear,
  endOfYear,
} from 'date-fns';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

import { ReportsContext } from '../../../_lib/context/reports-context';
import { getGraphQLDate } from '../../../_utils/general-utils';

export function ReportsEditFiltersDates() {
  const { t } = useTranslation();

  const {
    filterStartDate,
    setFilterStartDate,
    filterEndDate,
    setFilterEndDate,
  } = React.useContext(ReportsContext);

  const [datesOption, setDatesOption] = React.useState('custom');

  const handleSelectChange = (event: any) => {
    setDatesOption(event.target.value);

    const datesOptions = {
      custom: {
        startDate: filterStartDate,
        endDate: filterEndDate,
      },
      last_7_days: {
        startDate: getGraphQLDate(subDays(new Date(), 7)),
        endDate: getGraphQLDate(new Date()),
      },
      last_30_days: {
        startDate: getGraphQLDate(subDays(new Date(), 30)),
        endDate: getGraphQLDate(new Date()),
      },
      prev_week: {
        startDate: getGraphQLDate(
          startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })
        ),
        endDate: getGraphQLDate(
          endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })
        ),
      },
      prev_1_month: {
        startDate: getGraphQLDate(startOfMonth(subMonths(new Date(), 1))),
        endDate: getGraphQLDate(endOfMonth(subMonths(new Date(), 1))),
      },
      prev_1_quarter: {
        startDate: getGraphQLDate(startOfQuarter(subQuarters(new Date(), 1))),
        endDate: getGraphQLDate(endOfQuarter(subQuarters(new Date(), 1))),
      },
      prev_1_year: {
        startDate: getGraphQLDate(startOfYear(subYears(new Date(), 1))),
        endDate: getGraphQLDate(endOfYear(subYears(new Date(), 1))),
      },
      year_to_date: {
        startDate: getGraphQLDate(startOfYear(new Date())),
        endDate: getGraphQLDate(new Date()),
      },
    } as any;

    setFilterStartDate(datesOptions[event.target.value].startDate);
    setFilterEndDate(datesOptions[event.target.value].endDate);
  };

  const handleStartChange = (newValue: Date | null) => {
    if (newValue?.getTime() !== new Date(filterStartDate).getTime()) {
      setDatesOption('custom');
      setFilterStartDate(newValue ? getGraphQLDate(newValue) : null);
    }
  };

  const handleEndChange = (newValue: Date | null) => {
    if (newValue?.getTime() !== new Date(filterEndDate).getTime()) {
      setDatesOption('custom');
      setFilterEndDate(newValue ? getGraphQLDate(newValue) : null);
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      aria-labelledby="areas-list-subheader"
      subheader={
        <ListSubheader component="div" id="areas-list-subheader">
          {t('Dates')}
        </ListSubheader>
      }
    >
      <ListItem>
        <FormControl fullWidth size="small">
          <InputLabel id="dates-option-label">{t('Dates Option')}</InputLabel>
          <Select
            labelId="dates-option-label"
            id="dates-option"
            value={datesOption}
            label={t('Dates Option')}
            onChange={handleSelectChange}
          >
            <MenuItem value="custom">{t('Custom')}</MenuItem>
            <MenuItem value="last_7_days">{t('Last 7 days')}</MenuItem>
            <MenuItem value="last_30_days">{t('Last 30 days')}</MenuItem>
            <MenuItem value="prev_week">{t('Previous week')}</MenuItem>
            <MenuItem value="prev_1_month">{t('Previous 1 month')}</MenuItem>
            <MenuItem value="prev_1_quarter">{t('Previous quarter')}</MenuItem>
            <MenuItem value="prev_1_year">{t('Previous year')}</MenuItem>
            <MenuItem value="year_to_date">{t('Year to date')}</MenuItem>
          </Select>
        </FormControl>
      </ListItem>
      <ListItem>
        <DatePicker
          label={t('Start date')}
          inputFormat="dd/MM/yyyy"
          value={filterStartDate ? new Date(filterStartDate) : null}
          maxDate={filterEndDate ? new Date(filterEndDate) : undefined}
          onChange={handleStartChange}
          renderInput={(params: any) => (
            <TextField
              size="small"
              fullWidth
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {!!filterStartDate && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setFilterStartDate(null)}
                          onMouseDown={handleMouseDown}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                    {params?.InputProps?.endAdornment}
                  </>
                ),
              }}
            />
          )}
          componentsProps={{
            actionBar: {
              // The actions will be the same between desktop and mobile
              actions: ['clear'],
            },
          }}
        />
      </ListItem>
      <ListItem>
        <DatePicker
          label={t('End date')}
          inputFormat="dd/MM/yyyy"
          value={filterEndDate ? new Date(filterEndDate) : null}
          minDate={filterStartDate ? new Date(filterStartDate) : undefined}
          onChange={handleEndChange}
          renderInput={(params: any) => (
            <TextField
              size="small"
              fullWidth
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {!!filterEndDate && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setFilterEndDate(null)}
                          onMouseDown={handleMouseDown}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                    {params?.InputProps?.endAdornment}
                  </>
                ),
              }}
            />
          )}
          componentsProps={{
            actionBar: {
              // The actions will be the same between desktop and mobile
              actions: ['clear'],
            },
          }}
        />
      </ListItem>
    </List>
  );
}
