import React from 'react';
import { useHistory } from 'react-router-dom';

export function ScrollToTop({ children }: any) {
  const history = useHistory();

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      const objDiv = document.getElementById('root') || ({} as any);
      objDiv.scrollTop = 0;
    });
    return () => {
      unlisten();
    };
  }, [history]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
