import React from 'react';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export function OfflineEffects() {
  React.useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
        });
      });
    }

    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
        window.localStorage.clear();
        window.location.reload();
      },
    });
  }, []);
  return null;
}
