import { Link, View, Image, Document, Text } from '@react-pdf/renderer';
import grey from '@mui/material/colors/grey';

import { Page } from '../../page';
import {
  getStringDate,
  getStringDateTime,
} from '../../../../_utils/general-utils';
import { CurrencyText } from '../../../../components/currency-text';

import { ReportsPdfPagesMembers } from '../../pages/members';
import { ReportsPdfUiProgressBar } from '../../ui/progress-bar';
import { ReportsPdfUiMilestones } from '../../ui/milestones';
import { ReportsPdfTemplatesSpirePagesTitle } from './pages/title';

// PDF Document
export function ReportsPdfTemplatesSpireDocument({
  t,
  dashboard,
  user,
  isNew,
  report,
  reportData,
  filterAreas,
  startDate,
  endDate,
}: any) {
  // dashboard
  const { priorityAreaName, interventionName } = dashboard;

  // report date and time
  const [, reportDateTime] = [
    getStringDate(new Date().toString(), dashboard.language),
    getStringDateTime(new Date().toString(), dashboard.language),
  ];

  return (
    <Document title={dashboard.name} language={dashboard.language}>
      <ReportsPdfTemplatesSpirePagesTitle
        t={t}
        dashboard={dashboard}
        startDate={startDate}
        endDate={endDate}
      />

      {/* --------------------------------------------------------------- */}
      {/* CONTENT */}
      {/* --------------------------------------------------------------- */}

      {reportData.priorityAreas.map((priorityArea: any) => {
        if (report.hasFilterAreas && filterAreas.length === 0) {
          return null;
        }

        const filtered =
          priorityArea.interventions
            .map((i: any) => `intv-${i.id}`)
            .every((iId: any) => filterAreas.indexOf(iId) === -1) &&
          filterAreas.indexOf(`pa-${priorityArea.id}`) === -1;

        if (filtered) return null;

        return (
          <Page key={priorityArea.id}>
            <View>
              {/* --------------------------------------------------------------- */}
              {/* Title */}
              <Text style={{ fontSize: 15, color: grey[500], marginBottom: 5 }}>
                {priorityAreaName}
              </Text>
              <Text style={{ fontSize: 27, marginBottom: 12 }}>
                {priorityArea.name}
              </Text>

              {/* --------------------------------------------------------------- */}
              {/* Summary */}
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {/* --------------------------------------------------------------- */}
                {/* Leads + Description */}
                <View
                  style={{
                    width: priorityArea.summary.budget ? '60%' : '100%',
                  }}
                >
                  {/* Leads */}
                  <View style={{ marginBottom: 10 }}>
                    <Text style={{ marginBottom: 1 }}>
                      <Text style={{ fontSize: 11, fontWeight: 'bold' }}>
                        {t('Lead')}:
                      </Text>{' '}
                      <Text style={{ fontSize: 14, fontWeight: 'light' }}>
                        {priorityArea.lead.name || '-'}
                      </Text>
                    </Text>
                    <Text>
                      <Text style={{ fontSize: 11, fontWeight: 'bold' }}>
                        {t('Co-Lead')}:
                      </Text>{' '}
                      <Text style={{ fontSize: 14, fontWeight: 'light' }}>
                        {priorityArea.coLead.name || '-'}
                      </Text>
                    </Text>
                  </View>

                  {/* Description */}
                  <Text style={{ fontSize: 14, marginBottom: 10 }}>
                    {priorityArea.goal || '-'}
                  </Text>
                </View>

                {/* --------------------------------------------------------------- */}
                {/* Financiing */}
                {!!priorityArea.summary.budget && (
                  <View
                    style={{
                      width: '40%',
                      paddingLeft: 15,
                      textAlign: 'center',
                    }}
                  >
                    <View
                      style={{
                        padding: 10,
                        border: 1,
                        borderRadius: 5,
                        borderColor: grey[500],
                        backgroundColor: grey[100],
                      }}
                    >
                      <Text style={{ fontSize: 15, marginBottom: 10 }}>
                        {t('Financing')}
                      </Text>
                      <View style={{ fontSize: 11, marginBottom: 10 }}>
                        <Text style={{ marginBottom: 5 }}>
                          {t('Budget')}:{' '}
                          <CurrencyText
                            amount={priorityArea.summary.budget}
                            dashboard={dashboard}
                          />
                        </Text>
                        <Text>
                          {t('Total funding')}:{' '}
                          <CurrencyText
                            amount={priorityArea.summary.totalFunding}
                            dashboard={dashboard}
                          />
                        </Text>
                        <Text
                          style={{
                            marginTop: 10,
                          }}
                        >
                          {t('Currently spent')}:{' '}
                          <CurrencyText
                            amount={priorityArea.summary.currentlySpent}
                            dashboard={dashboard}
                          />
                        </Text>
                      </View>

                      <View
                        style={{
                          fontSize: 11,
                          marginBottom: 10,
                          width: '100%',
                        }}
                      >
                        <ReportsPdfUiProgressBar
                          parts={[
                            {
                              label: `${Math.round(
                                (priorityArea.summary.totalFunding /
                                  priorityArea.summary.budget) *
                                  100
                              )}%`,
                              width: Math.round(
                                (priorityArea.summary.totalFunding /
                                  priorityArea.summary.budget) *
                                  100
                              ),
                              fill: '#007b7d',
                            },
                            {
                              label: '',
                              width:
                                100 -
                                Math.round(
                                  (priorityArea.summary.totalFunding /
                                    priorityArea.summary.budget) *
                                    100
                                ),
                              fill: '#e0e0e0',
                            },
                          ]}
                        />
                        <Text style={{ marginBottom: 3, marginTop: 5 }}>
                          {t('% Funded')}:{' '}
                          {priorityArea.summary.budget === 0 ||
                          priorityArea.summary.totalFunding === 0
                            ? '0'
                            : Number.isNaN(
                                Math.round(
                                  (priorityArea.summary.totalFunding /
                                    priorityArea.summary.budget) *
                                    100
                                )
                              )
                            ? '0'
                            : Math.round(
                                (priorityArea.summary.totalFunding /
                                  priorityArea.summary.budget) *
                                  100
                              )}
                          %
                        </Text>
                      </View>
                      <View
                        style={{ fontSize: 11, marginBottom: 5, width: '100%' }}
                      >
                        <ReportsPdfUiProgressBar
                          parts={[
                            {
                              label: `${Math.round(
                                (priorityArea.summary.currentlySpent /
                                  priorityArea.summary.budget) *
                                  100
                              )}%`,
                              width: Math.round(
                                (priorityArea.summary.currentlySpent /
                                  priorityArea.summary.budget) *
                                  100
                              ),
                              fill: '#007b7d',
                            },
                            {
                              label: '',
                              width:
                                100 -
                                Math.round(
                                  (priorityArea.summary.currentlySpent /
                                    priorityArea.summary.budget) *
                                    100
                                ),
                              fill: '#e0e0e0',
                            },
                          ]}
                        />
                        <Text style={{ marginBottom: 3, marginTop: 5 }}>
                          {t('% Spent')}:{' '}
                          {priorityArea.summary.budget === 0 ||
                          priorityArea.summary.currentlySpent === 0
                            ? '-'
                            : Math.round(
                                (priorityArea.summary.currentlySpent /
                                  priorityArea.summary.budget) *
                                  100
                              )}
                          %
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
              </View>

              {/* --------------------------------------------------------------- */}
              {/* Milestones */}

              <ReportsPdfUiMilestones t={t} area={priorityArea} />

              {/* --------------------------------------------------------------- */}
              {/* Content Summary */}
              <View
                style={{
                  marginTop: 20,
                  paddingTop: 20,
                  paddingBottom: 20,
                  borderTop: 1,
                  borderColor: grey[400],
                }}
              >
                <Text style={{ fontSize: 20, marginBottom: 5 }}>
                  {t('Activities')}
                </Text>
                <Text
                  style={{ fontSize: 15, color: grey[500], marginBottom: 10 }}
                >
                  {t(
                    'Summary of upcoming, overdue, and pending activities and issues'
                  )}
                </Text>
                {Object.keys(priorityArea.summary.contentSummary).map(
                  (key: any) => {
                    if (key === '__typename') return null;
                    const contentSummary =
                      priorityArea.summary.contentSummary[key];
                    const contentLabels = {
                      upcoming: t('Upcoming deadlines'),
                      ongoing: t('Upcoming deadlines'),
                      overdue: t('Most overdue'),
                      pending: t('With pending updates'),
                      issues: t('Recently raised issues'),
                      completed: t('Recently completed'),
                    };
                    return (
                      <View key={key}>
                        <Text
                          style={{
                            fontSize: 13,
                            fontWeight: 'bold',
                            marginBottom: 7,
                            marginTop: 10,
                            textDecoration: 'underline',
                          }}
                        >
                          {contentLabels[key as keyof typeof contentLabels]}
                        </Text>
                        {contentSummary.content &&
                        contentSummary.content.length > 0 ? (
                          contentSummary.content.map((content: any) => (
                            <View key={content.id} style={{ marginBottom: 5 }}>
                              <Text style={{ fontSize: 12, marginBottom: 3 }}>
                                {content.title || '-'}
                              </Text>
                              <Text style={{ fontSize: 10, color: grey[500] }}>
                                {content.intervention.name || '-'}
                              </Text>
                            </View>
                          ))
                        ) : (
                          <Text
                            style={{
                              fontSize: 12,
                              fontStyle: 'italic',
                              marginBottom: 5,
                              color: grey[500],
                            }}
                          >
                            {t('Nothing to show')}
                          </Text>
                        )}
                      </View>
                    );
                  }
                )}
              </View>

              {/* --------------------------------------------------------------- */}
              {/* Interventions */}
              {/* --------------------------------------------------------------- */}
              <View
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                  borderTop: 1,
                  borderColor: grey[400],
                }}
              >
                <Text style={{ fontSize: 20, marginBottom: 5 }}>
                  {interventionName}
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                  }}
                >
                  {priorityArea.interventions.map((intervention: any) => {
                    // const {
                    //   milestonesCompleted,
                    //   milestonesTotal,
                    //   milestonesPercentage,
                    // } = intervention;
                    return (
                      <View
                        key={intervention.id}
                        style={{ width: '50%', padding: 10 }}
                        wrap={false}
                      >
                        <View
                          style={{
                            border: 1,
                            borderColor: grey[400],
                            padding: 15,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              marginBottom: 3,
                              color: grey[500],
                            }}
                          >
                            {interventionName}
                          </Text>
                          <Text style={{ fontSize: 15, marginBottom: 5 }}>
                            {intervention.name || '-'}
                          </Text>
                          <Text
                            style={{
                              fontSize: 10,
                              marginBottom: 5,
                              maxLines: 1,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {intervention.description || '-'}
                          </Text>

                          {/* Co-Lead / Lead */}
                          <View style={{ marginBottom: 7 }}>
                            <Text style={{ fontSize: 10, marginBottom: 3 }}>
                              <Text style={{ fontSize: 8 }}>{t('Lead')}: </Text>
                              <Text>
                                {intervention.lead
                                  ? intervention.lead.name
                                  : '-'}
                              </Text>
                            </Text>
                            <Text style={{ fontSize: 10 }}>
                              <Text style={{ fontSize: 8 }}>
                                {t('Co-Lead')}:{' '}
                              </Text>
                              <Text>
                                {intervention.coLead
                                  ? intervention.coLead.name
                                  : '-'}
                              </Text>
                            </Text>
                          </View>

                          {/* Budget */}
                          <Text style={{ fontSize: 10, marginBottom: 3 }}>
                            {t('Budget')}:{' '}
                            <CurrencyText
                              amount={intervention.budget}
                              dashboard={dashboard}
                            />
                          </Text>
                          <Text style={{ fontSize: 10, marginBottom: 7 }}>
                            {t('Spent')}:{' '}
                            <CurrencyText
                              amount={intervention.currentlySpent}
                              dashboard={dashboard}
                            />
                          </Text>

                          {/* Milestones */}
                          {/* <Text style={{ fontSize: 10, marginBottom: 3 }}>
                            {t('Milestones')}: {milestonesCompleted}/
                            {milestonesTotal} ({milestonesPercentage}%)
                          </Text> */}
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          </Page>
        );
      })}

      {/* --------------------------------------------------------------- */}
      {/* Members Info */}
      {/* --------------------------------------------------------------- */}
      {!report.filterMembers && (
        <ReportsPdfPagesMembers
          t={t}
          reportData={reportData}
          dashboard={dashboard}
        />
      )}

      {/* --------------------------------------------------------------- */}
      {/* Report Details */}
      {/* --------------------------------------------------------------- */}
      <Page>
        <View
          style={{
            display: 'flex',
            height: '100%',
            justifyContent: 'flex-end',
            fontSize: 12,
          }}
        >
          <Text style={{ marginBottom: 5 }}>
            {t('Author')}:{' '}
            {!isNew
              ? report && report.author
                ? report.author.name
                : '-'
              : user.name}
          </Text>
          <Text style={{ marginBottom: 5 }}>
            {t('Timestamp')}: {reportDateTime}
          </Text>
          <Text style={{ marginBottom: 20 }}>
            {t('Report ID')}:{' '}
            {!isNew ? (report ? report.id : '(new)') : '(new)'}
          </Text>

          <Text style={{ marginBottom: 0 }}>
            {t('Learn more at')}:{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link src="https://digitaldashboard.institute.global/">
              https://digitaldashboard.institute.global/
            </Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
}
