import React from 'react';
import { useTranslation } from 'react-i18next';

import MuiSnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export function Snackbar({
  open,
  setOpen,
  message,
  setMessage,
  isDelete,
  setIsDelete,
  isError,
  setIsError,
}: any) {
  const { t } = useTranslation();

  const [localOpen, setLocalOpen] = React.useState(open);

  const handleClose = () => {
    setOpen(false);
    setLocalOpen(false);
  };

  const handleExited = () => {
    if (setIsDelete) setIsDelete(false);
    if (setIsError) setIsError(false);
    if (setMessage) setMessage('');
  };

  React.useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={localOpen}
      autoHideDuration={3000}
      TransitionProps={{ onExited: handleExited }}
      onClose={handleClose}
    >
      <Alert severity={isError ? 'error' : 'success'} onClose={handleClose}>
        {message ||
          (isDelete && t('Successfully deleted.')) ||
          (isError && t('Something went wrong. Please try again.')) ||
          t('Successfully saved changes.')}
      </Alert>
    </MuiSnackbar>
  );
}
