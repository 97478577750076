import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import frenchTranslations from './fr-translations.json';
import portugueseTranslations from './pt-translations.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: frenchTranslations,
      pt: portugueseTranslations,
    },

    fallbackLng: 'en-GB',

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
