import { View } from '@react-pdf/renderer';

import Layout from '../layout';
import Boxed from '../../ui/boxed';

import Funding from './funding';
import Financiers from './financiers';
import Methods from './methods';

export function ReportsPdfTemplatesPreziCountrySectionsFinance({
  t,
  dashboard,
  reportData,
  areas,
}: any) {
  const boxedStyles = {
    width: '32%',
    flexDirection: 'column',
    spacing: 2,
    titleFlexGrow: 0,
    contentFlexGrow: 1,
  };

  return (
    <Layout title={t('Financials')}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <Boxed
          {...boxedStyles}
          title={t('Funding')}
          content={
            <Funding
              t={t}
              dashboard={dashboard}
              reportData={reportData}
              areas={areas}
            />
          }
        />
        <Boxed
          {...boxedStyles}
          title={t('Financiers')}
          content={
            <Financiers
              t={t}
              dashboard={dashboard}
              reportData={reportData}
              areas={areas}
            />
          }
        />
        <Boxed
          {...boxedStyles}
          title={t('Finance Methods')}
          content={
            <Methods
              t={t}
              dashboard={dashboard}
              reportData={reportData}
              areas={areas}
            />
          }
        />
      </View>
    </Layout>
  );
}
