import { Document } from '@react-pdf/renderer';

export function ReportsPdfDocument({ dashboard, children }: any) {
  return (
    <Document
      title={dashboard.name}
      language={dashboard.language}
      creator="TBI Digital Delivery Dashboard"
      producer="TBI Digital Delivery Dashboard"
      // author={user.name}
    >
      {children}
    </Document>
  );
}
export default ReportsPdfDocument;
