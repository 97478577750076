import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Link,
  Box,
  Typography,
  Avatar,
  Container,
  Alert,
  AlertTitle,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import AuthBg from '../../assets/img/bg-auth.jpg';



function Copyright(props: any) {
  const { t } = useTranslation();
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {t('Built by')}{' '}
      <Link
        color="inherit"
        href="https://institute.global/"
        target="_blank"
        rel="noreferre noopener"
      >
        Tony Blair Institute
      </Link>
    </Typography>
  );
}

export function AuthScreenLayout({
  icon,
  title,
  children,
  demoEmail,
  demoPassword,
}: any) {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: `url("${AuthBg}")`,
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        backgroundAttachment: 'fixed',
        pt: 10,
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" color="grey.800" sx={{ mb: 8 }}>
            {t('Digital Delivery Dashboard')}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 4,
            mb: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            bgcolor: 'white',
            borderRadius: 1,
            border: 1,
            borderColor: 'grey.400',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            {icon || <LockOutlinedIcon />}
          </Avatar>
          <Typography component="h1" variant="h5">
            {t(title || `Sign In`)}
          </Typography>

          {/* demo */}
          {location.pathname === '/' &&
            process.env.REACT_APP_IS_DEMO_SITE === 'true' && (
              <Box sx={{ maxWidth: '100%', my: 2 }}>
                <Alert
                  severity="info"
                  sx={{
                    '& .MuiAlert-message': {
                      overflow: 'auto',
                    },
                  }}
                >
                  <Typography variant="body2" gutterBottom>
                    {t(
                      'This is a demo site. Data is deleted every hour. You can log in using:'
                    )}
                  </Typography>
                  <Typography variant="body2">
                    <strong>{t('Email')}</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{
                      border: 1,
                      borderRadius: 1,
                      px: 1,
                      py: 0.5,
                      bgcolor: 'grey.200',
                      color: 'text.secondary',
                      borderColor: 'text.secondary',
                      overflow: 'hidden',
                    }}
                  >
                    {demoEmail}
                  </Typography>
                  <Typography variant="body2">
                    <strong>{t('Password')}</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{
                      border: 1,
                      borderRadius: 1,
                      px: 1,
                      py: 0.5,
                      bgcolor: 'grey.200',
                      color: 'text.secondary',
                      borderColor: 'text.secondary',
                      overflow: 'hidden',
                    }}
                  >
                    {demoPassword}
                  </Typography>
                </Alert>
              </Box>
            )}

          {/* content */}
          {children}
        </Box>
        {/* copyright */}
        <Copyright sx={{ mb: 4 }} />
        <Box sx={{ height: '1px' }} />
      </Container>
    </Box>
  );
}
