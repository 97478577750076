import { View, Text } from '@react-pdf/renderer';

import teal from '@mui/material/colors/teal';
import lightBlue from '@mui/material/colors/lightBlue';
import grey from '@mui/material/colors/grey';

import { CurrencyText } from '../../../../../../components/currency-text';
import { ReportsPdfUiProgressBar } from '../../../../ui/progress-bar';
import { getFinanceSummary } from '../../../../../../_utils/finance-utils';

export function ReportsPdfTemplatesPreziCountrySectionsFinanceFunding({
  t,
  dashboard,
  areas,
}: any) {
  const summary = getFinanceSummary(areas);

  const totalFundingParts = [
    {
      label: t('Total funding'),
      width: (summary.totalFunding / summary.budget) * 100,
      fill: teal[500],
    },
    {
      label: t('Budget'),
      width: (1 - summary.totalFunding / summary.budget) * 100,
      fill: grey[500],
    },
  ];
  const currentlySpentParts = [
    {
      label: t('Currently spent'),
      width: (summary.currentlySpent / summary.budget) * 100,
      fill: lightBlue[500],
    },
    {
      label: t('Total Funding'),
      width: (1 - summary.currentlySpent / summary.budget) * 100,
      fill: grey[500],
    },
  ];

  return (
    <View style={{ width: '100%', textAlign: 'left', margin: '3px' }}>
      <Text style={{ fontSize: '10px' }}>{t('Budget')}</Text>
      <Text style={{ marginBottom: '5px' }}>
        <CurrencyText amount={summary.budget} dashboard={dashboard} />
      </Text>

      {/* Total Funding */}
      <Text style={{ fontSize: '10px', marginBottom: '3px' }}>
        {t('Total funding')}:{' '}
        <CurrencyText amount={summary.totalFunding} dashboard={dashboard} />
      </Text>
      <ReportsPdfUiProgressBar parts={totalFundingParts} />

      <View style={{ height: '5px' }} />

      {/* Currently Spent */}
      <Text style={{ fontSize: '10px', marginBottom: '3px' }}>
        {t('Currently spent')}:{' '}
        <CurrencyText amount={summary.currentlySpent} dashboard={dashboard} />
      </Text>
      <ReportsPdfUiProgressBar parts={currentlySpentParts} />
    </View>
  );
}

export default ReportsPdfTemplatesPreziCountrySectionsFinanceFunding;
