import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useAuth } from '../../_utils/use-auth';
import { logError } from '../../_lib/error';

import { AuthScreenLayout } from '../../auth/_layout';

import AUTH_IS_DEMO_SITE from '../../_lib/graphql/queries/auth/isDemoSite.graphql';

export function ScreenAuthLogin() {
  const { t } = useTranslation();
  const { signIn } = useAuth();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null as any);

  const [demoEmail, setDemoEmail] = React.useState(
    'digitaldashboard+demo.su@institute.global'
  );
  const [demoPassword, setDemoPassword] = React.useState('tbidashboard');

  // Demo credentials
  const { data: dataIsDemoSiteQuery, error: errorAuthIsDemoSite } =
    useQuery(AUTH_IS_DEMO_SITE);

  // ----------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------

  // Validate the data
  const valid = (data: any) => {
    const newErrors = {} as any;
    if (!data.get('email')) {
      newErrors.email = true;
      setError(newErrors);
    }
    if (!data.get('password')) {
      newErrors.password = true;
      setError(newErrors);
    }
    return !newErrors.email && !newErrors.password;
  };

  // Handle login
  const handleLogin = (data: any) => {
    if (valid(data)) {
      setLoading(true);
      signIn(data.get('email'), data.get('password'))
        .then((r: any) => {
          if (!r?.data?.authLogin?.success) {
            setError({ auth: true });
            setLoading(false);
          } else {
            window.localStorage.clear();
            window.location.reload();
          }
        })
        .catch((err: any) => {
          logError(err);
          setError({ other: true });
          setLoading(false);
        });
    }
  };

  // Handle form submit
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    handleLogin(data);
  };

  // ----------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (dataIsDemoSiteQuery && dataIsDemoSiteQuery.authIsDemoSite) {
      setLoading(false);
      setDemoEmail(dataIsDemoSiteQuery.authIsDemoSite.email);
      setDemoPassword(dataIsDemoSiteQuery.authIsDemoSite.password);
    }
  }, [dataIsDemoSiteQuery]);

  React.useEffect(() => {
    if (errorAuthIsDemoSite) {
      logError(errorAuthIsDemoSite);
    }
  }, [errorAuthIsDemoSite]);

  // ----------------------------------------------------------------------------------------

  return (
    <AuthScreenLayout demoEmail={demoEmail} demoPassword={demoPassword}>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {/* email */}
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label={t('Email Address')}
          name="email"
          autoComplete="email"
          autoFocus
          disabled={loading}
          error={(!!error && !!error.auth) || (!!error && !!error.email)}
          defaultValue={
            process.env.REACT_APP_IS_DEMO_SITE === 'true'
              ? demoEmail
              : undefined
          }
          key={
            process.env.REACT_APP_IS_DEMO_SITE === 'true'
              ? demoEmail
              : undefined
          }
        />

        {/* password */}
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('Password')}
          type="password"
          id="password"
          autoComplete="current-password"
          disabled={loading}
          error={(!!error && !!error.auth) || (!!error && !!error.password)}
          defaultValue={
            process.env.REACT_APP_IS_DEMO_SITE === 'true'
              ? demoPassword
              : undefined
          }
          key={
            process.env.REACT_APP_IS_DEMO_SITE === 'true'
              ? demoPassword
              : undefined
          }
        />

        {/* remember me */}
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label={t('Remember me')}
          disabled={loading}
        />

        {/* error */}
        {!!error && (
          <Paper
            variant="outlined"
            sx={{
              backgroundColor: '#ffcdd2',
              borderColor: '#c62828',
              py: 1,
              px: 2,
              mt: 2,
            }}
          >
            <Typography variant="body2">
              {(!!error.email || !!error.password) &&
                t(`Email and password cannot be empty.`)}
              {!!error.auth &&
                t(`Incorrect email or password. Please try again.`)}
              {!!error.other && t(`Something went wrong. Please try again.`)}
            </Typography>
          </Paper>
        )}

        {/* sign in button */}
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={loading}
          disableElevation
        >
          {t('Sign In')}
        </LoadingButton>

        {/* forgot password */}
        <Grid container>
          <Grid item xs>
            <Link component={RouterLink} to="/auth/forgot" variant="body2">
              {t('Forgot password?')}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </AuthScreenLayout>
  );
}
