import { gql } from '@apollo/client';

import contentFragment from './fragments/content/content.graphql';

import context from './queries/context.graphql';
import contextDashboard from './queries/context/dashboard.graphql';
import contextUser from './queries/context/user.graphql';
import contextUsers from './queries/context/users.graphql';

import dashboardPlanning from './queries/dashboard-planning.graphql';

import importExport from './queries/import-export.graphql';
import importExports from './queries/import-exports.graphql';

import reports from './queries/reports/reports.graphql';
import getReport from './queries/reports/get-report.graphql';
import getReportData from './queries/reports/get-report-data.graphql';
import getReportsTemplateMeta from './queries/reports/get-reports-template-meta.graphql';

import dashboardOverview from './queries/dashboard-overview.graphql';

import priorityAreas from './queries/area/priority-area/priority-areas.graphql';
import priorityArea from './queries/area/priority-area/priority-area.graphql';
import priorityAreaTracker from './queries/area/priority-area/priority-area-tracker.graphql';
import priorityAreasPlanning from './queries/area/priority-area/priority-areas-planning.graphql';
import priorityAreaPlanningDelete from './queries/area/priority-area/priority-area-planning-delete.graphql';
import priorityAreasHome from './queries/area/priority-area/priority-areas-home.graphql';
import priorityAreaSummary from './queries/area/priority-area/priority-area-summary.graphql';

import interventions from './queries/area/intervention/interventions.graphql';
import intervention from './queries/area/intervention/intervention.graphql';
import interventionsPlanning from './queries/area/intervention/interventions-planning.graphql';
import interventionsPriorityAreaPlanning from './queries/area/intervention/interventions-priority-area-planning.graphql';
import interventionSummary from './queries/area/intervention/intervention-summary.graphql';

import milestonesPlanning from './queries/milestones-planning.graphql';
import regularUserContent from './queries/regular-user-content.graphql';
import trackerLanding from './queries/tracker-landing.graphql';
import activityTracker from './queries/activity-tracker.graphql';

import getAreaPermissions from './queries/area/permissions.graphql';

import getContent from './queries/content/get-content.graphql';
import getMyContent from './queries/content/get-my-content.graphql';

import contentGroups from './queries/content-groups.graphql';
import contentGroup from './queries/content-group.graphql';
import getContentBlockers from './queries/content/get-content-blockers.graphql';
import getAcceptedUploadFileTypes from './queries/content/get-accepted-upload-file-types.graphql';

import routeContentPermission from './queries/route-content-permission.graphql';

import classifications from './queries/classifications.graphql';
import regions from './queries/regions.graphql';

import areaLevels from './queries/area/area-levels.graphql';

export const CONTENT_FRAGMENT = contentFragment;

export const CONTEXT = context;
export const CONTEXT_DASHBOARD_QUERY = contextDashboard;
export const CONTEXT_USER_QUERY = contextUser;
export const CONTEXT_USERS_QUERY = contextUsers;

export const DASHBOARD_PLANNING = dashboardPlanning;

export const GET_REPORTS = reports;
export const GET_REPORT = getReport;
export const GET_REPORT_DATA = getReportData;
export const GET_REPORTS_TEMPLATE_META = getReportsTemplateMeta;

export const IMPORT_EXPORT_QUERY = importExport;
export const IMPORT_EXPORTS_QUERY = importExports;

export const DASHBOARD_OVERVIEW_QUERY = dashboardOverview;

export const PRIORITY_AREAS = priorityAreas;
export const PRIORITY_AREA = priorityArea;
export const PRIORITY_AREA_TRACKER = priorityAreaTracker;
export const PRIORITY_AREAS_PLANNING_QUERY = priorityAreasPlanning;
export const PRIORITY_AREA_PLANNING_DELETE_QUERY = priorityAreaPlanningDelete;
export const PRIORITY_AREAS_HOME_QUERY = priorityAreasHome;
export const PRIORITY_AREA_SUMMARY_QUERY = priorityAreaSummary;

export const INTERVENTIONS = interventions;
export const INTERVENTION = intervention;
export const INTERVENTIONS_PLANNING_QUERY = interventionsPlanning;
export const INTERVENTIONS_PRIORITY_AREA_PLANNING_QUERY =
  interventionsPriorityAreaPlanning;
export const INTERVENTION_SUMMARY_QUERY = interventionSummary;

export const MILESTONES_PLANNING = milestonesPlanning;
export const REGULAR_USER_CONTENT = regularUserContent;
export const TRACKER_LANDING = trackerLanding;
export const ACTIVITY_TRACKER = activityTracker;

export const GET_AREA_PERMISSIONS = getAreaPermissions;

export const GET_CONTENT = getContent;
export const GET_MY_CONTENT_QUERY = getMyContent;

export const CONTENT_GROUPS_QUERY = contentGroups;
export const CONTENT_GROUP_QUERY = contentGroup;

export const GET_CONTENT_BLOCKERS = getContentBlockers;
export const GET_ACCEPTED_UPLOAD_FILE_TYPES_QUERY = getAcceptedUploadFileTypes;

export const ROUTE_CONTENT_PERMISSION = routeContentPermission;

export const CLASSIFICATIONS_QUERY = classifications;
export const REGIONS_QUERY = regions;

export const AREA_LEVELS_QUERY = areaLevels;

export const LAST_LOGIN_QUERY = gql`
  query LastLogin($userId: ID!) {
    user(id: $userId) {
      lastLogin
    }
  }
`;
