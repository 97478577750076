import React from 'react';
import { useTranslation } from 'react-i18next';

import { Font, BlobProvider } from '@react-pdf/renderer';

import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import robotoFont from '../../../assets/fonts/Roboto/Roboto-Regular.ttf';
import robotoFontItalic from '../../../assets/fonts/Roboto/Roboto-Italic.ttf';
import robotoFontLight from '../../../assets/fonts/Roboto/Roboto-Light.ttf';
import robotoFontLightItalic from '../../../assets/fonts/Roboto/Roboto-LightItalic.ttf';
import robotoFontMedium from '../../../assets/fonts/Roboto/Roboto-Medium.ttf';
import robotoFontMediumItalic from '../../../assets/fonts/Roboto/Roboto-MediumItalic.ttf';
import robotoFontBold from '../../../assets/fonts/Roboto/Roboto-Bold.ttf';
import robotoFontBoldItalic from '../../../assets/fonts/Roboto/Roboto-BoldItalic.ttf';
import robotoFontBlack from '../../../assets/fonts/Roboto/Roboto-Black.ttf';
import robotoFontBlackItalic from '../../../assets/fonts/Roboto/Roboto-BlackItalic.ttf';

import { ReportsPdfTemplatesPreziCountryDocument } from './template/prezi-country/document';

import { ReportsContext } from '../../_lib/context/reports-context';
import { DashboardContext } from '../../_lib/context/dashboard-context';

import { logError } from '../../_lib/error';
import { ReportsPdfTemplatesSpireDocument } from './template/spire/document';

export function ReportsReportPdf() {
  const { t } = useTranslation();
  const { dashboard, user } = React.useContext(DashboardContext);
  const {
    report,
    reportData,
    setDocumentBlob,
    filterAreas,
    isNew,
    startDate,
    endDate,
  } = React.useContext(ReportsContext);

  // Fonts
  Font.register({
    family: 'Roboto',
    fonts: [
      { src: robotoFont, fontWeight: 'normal', fontStyle: 'normal' },
      { src: robotoFontItalic, fontWeight: 'normal', fontStyle: 'italic' },
      { src: robotoFontLight, fontWeight: 'light', fontStyle: 'normal' },
      { src: robotoFontLightItalic, fontWeight: 'light', fontStyle: 'italic' },
      { src: robotoFontMedium, fontWeight: 'medium', fontStyle: 'normal' },
      {
        src: robotoFontMediumItalic,
        fontWeight: 'medium',
        fontStyle: 'italic',
      },
      { src: robotoFontBold, fontWeight: 'bold', fontStyle: 'normal' },
      { src: robotoFontBoldItalic, fontWeight: 'bold', fontStyle: 'italic' },
      { src: robotoFontBlack, fontWeight: 'heavy', fontStyle: 'normal' },
      { src: robotoFontBlackItalic, fontWeight: 'heavy', fontStyle: 'italic' },
    ],
  });

  // for hot reload change the const below
  // issue: https://github.com/diegomura/react-pdf/issues/1350
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeMe = 'changeMe';

  const [PdfDocument, setPdfDocument] = React.useState<any>(null);

  React.useEffect(() => {
    const PdfDocumentTemplates = {
      PREZI_COUNTRY: <ReportsPdfTemplatesPreziCountryDocument />,
      SPIRE: <ReportsPdfTemplatesSpireDocument />,
    } as any;

    if (report && reportData && Object.keys(reportData).length > 0) {
      // set pdf document
      setPdfDocument(
        React.cloneElement(
          PdfDocumentTemplates[dashboard.reportsDefaultTemplate],
          {
            t,
            dashboard,
            user,
            isNew,
            report,
            reportData,
            filterAreas,
            startDate,
            endDate,
          }
        )
      );
    }
  }, [
    dashboard,
    isNew,
    report,
    reportData,
    filterAreas,
    t,
    user,
    startDate,
    endDate,
  ]);

  function BlobUpdate({ blob }: any) {
    React.useEffect(() => {
      setDocumentBlob(blob);
    }, [blob]);
    return null;
  }

  if (!PdfDocument) return <Skeleton variant="rectangular" height={800} />;

  return (
    <>
      <BlobProvider document={PdfDocument}>
        {({ blob, url, loading, error }) => {
          if (error) logError(error);

          if (loading) return <Skeleton variant="rectangular" height={800} />;

          return (
            <>
              <iframe
                src={url || undefined}
                title={`${dashboard.name}.pdf`}
                height="800px"
                width="100%"
              />
              <BlobUpdate blob={blob} />
            </>
          );
        }}
      </BlobProvider>

      <Typography variant="caption" sx={{ color: 'textSecondary' }}>
        *{' '}
        {t(
          "If PDF doesn't show, try another browser or quit and restart your current browser."
        )}
      </Typography>
    </>
  );
}
