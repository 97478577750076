import React from 'react';
import { ApolloConsumer, useQuery } from '@apollo/client';
import { Route, Switch } from 'react-router-dom';

import { AuthRouter } from '@institutegc/digital-dashboard-ui/src/_lib/routers/auth-router';

import { ScreenAuthLogout } from '@institutegc/digital-dashboard-ui/src/_screens/auth/logout';

import { ScreenInfoLoading } from '@institutegc/digital-dashboard-ui/src/_screens/_info/loading';
import { ScreenInfoErrorTest } from '@institutegc/digital-dashboard-ui/src/_screens/_info/error';

import { AppLoader } from './app-loader';

import AUTH_IS_LOGGED_IN from '../_lib/graphql/queries/auth/isLoggedIn.graphql';

function IsLoggedScreen({ data }: any) {
  return (
    <Switch>
      <Route exact path="/react-error-test/">
        <ScreenInfoErrorTest />
      </Route>
      {data && !data.authIsLoggedIn ? (
        <AuthRouter />
      ) : (
        <Switch>
          <Route exact path="/accounts/logout/">
            <ScreenAuthLogout />
          </Route>
          <Route path="*">
            <AppLoader />
          </Route>
        </Switch>
      )}
    </Switch>
  );
}

export function Routes() {
  const { loading, data } = useQuery(AUTH_IS_LOGGED_IN);

  return loading ? <ScreenInfoLoading /> : <IsLoggedScreen data={data} />;
}
