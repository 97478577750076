import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';

// Sentry
// Initialize sentry
if (process.env.REACT_APP_SENTRY_IS_ON === 'true') {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate:
      parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '') || 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
