import { Page as ReactPdfPage } from '@react-pdf/renderer';
import { PageSize, Orientation, Style } from '@react-pdf/types';

interface PageProps {
  children?: any;
  pageSize?: PageSize;
  pageOrientation?: Orientation;
  pageStyle?: Style;
  pageStylePadding?: string | number;
  pageStyleFontSize?: string | number;
}
export function Page({
  children,
  pageSize,
  pageOrientation,
  pageStyle,
  pageStylePadding,
  pageStyleFontSize,
}: PageProps) {
  return (
    <ReactPdfPage
      size={pageSize}
      orientation={pageOrientation}
      style={{
        ...pageStyle,
        padding: pageStylePadding,
        fontSize: pageStyleFontSize,
      }}
    >
      {children}
    </ReactPdfPage>
  );
}
Page.defaultProps = {
  children: null,
  pageSize: 'A4',
  pageOrientation: 'landscape',
  pageStyle: {
    backgroundColor: '#ffffff',
    fontFamily: 'Roboto',
  },
  pageStylePadding: '2cm',
  pageStyleFontSize: 12,
};

export default Page;
