import React from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { getGraphQLDate } from '../_utils/general-utils';
import { DashboardContext } from '../_lib/context/dashboard-context';

import { FileUpload } from './file-upload';
import { ConfirmCancelButtons } from './confirm-cancel-buttons';
import { MemberAutocomplete } from './member-autocomplete';
import { DialogTitle } from '../DialogTitle/dialog-title';
import { DateInput } from './date-input';

export function DialogModal({
  onSubmit,
  loading,
  onDismiss,
  message,
  title,
  type,
  initialValue,
  initialDate,
  show,
  open,
}: any) {
  const { t } = useTranslation();
  const { users } = React.useContext(DashboardContext);

  const size = type && type.includes('text-input') ? 'sm' : 'xs';
  const dateInput = type
    ? type.includes('date') ||
      type.includes('issue') ||
      type.includes('convert')
    : false;
  const placeholderObject = {
    text: initialValue || '',
    date: initialDate || getGraphQLDate(new Date()),
    value: !initialValue
      ? 0
      : Number.isNaN(parseFloat(initialValue))
      ? 0
      : parseFloat(initialValue),
  };

  const [update, setUpdate] = React.useState<any>(placeholderObject);
  const [notANumber, setNotaNumber] = React.useState(false as any);
  const [noTextError, setNoTextError] = React.useState(false as any);

  const [errors, setErrors] = React.useState<any>({});

  update.assigneeId =
    update.assigneeId || (update.assignee ? update.assignee.id : '');

  const changeValue = (value: any, valueType: any) => {
    update[valueType] = value;
    setUpdate({ ...update });

    // Reset errors
    let errorType = valueType;
    if (errorType === 'assigneeId') errorType = 'assignee';
    if (value)
      setErrors({
        ...errors,
        [errorType]: false,
      });
  };

  const handleConfirm = () => {
    if (Number.isNaN(parseInt(update.value, 10))) {
      setNotaNumber(t('Please use a valid number'));
      return;
    }
    if ((type ? type.includes('text-input') : false) && !update.text) {
      setNoTextError(t('You must include this field'));
      return;
    }

    if (!!type && !!type.includes('convert') && !update.assigneeId) {
      setErrors({
        ...errors,
        assignee: t('You must include an assignee.'),
      });
      return;
    }

    onSubmit(update);
  };

  return (
    <Dialog
      id="confirmation-dialog"
      className={type}
      fullWidth
      maxWidth={size}
      open={show || open}
    >
      <DialogTitle onClose={onDismiss}>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          {typeof message === 'string' ? (
            <Typography>{message}</Typography>
          ) : (
            message
          )}

          {!!dateInput && (
            <Box sx={{ mt: 1 }}>
              <DateInput
                id="dialog-date-input"
                date={update.date}
                setDate={(date: any) => setUpdate({ ...update, date })}
                disabled={loading}
                label={t('Date')}
                size="small"
                fullWidth
              />
            </Box>
          )}

          {!!type?.includes('text-input') && (
            <TextField
              label={t('Text')}
              multiline
              minRows={3}
              fullWidth
              onChange={(event) => changeValue(event.target.value, 'text')}
              value={update.text}
              disabled={loading}
              helperText={noTextError}
              error={!!noTextError}
            />
          )}

          {!!type?.includes('integer-input') && (
            <TextField
              label={t('Number')}
              value={update.value}
              onChange={(event) =>
                setUpdate({ ...update, value: event.target.value })
              }
              InputProps={{
                inputProps: {
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  style: { textAlign: 'right' },
                },
              }}
              size="small"
              fullWidth
              error={!!notANumber}
              helperText={notANumber}
              disabled={loading}
            />
          )}

          {!!type?.includes('attachment') && (
            <Box id="update-attachment" sx={{ textAlign: 'right' }}>
              <Typography sx={{ mb: 1 }}>
                {!!update.attachment && `${update.attachment.name} `}
              </Typography>
              <FileUpload
                isIcon={false}
                file={update.attachment}
                setFile={(file: any) =>
                  setUpdate({ ...update, attachment: file })
                }
              />
            </Box>
          )}

          {/* tracker : issue : convert to activity */}
          {!!type && !!type.includes('convert') && (
            <Box sx={{ mb: 2 }}>
              <MemberAutocomplete
                label={t('Assigned to')}
                value={
                  users.find((u: any) => u.id === update.assigneeId) || null
                }
                onChange={(event: any, value: any) =>
                  changeValue(value.id, 'assigneeId')
                }
                required
                disableClearable
                disabled={loading}
                error={errors.assigneeId}
              />
            </Box>
          )}
        </Stack>
      </DialogContent>

      {/* actions */}
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <ConfirmCancelButtons
          confirm={handleConfirm}
          cancel={onDismiss}
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  );
}

DialogModal.defaultProps = {
  open: false,
  show: false,
};

export default DialogModal;
