import React from 'react';
import { useTranslation } from 'react-i18next';

import { gql, useMutation, useQuery } from '@apollo/client';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TextField from '@mui/material/TextField';

import grey from '@mui/material/colors/grey';

import { logError } from '../../../_lib/error';
import { SWITCH_MEMBER } from '../../../_lib/graphql/mutations';

import { CreateMember } from '../../../actions/create-member';

import { DialogTitle } from '../../../DialogTitle/dialog-title';
import { SwitchMemberMenuSecondary } from './menu-secondary';
import { DashboardContext } from '../../../_lib/context/dashboard-context';

const GET_USERS_FRAGMENT = gql`
  fragment GetUsersFragment on UserType {
    id
    name
    email
    isAdmin
    isRegular
    permissions {
      admin
      priorityAreas {
        id
        reference
        name
      }
      interventions {
        id
        reference
        name
      }
    }
  }
`;

const GET_USERS_QUERY = gql`
  ${GET_USERS_FRAGMENT}
  query GetUsers {
    users {
      ...GetUsersFragment
    }
  }
`;

const GET_USERS_SUBSCRIPTION = gql`
  ${GET_USERS_FRAGMENT}
  subscription GetUsers {
    users {
      ...GetUsersFragment
    }
  }
`;

function SwitchMemberDialogSubscriptions({
  subscribeToMore,
}: {
  subscribeToMore: () => () => void;
}) {
  React.useEffect(() => {
    return subscribeToMore();
  }, [subscribeToMore]);
  return null;
}

export function SwitchMemberDialog({ setOpen, open }: any) {
  const { t } = useTranslation();

  const { setSnackbarIsError, setSnackbarOpen } =
    React.useContext(DashboardContext);

  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [openAddMember, setOpenAddMember] = React.useState<boolean>(false);

  const { data, loading, subscribeToMore } = useQuery(GET_USERS_QUERY);
  const [switchUserMutation] = useMutation(SWITCH_MEMBER);

  // --------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------

  const handleChange = (event: any, newValue: any) => {
    setDisabled(true);
    const uId = newValue.id;
    const user = data?.users?.find((u: any) => u.id === uId);

    switchUserMutation({ variables: { targetUserId: user.id } })
      .then(() => {
        window.localStorage.clear();
        window.location.reload();
      })
      .catch((e) => {
        logError(e);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      });
  };

  // --------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* add member */}
      <CreateMember
        showAddUserDialog={openAddMember}
        setShowAddUserDialog={setOpenAddMember}
      />

      {/* switch member dialog */}
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          onClose={() => setOpen(false)}
          sx={{ color: 'primary.main' }}
        >
          Switch Member
        </DialogTitle>

        <DialogContent>
          {(!data || loading || disabled) && (
            <Box sx={{ textAlign: 'center', p: 4 }}>
              <CircularProgress />
            </Box>
          )}

          {!!data && !loading && !disabled && (
            <Box sx={{ my: 1 }}>
              <Autocomplete
                onChange={handleChange}
                disabled={disabled || loading}
                options={data?.users}
                renderInput={(params) => (
                  <TextField {...params} label="Member" />
                )}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) =>
                  option.id === 'addUser' ? (
                    [
                      <Divider key="addUserDivider" />,
                      <MenuItem
                        {...props}
                        key="addUserMenuItem"
                        onClick={() => {
                          setOpenAddMember(true);
                        }}
                      >
                        <ListItemIcon>
                          <PersonAddIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('Add User')}</ListItemText>
                      </MenuItem>,
                    ]
                  ) : (
                    <MenuItem {...props}>
                      <ListItemText
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        disableTypography
                        primary={
                          <Typography>
                            {option.name}{' '}
                            {(option.isAdmin || option.isRegular) && (
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{ color: grey[700] }}
                              >
                                ({option.isAdmin ? t('Admin') : t('Regular')})
                              </Typography>
                            )}
                          </Typography>
                        }
                        secondary={
                          <SwitchMemberMenuSecondary option={option} />
                        }
                      />
                    </MenuItem>
                  )
                }
                filterOptions={(options: any, params) => {
                  return options.filter((option: any) => {
                    const pasNames =
                      option.permissions?.priorityAreas?.map(
                        (pa: any) =>
                          `${pa.reference ? `${pa.reference} - ` : ''}${
                            pa.name
                          }`
                      ) || [];
                    const intvNames =
                      option.permissions?.interventions?.map(
                        (i: any) =>
                          `${i.reference ? `${i.reference} - ` : ''}${i.name}`
                      ) || [];

                    return (
                      option.name
                        ?.toLowerCase()
                        .includes(params.inputValue.toLowerCase()) ||
                      option.email
                        ?.toLowerCase()
                        .includes(params.inputValue.toLowerCase()) ||
                      pasNames
                        .map((pa: any) => pa.toLowerCase())
                        .find((pa: any) =>
                          pa.includes(params.inputValue.toLowerCase())
                        ) ||
                      intvNames
                        .map((i: any) => i.toLowerCase())
                        .find((i: any) =>
                          i.includes(params.inputValue.toLowerCase())
                        ) ||
                      option.id === 'addUser'
                    );
                  });
                }}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* subscribe to more */}
      {!!data && (
        <SwitchMemberDialogSubscriptions
          subscribeToMore={() =>
            subscribeToMore({
              document: GET_USERS_SUBSCRIPTION,
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                return subscriptionData.data;
              },
            })
          }
        />
      )}
    </>
  );
}
