import React from 'react';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { DashboardContext } from '../_lib/context/dashboard-context';
import { deepCopy } from '../_utils/general-utils';
import { CreateMember } from '../actions/create-member';

export function MemberAutocomplete({
  label,
  placeholder,

  filterIds,
  filterAdmins,
  value,
  onChange,

  error,
  disabled,

  required,
  multiple,
  disableClearable,
}: any) {
  const { t } = useTranslation();
  const { user, users } = React.useContext(DashboardContext);

  const [userOptions, setUserOptions] = React.useState<any>([]);

  const [addedMember, setAddedMember] = React.useState<any>(null);
  const [openCreateMemberDialog, setOpenCreateMemberDialog] =
    React.useState(false);

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handleNewMember = (newMember: any) => {
    setAddedMember(newMember);
  };

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  // user options
  React.useEffect(() => {
    const newUsers = deepCopy(users);
    if (user.isAdmin) {
      newUsers.push({
        id: 'addUser',
        name: t('Add User'),
      });
    }
    setUserOptions(newUsers);
  }, [users, user, t]);

  // added member
  React.useEffect(() => {
    if (addedMember) {
      if (userOptions.find((u: any) => u.id === addedMember.id)) {
        if (multiple) {
          const newValue = deepCopy(value);
          newValue.push(addedMember);
          onChange(null, newValue);
        } else {
          onChange(null, addedMember);
        }

        setAddedMember(null);
      }
    }
  }, [addedMember, userOptions, value, onChange, multiple]);

  // ----------------------------------------------------------------------------------------------------

  return (
    <>
      {/* ---------------------------------------------------------------------------------------------------- */}
      {/* dialogs */}
      {/* ---------------------------------------------------------------------------------------------------- */}
      {/* create member */}
      <CreateMember
        setSelect={handleNewMember}
        showAddUserDialog={openCreateMemberDialog}
        setShowAddUserDialog={setOpenCreateMemberDialog}
      />

      {/* ---------------------------------------------------------------------------------------------------- */}
      {/* autocomplete */}
      {/* ---------------------------------------------------------------------------------------------------- */}
      <Autocomplete
        options={userOptions || []}
        fullWidth
        size="small"
        disableClearable={disableClearable}
        disabled={disabled}
        multiple={multiple}
        placeholder={placeholder}
        renderTags={(val: readonly string[], getTagProps) =>
          val.map((option: any, index: number) => (
            <Chip
              variant="outlined"
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => {
          const inputValue = params.inputProps.value as string;
          return (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              error={!!error}
              helperText={error}
              inputProps={{
                ...params.inputProps,
                style: {
                  ...params.inputProps.style,
                  paddingRight: '28px',
                },
                value: inputValue ? inputValue.split(' 🤟 ')[0] : '',
              }}
            />
          );
        }}
        getOptionLabel={(option: any) => `${option.name} 🤟 ${option.email}`}
        renderOption={(props, option: any) => {
          return option.id === 'addUser' ? (
            [
              <Divider key="addUserDivider" />,
              <MenuItem
                {...props}
                key="addUserMenuItem"
                onClick={() => {
                  setOpenCreateMemberDialog(true);
                }}
              >
                <ListItemIcon>
                  <PersonAddIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('Add User')}</ListItemText>
              </MenuItem>,
            ]
          ) : (
            <MenuItem {...props} key={option.email}>
              <ListItemText
                primary={option.name}
                secondary={option.email}
                sx={{
                  '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                }}
              />
            </MenuItem>
          );
        }}
        value={value || (multiple ? [] : null)}
        isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
        onChange={onChange}
        filterOptions={(options: any, params) => {
          let filtered = deepCopy(options) || [];

          // remove users that are already selected
          if (multiple) {
            filtered = filtered.filter((option: any) => {
              return !value.map((u: any) => u.id).includes(option.id);
            });
          } else {
            filtered = filtered.filter((option: any) => option.id !== value);
          }

          // remove users that are in the filteredOptionsIds
          if (filterIds) {
            filtered = filtered.filter((option: any) => {
              return !filterIds.includes(option.id);
            });
          }

          // remove users that are admins
          if (filterAdmins) {
            filtered = filtered.filter((option: any) => {
              return !option.isAdmin;
            });
          }

          return filtered.filter(
            (option: any) =>
              option.name
                ?.toLowerCase()
                .includes(params.inputValue.toLowerCase()) ||
              option.email
                ?.toLowerCase()
                .includes(params.inputValue.toLowerCase()) ||
              option.id === 'addUser'
          );
        }}
      />
    </>
  );
}
