import React from 'react';

export const PortalContainerContext = React.createContext<any>('');

export function PortalContainerProvider({ children }: any) {
  // data
  const portalContainer = React.useRef(null);

  // context
  const contextValue = React.useMemo(
    () => ({
      portalContainer,
    }),
    [portalContainer]
  );

  return (
    <PortalContainerContext.Provider value={contextValue}>
      {children}
      <div ref={portalContainer} />
    </PortalContainerContext.Provider>
  );
}
