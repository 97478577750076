import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';

import { DashboardContext } from '../_lib/context/dashboard-context';
import { CHANGE_USER_DETAILS } from '../_lib/graphql/mutations';
import { DialogTitle } from '../DialogTitle/dialog-title';
import { logError } from '../_lib/error';

import { ChangePasswordDialog } from './navbar-menu/change-password/dialog';

export function ChangeUserDetails({ open, onClose }: any) {
  const { t } = useTranslation();
  const { user, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    React.useState(false);

  const [changeUserDetailsMutation] = useMutation(CHANGE_USER_DETAILS);

  // Form state
  const [formDisableInput, setFormDisableInput] = React.useState(false);
  // User name
  const [formUserName, setFormUserName] = React.useState(user.name);
  const handleFormUserNameChange = (e: any) => {
    setFormUserName(e.target.value);
  };
  // User email
  const formUserEmail = user.email;
  // Buttons
  const [saveButtonSpinnerShow, setSaveButtonSpinnerShow] =
    React.useState(false);
  // Not Saved Changes Alert
  const [showNotSavedChangesAlert, setShowNotSavedChangesAlert] =
    React.useState(false);

  // --------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------

  // Close modal and reset states
  const closeModalAndReset = () => {
    setFormDisableInput(false);
    setFormUserName(user.name);
    setSaveButtonSpinnerShow(false);
    setShowNotSavedChangesAlert(false);
    onClose();
  };

  // Save Changes
  const handleSaveChanges = async () => {
    setFormDisableInput(true);
    setSaveButtonSpinnerShow(true);
    setShowNotSavedChangesAlert(false);

    changeUserDetailsMutation({
      variables: { name: formUserName },
    })
      .then(() => {
        closeModalAndReset();
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  // Close without saving changes
  const handleCloseButton = () => {
    if (formUserName !== user.name) {
      setShowNotSavedChangesAlert(true);
    } else {
      closeModalAndReset();
    }
  };

  const handleCloseWithoutSaveButton = () => {
    closeModalAndReset();
  };
  const handleCloseWithoutSaveCancelButton = () => {
    setShowNotSavedChangesAlert(false);
  };

  // --------------------------------------------------------------------------------------

  return (
    <>
      <ChangePasswordDialog
        open={openChangePasswordDialog}
        setOpen={setOpenChangePasswordDialog}
      />
      <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
        <DialogTitle onClose={onClose}>{t('Edit My Account')}</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ my: 1 }}>
            <Stack spacing={3}>
              <TextField
                label={t('Full name')}
                value={formUserName}
                onChange={handleFormUserNameChange}
                disabled={formDisableInput}
                fullWidth
              />
              <TextField
                type="email"
                label={t('Email address')}
                value={formUserEmail}
                disabled
                fullWidth
              />

              {/* Alert if changes not saved */}
              {showNotSavedChangesAlert && (
                <Alert severity="warning">
                  <AlertTitle>{t('Discard Changes?')}</AlertTitle>
                  {t(
                    'You have unsaved changes. Would you like to discard them?'
                  )}
                  <Divider sx={{ my: 1.5 }} />
                  <Stack spacing={1} direction="row" justifyContent="flex-end">
                    <Button
                      onClick={handleCloseWithoutSaveCancelButton}
                      variant="outlined"
                    >
                      {t('No')}
                    </Button>
                    <Button
                      onClick={handleCloseWithoutSaveButton}
                      variant="contained"
                      disableElevation
                    >
                      {t('Yes')}
                    </Button>
                  </Stack>
                </Alert>
              )}

              <Button
                variant="contained"
                onClick={() => setOpenChangePasswordDialog(true)}
                sx={{ textTransform: 'none' }}
                startIcon={<LockResetOutlinedIcon />}
              >
                {t('Change Password')}
              </Button>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions sx={{ py: 2, px: 2 }}>
          <Button variant="outlined" onClick={handleCloseButton}>
            {t('Close')}
          </Button>
          <LoadingButton
            loading={saveButtonSpinnerShow}
            variant="contained"
            onClick={handleSaveChanges}
            disableElevation
          >
            {t('Save Changes')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
