import React from 'react';

import {
  ScreenInfoError,
  ScreenInfoErrorComponent,
} from '../_screens/_info/error';
import { ScreenInfoOffline } from '../_screens/_info/offline';

import {
  ScreenInfoUpdateAvailable,
  ScreenInfoUpdateInProgress,
} from '../_screens/_info/update-available';

import { logError } from './error';
import { useOnlineStatus } from './offline/use-online-status';

export function ReactErrorBase({ error, isComponent }: any) {
  // if is missing js, update is available
  let updateAvailable = false;
  if (error && error.name === 'ChunkLoadError') {
    updateAvailable = true;
  }

  const [isUpdating, setIsUpdating] = React.useState(true);

  const isOnline = useOnlineStatus();

  React.useEffect(() => {
    let updatingFound = false;
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          registrations.forEach((registration) => {
            if (registration.installing) {
              updatingFound = true;
              setIsUpdating(true);
            }
          });
        })
        .finally(() => {
          if (!updateAvailable && !updatingFound) {
            setIsUpdating(false);
            logError(error);
          }
        });
    } else {
      setIsUpdating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (updateAvailable) return <ScreenInfoUpdateAvailable />;
  if (isUpdating) return <ScreenInfoUpdateInProgress />;

  if (!isOnline) return <ScreenInfoOffline />;

  return isComponent ? <ScreenInfoErrorComponent /> : <ScreenInfoError />;
}

ReactErrorBase.defaultProps = {
  isComponent: false,
};

export function ReactError({ error }: any) {
  return <ReactErrorBase error={error} />;
}

export function ReactErrorComponent({ error }: any) {
  return <ReactErrorBase error={error} isComponent />;
}
