import React from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';

import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import { DialogTitle } from '../DialogTitle/dialog-title';

import { DashboardContext } from '../_lib/context/dashboard-context';
import { logError } from '../_lib/error';

export function CreateDashboardDialog({ onSubmit, onClose, open }: any) {
  const { t } = useTranslation();

  const { user, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const languages = [
    { name: t('English'), code: 'en' },
    { name: t('French'), code: 'fr' },
    { name: t('Portuguese'), code: 'pt' },
  ];

  const [newDashboard, setNewDashboard] = React.useState<any>({
    language: languages[0].code,
    mode: 'FULL',
    // if not super user add default admin name, position, and email
    ...(user.superuser
      ? {}
      : {
          adminName: user.name,
          adminPosition: user.settings.position || 'Admin',
          adminEmail: user.email,
        }),
  });

  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleConfirm = () => {
    const incomplete =
      !newDashboard.name ||
      !newDashboard.adminName ||
      !newDashboard.adminPosition ||
      !newDashboard.adminEmail;

    if (incomplete) return setError('Please complete all fields');

    setError('');
    setLoading(true);

    return onSubmit(newDashboard)
      .then(() => {
        window.location.pathname = '/';
      })
      .catch((err: any) => {
        logError(err);
        setSnackbarIsError(true);
        setLoading(false);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  return (
    <Dialog id="create-dashboard-form" maxWidth="xs" fullWidth open={open}>
      <DialogTitle onClose={onClose}>{t('Create Dashboard')}</DialogTitle>

      <DialogContent dividers>
        <Box sx={{ mt: 1 }}>
          <Stack spacing={3}>
            <TextField
              label={t('Name')}
              value={newDashboard.name || ''}
              onChange={(event) =>
                setNewDashboard({
                  ...newDashboard,
                  name: event.target.value,
                })
              }
              id="dashboard-name-input"
              fullWidth
              required
              disabled={loading}
            />

            <FormControl fullWidth required size="small" disabled={loading}>
              <InputLabel id="demo-simple-select-label">
                {t('Language')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newDashboard.language}
                label={t('Language')}
                onChange={(event) =>
                  setNewDashboard({
                    ...newDashboard,
                    language: event.target.value,
                  })
                }
                required
                size="small"
              >
                {languages.map((l) => (
                  <MenuItem key={l.code} value={l.code}>
                    {l.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth disabled={loading}>
              <InputLabel id="dashboard-mode-select-label">
                {t('Dashboard Type')}
              </InputLabel>
              <Select
                labelId="dashboard-mode-select-label"
                id="dashboard-mode-select"
                value={newDashboard.mode}
                label={t('Dashboard Type')}
                onChange={(event) => {
                  setNewDashboard({
                    ...newDashboard,
                    mode: event.target.value,
                  });
                }}
              >
                <MenuItem value="FULL">{t('Full Dashboard')}</MenuItem>
                <MenuItem value="TRACKER">
                  {t('Activity Tracker Only')}
                </MenuItem>
                <MenuItem value="SUPRANATIONAL">
                  {t('Dashboard of Dashboards')}
                </MenuItem>
              </Select>
            </FormControl>

            {user.superuser && (
              <>
                <Divider />

                <TextField
                  label="Admin Name"
                  value={newDashboard.adminName || ''}
                  onChange={(event) =>
                    setNewDashboard({
                      ...newDashboard,
                      adminName: event.target.value,
                    })
                  }
                  fullWidth
                  required
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  disabled={loading}
                />

                <TextField
                  label="Admin Position"
                  value={newDashboard.adminPosition || ''}
                  onChange={(event) =>
                    setNewDashboard({
                      ...newDashboard,
                      adminPosition: event.target.value,
                    })
                  }
                  fullWidth
                  required
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BadgeIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  disabled={loading}
                />

                <TextField
                  label="Admin Email"
                  value={newDashboard.adminEmail || ''}
                  onChange={(event) =>
                    setNewDashboard({
                      ...newDashboard,
                      adminEmail: event.target.value,
                    })
                  }
                  fullWidth
                  required
                  InputProps={{
                    inputProps: {
                      type: 'email',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  disabled={loading}
                />
              </>
            )}

            {!!error && (
              <Box>
                <Typography color="error">{error}</Typography>
              </Box>
            )}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()} disabled={loading}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => handleConfirm()}
          loading={loading}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
