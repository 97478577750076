import { View, Text, Image } from '@react-pdf/renderer';

import grey from '@mui/material/colors/grey';

import { getStringDate } from '../../../../../_utils/general-utils';

import { ReportsPdfTemplatesPreziCountryUiBoxed as Boxed } from './boxed';

export function ReportsPdfTemplatesPreziCountryUiHeader({
  t,
  dashboard,
  reportData,
  area,
  areaName,
  report,
}: any) {
  const { reportsTemplateMeta } = reportData.dashboard;
  const preziCountryCountryFocalPoint =
    reportsTemplateMeta.find(
      (meta: any) => meta?.key === 'preziCountryCountryFocalPoint'
    )?.value || '';
  const preziCountryCountryLead =
    reportsTemplateMeta.find(
      (meta: any) => meta?.key === 'preziCountryCountryLead'
    )?.value || '';
  const preziCountryCountryFlag =
    reportsTemplateMeta.find(
      (meta: any) => meta?.key === 'preziCountryCountryFlag'
    )?.file || '';

  const countryLead = reportData.dashboard.users
    .map((user: any) => user.user.id)
    .includes(preziCountryCountryLead)
    ? reportData.dashboard.users.find(
        (user: any) => user.user.id === preziCountryCountryLead
      ).user.name
    : preziCountryCountryLead;

  return (
    <View>
      {/* Title and Flag */}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          minHeight: '50px',
          marginBottom: '7px',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        {!area && !!dashboard.logo && (
          <Image
            style={{
              position: 'absolute',
              left: '0',
              height: '50px',
              width: '50px',
            }}
            src={dashboard.logo}
          />
        )}
        <Text
          style={{
            marginRight: '2cm',
            marginLeft: '2cm',
            fontSize: !area ? '22px' : '20px',
            fontWeight: 'bold',
            borderBottom: '2px solid #000',
            maxLines: 1,
            textOverflow: 'ellipsis',
          }}
        >
          {!area
            ? t('Dashboard')
            : `${areaName} - ${area.reference}. ${area.name}`}
        </Text>
        {!area && !!preziCountryCountryFlag && (
          <Image
            style={{
              position: 'absolute',
              right: '0',
              height: '50px',
              width: '100px',
            }}
            src={preziCountryCountryFlag}
          />
        )}
      </View>

      {/* Report Date */}
      <View style={{ marginBottom: '15px' }}>
        <Text>
          {/* Report Date */}
          {t('Report Date')}:{' '}
          {getStringDate(
            report?.updatedAt || new Date().toString(),
            dashboard.language
          )}
          {(report?.filterStartDate || report?.filterEndDate) && (
            <Text style={{ fontSize: '10px', color: grey[800] }}>
              <Text style={{ paddingHorizontal: '15px' }}> . </Text>
              {/* Period */}
              {t('Period')}:{' '}
              {report?.filterStartDate
                ? getStringDate(
                    report?.filterStartDate,
                    dashboard.language,
                    true
                  )
                : ''}
              {' - '}
              {report?.filterEndDate
                ? getStringDate(report?.filterEndDate, dashboard.language, true)
                : ''}
            </Text>
          )}
        </Text>
      </View>

      {/* Country + Leads */}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {!!dashboard.country && (
          <Boxed
            width="32%"
            spacing={1}
            title={t('Country')}
            content={dashboard.country}
          />
        )}
        {!!preziCountryCountryFocalPoint && (
          <Boxed
            width="32%"
            spacing={1}
            title={t('Focal Point')}
            content={preziCountryCountryFocalPoint}
          />
        )}
        {!!countryLead && (
          <Boxed
            width="32%"
            spacing={1}
            title={t('Country Lead')}
            content={countryLead}
          />
        )}
      </View>
    </View>
  );
}
