/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

export function DashboardAppBarAvatar({ menuItems }: any) {
  const location = useLocation();
  const [currLocation, setCurrLocation] = React.useState(location.pathname);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (location.pathname !== currLocation) {
      handleClose();
      setCurrLocation(location.pathname);
    }
  }, [location, currLocation]);

  const menuId = 'dashboard-appbar-avatar-menu';

  return (
    <>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>

      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ '& .MuiMenu-paper': { width: '300px' } }}
      >
        {menuItems &&
          menuItems.map((menuItem: any, i: number) => {
            if (menuItem.type === 'divider') return <Divider key={i} />;
            if (menuItem.type === 'item') {
              return (
                <MenuItem
                  key={i}
                  id={menuItem.id}
                  onClick={() => {
                    handleClose();
                    if (menuItem.onClick) menuItem.onClick();
                  }}
                  component={menuItem.component}
                  to={menuItem.to}
                  disabled={menuItem.disabled}
                  disableRipple={menuItem.disableRipple}
                >
                  {/* start icon */}
                  {menuItem.startIcon && (
                    <ListItemIcon>
                      {React.cloneElement(menuItem.startIcon, {
                        fontSize: 'small',
                      })}
                    </ListItemIcon>
                  )}

                  {/* text */}
                  <ListItemText
                    inset={menuItem.textInset}
                    primary={menuItem.text}
                    secondary={menuItem.textSecondary}
                    primaryTypographyProps={{
                      noWrap: true,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    secondaryTypographyProps={{ noWrap: true }}
                  />

                  {/* end icon */}
                  {menuItem.endIcon && (
                    <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                      {React.cloneElement(menuItem.endIcon, {
                        fontSize: 'small',
                      })}
                    </ListItemIcon>
                  )}
                </MenuItem>
              );
            }

            const componentProps = {
              key: i,
            } as any;
            if (menuItem.component && menuItem.component.props.handleClose)
              componentProps.handleClose = handleClose;

            if (menuItem.type === 'component')
              return React.cloneElement(menuItem.component, componentProps);

            if (menuItem.component)
              return React.cloneElement(menuItem.component, componentProps);

            return null;
          })}
      </Menu>
    </>
  );
}
