import { View, Text } from '@react-pdf/renderer';
import teal from '@mui/material/colors/teal';
import grey from '@mui/material/colors/grey';

import { ReportsPdfUiProgressBar } from '../../../../ui/progress-bar';
import Boxed from '../../ui/boxed';

export function ReportsPdfTemplatesPreziCountrySectionsActivitiesPercentage({
  t,
  dashboard,
  area,
}: any) {
  const percDoneAvg = area?.summary?.contentSummary?.percDone || 0;

  const percDoneAvgParts = [
    {
      label: t('Percentage Done'),
      width: percDoneAvg,
      fill: teal[500],
    },
    {
      label: t('Percentage Not Done'),
      width: 100 - percDoneAvg,
      fill: grey[500],
    },
  ];

  return (
    <View wrap={false}>
      <Boxed
        spacing={2}
        title={t('Activities Percentage Average')}
        content={
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text style={{ marginRight: '5px' }}>{`${Math.round(
              percDoneAvg
            )}%`}</Text>
            <ReportsPdfUiProgressBar parts={percDoneAvgParts} width="70%" />
          </View>
        }
      />
      <View style={{ height: '20px' }} />
    </View>
  );
}

export default ReportsPdfTemplatesPreziCountrySectionsActivitiesPercentage;
