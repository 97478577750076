import React from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import { DashboardContext } from '../_lib/context/dashboard-context';
import { getFilterOptions } from '../_utils/content-utils/filter-utils';
import { DashboardAppBarFilter } from './appbar/filter';

export function InterventionsFilter({ isDark }: any) {
  const { t } = useTranslation();
  const { dashboard, interventionsFilter, setInterventionsFilter, user } =
    React.useContext(DashboardContext);

  const [filterOptions, setFilterOptions] = React.useState<any>([]);
  const [selectedFilters, setSelectedFilters] = React.useState<any>([]);

  // -----------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------
  // handlers
  // -----------------------------------------------------------------------------------------

  const handleFilter = (event: any, filters: any) => {
    setInterventionsFilter(filters);
  };

  // -----------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------

  React.useEffect(() => {
    setFilterOptions(getFilterOptions(dashboard, t));
  }, [dashboard, t]);

  React.useEffect(() => {
    setSelectedFilters(
      interventionsFilter.filter((fi: any) => {
        let isOption = false;
        filterOptions.forEach((fO: any) => {
          if (isEqual(fO, fi)) isOption = true;
        });
        return isOption;
      })
    );
  }, [interventionsFilter, dashboard, t, filterOptions]);

  // -----------------------------------------------------------------------------------------

  if (user.isRegular) return null;

  return (
    <DashboardAppBarFilter
      label={`${dashboard.interventionName} ${t('filter')}`}
      options={filterOptions}
      selectedFilters={selectedFilters}
      onChange={handleFilter}
      isDark={isDark}
    />
  );
}
