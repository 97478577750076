import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';

import { ReportsContext } from '../../_lib/context/reports-context';

export function ReportsEditText({ formErrors }: any) {
  const { t } = useTranslation();
  const { report, reportData } = useContext(ReportsContext);

  return (
    <>
      <TextField
        label={t('Title')}
        variant="outlined"
        fullWidth
        required
        defaultValue={(!!report && report.title) || new Date().toDateString()}
        name="title"
        key={`title-${(report && report.title) || 'new'}`}
        error={!!formErrors && !!formErrors.title}
        helperText={formErrors && formErrors.title}
      />

      {reportData?.dashboard?.reportsDefaultTemplate === 'SPIRE' && (
        <TextField
          label={t('Description')}
          variant="outlined"
          fullWidth
          multiline
          rows={2}
          defaultValue={(report && report.description) || ''}
          key={`desc-${(report && report.description) || 'new'}`}
          name="description"
          sx={{ mt: 3 }}
        />
      )}
    </>
  );
}
