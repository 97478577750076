import React from 'react';
import { GraphqlLoadDataQueries } from './queries';

export function GraphqlLoadData() {
  const [load, setLoad] = React.useState(false);

  // React.useEffect(() => {
  //   setLoad(true);
  // }, []);

  return load ? <GraphqlLoadDataQueries /> : null;
}
