import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AttachFile from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

function FileUploadButton({
  isIcon,
  children,
}: {
  isIcon?: boolean;
  children: any;
}) {
  const { t } = useTranslation();
  if (isIcon) {
    return (
      <Tooltip title={t('Attach file') as string}>
        <IconButton component="label" size="large" color="primary">
          <AttachFile />
          {children}
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <Button
      component="label"
      size="small"
      variant="outlined"
      startIcon={<AttachFile />}
    >
      {t('Attach file')}
      {children}
    </Button>
  );
}

FileUploadButton.defaultProps = {
  isIcon: true,
};

export function FileUpload({ file, setFile, filetypes, isIcon }: any) {
  const { t } = useTranslation();
  return (
    <Box id="file-upload" sx={{ alignSelf: 'center' }}>
      {!!file && (
        <Button size="small" sx={{ mr: 1 }} onClick={() => setFile(null)}>
          {t('Clear')}
        </Button>
      )}
      <FileUploadButton isIcon={isIcon}>
        <input
          id="file-upload-input"
          type="file"
          hidden
          accept={filetypes}
          onChange={(event: any) => {
            if (event.target.files.length > 0) {
              setFile(event.target.files[0]);
            }
          }}
        />
      </FileUploadButton>
    </Box>
  );
}

export default FileUpload;
