import { View } from '@react-pdf/renderer';

import { PreziPage } from '../ui/page';
import { ReportsPdfTemplatesPreziCountryUiHeader } from '../ui/header';
import { ReportsPdfTemplatesPreziCountryUiSectionTitle } from '../ui/section-title';
import { ReportsPdfTemplatesPreziCountrySectionsTarget } from '../sections/targets';
import { ReportsPdfTemplatesPreziCountrySectionsFinance } from '../sections/finance';
import { ReportsPdfTemplatesPreziCountrySectionsActivitiesAreasCharts } from '../sections/activities/areas-charts';
import { ReportsPdfTemplatesPreziCountrySectionsActivitiesList } from '../sections/activities/lists';

import {
  getFinanceMethods,
  getFinanceSummary,
  getFinanciers,
  hasSomeFinance,
} from '../../../../../_utils/finance-utils';
import { getTargets } from '../../../../../_utils/targets-utils';

export function PdftTemplatePreziCountryPageOverview({
  dashboard,
  user,
  isNew,
  report,
  reportData,
  filterAreas,
  t,
}: any) {
  // --------------------------------------------------------------------
  // --------------------------------------------------------------------
  // finance + targets section
  // --------------------------------------------------------------------
  let showFinance = true;
  if (!hasSomeFinance(reportData.priorityAreas)) {
    showFinance = false;
  }

  let showTargets = true;
  const targets = getTargets(reportData.priorityAreas);
  if (targets.length === 0) showTargets = false;

  return (
    <PreziPage>
      <ReportsPdfTemplatesPreziCountryUiHeader
        t={t}
        dashboard={dashboard}
        reportData={reportData}
        report={report}
      />

      {/* Finance + Targets */}
      {(showFinance || showTargets) && (
        <>
          <ReportsPdfTemplatesPreziCountryUiSectionTitle
            primary={t('Principal Characteristics')}
          />

          <View>
            {showFinance && (
              <ReportsPdfTemplatesPreziCountrySectionsFinance
                t={t}
                dashboard={dashboard}
                reportData={reportData}
                areas={reportData.priorityAreas}
              />
            )}
            {showFinance && showTargets && <View style={{ height: '10px' }} />}
            {showTargets && (
              <ReportsPdfTemplatesPreziCountrySectionsTarget
                t={t}
                dashboard={dashboard}
                reportData={reportData}
                areas={reportData.priorityAreas}
              />
            )}
          </View>
        </>
      )}

      {/* Activities */}
      <ReportsPdfTemplatesPreziCountryUiSectionTitle primary={t('Progress')} />
      <ReportsPdfTemplatesPreziCountrySectionsActivitiesAreasCharts
        t={t}
        dashboard={dashboard}
        areas={reportData.priorityAreas}
      />
      <View style={{ height: '20px' }} />
      <ReportsPdfTemplatesPreziCountrySectionsActivitiesList
        t={t}
        dashboard={dashboard}
        reportData={reportData}
        areas={reportData.priorityAreas}
        debug
      />
    </PreziPage>
  );
}
