import React from 'react';
import { useMutation } from '@apollo/client';

import { DashboardContext } from '../_lib/context/dashboard-context';
import { CREATE_USER } from '../_lib/graphql/mutations';
import { logError } from '../_lib/error';

import { UserCreateDialog } from '../navbar/user-create-dialog';

export function CreateMember({
  setSelect,
  showAddUserDialog,
  setShowAddUserDialog,
}: any) {
  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const [loading, setLoading] = React.useState(false);

  const [createUserMutation] = useMutation(CREATE_USER);

  const createUser = (user: any) => {
    setLoading(true);
    return createUserMutation({
      variables: {
        ...user,
        dashboardId: dashboard.id,
        isAdmin: user.isAdmin || false,
      },
    })
      .then((result) => {
        if (setSelect)
          setSelect({
            ...result.data.createUser.user,
          });
        setShowAddUserDialog(false);
      })
      .catch((e) => {
        setSnackbarIsError(true);
        logError(e);
      })
      .finally(() => {
        setLoading(false);
        setSnackbarOpen(true);
      });
  };

  return (
    <UserCreateDialog
      loading={loading}
      onSubmit={createUser}
      onClose={() => setShowAddUserDialog(false)}
      open={showAddUserDialog}
    />
  );
}

export default CreateMember;
