import React from 'react';
import { useTranslation } from 'react-i18next';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Collapse from '@mui/material/Collapse';

import { ReportsContext } from '../../../_lib/context/reports-context';
import { DashboardContext } from '../../../_lib/context/dashboard-context';

export function ReportsEditFiltersAreas() {
  const { t } = useTranslation();

  const { dashboard } = React.useContext(DashboardContext);
  const { report, reportData, isNew, setFilterAreas } =
    React.useContext(ReportsContext);

  const [open, setOpen] = React.useState<any>([]);
  const [checked, setChecked] = React.useState<any>([]);

  const [priorityAreas, setPriorityAreas] = React.useState<any>(null);

  // -------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------------

  const handleOpen = (paId: any) => {
    const currentIndex = open.indexOf(paId);
    const newOpen = [...open];

    if (currentIndex === -1) {
      newOpen.push(paId);
    } else {
      newOpen.splice(currentIndex, 1);
    }

    setOpen(newOpen);
  };

  // check or uncheck all pa interventions and children
  function checkOrUncheck(pa: any, newChecked: any, check = true) {
    // interventions
    pa?.interventions?.forEach((intervention: any) => {
      const intvIndex = newChecked.indexOf(`intv-${intervention.id}`);
      if (intvIndex === -1 && check) {
        newChecked.push(`intv-${intervention.id}`);
      }
      if (intvIndex !== -1 && !check) {
        newChecked.splice(intvIndex, 1);
      }
    });

    // children
    if (dashboard.enableAreaLevels) {
      pa?.children?.forEach((child: any) => {
        const childIndex = newChecked.indexOf(`pa-${child.id}`);
        if (childIndex === -1 && check) {
          newChecked.push(`pa-${child.id}`);
        }
        if (childIndex !== -1 && !check) {
          newChecked.splice(childIndex, 1);
        }

        const childPa = reportData.priorityAreas.find(
          (paf: any) => paf.id === child.id
        );
        checkOrUncheck(childPa, newChecked, check);
      });
    }
  }

  const handleToggle = (areaId: any) => () => {
    const newChecked = [...checked];
    const currentIndex = newChecked.indexOf(areaId);

    if (currentIndex === -1) {
      newChecked.push(areaId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // check or uncheck all interventions of this pa
    if (areaId.startsWith('pa-')) {
      const paId = areaId.split('-')[1];
      const pa = reportData.priorityAreas.find((paf: any) => paf.id === paId);

      checkOrUncheck(pa, newChecked, currentIndex === -1);
    }

    setChecked(newChecked);
  };

  // -------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------------

  // set areas
  React.useEffect(() => {
    function sortPriorityAreas(pas: any) {
      if (dashboard.enableAreaLevels) {
        const newPas = [] as any;
        pas.forEach((pa: any) => {
          if (!pa.parent) {
            newPas.push(pa);
          } else {
            const parent = newPas.find((p: any) => p.id === pa.parent.id);
            if (parent) {
              if (
                parent.children &&
                !parent.children.find((c: any) => c.id === pa.id)
              ) {
                parent.children.push(pa);
              } else {
                parent.children = [pa];
              }
            }
          }
        });
        return newPas;
      }
      return pas;
    }

    if (reportData?.priorityAreas) {
      if (!report?.filterProgramme || report.filterProgramme.id === 'none') {
        setPriorityAreas(sortPriorityAreas(reportData.priorityAreas));
      } else {
        const prog = report.filterProgramme;
        const newPas = reportData.priorityAreas
          ?.map((pa: any) => {
            return {
              ...pa,
              interventions: pa.interventions.filter(
                (i: any) => i.programme && i.programme.id === prog.id
              ),
            };
          })
          .filter((pa: any) => pa.interventions.length > 0);

        setPriorityAreas(sortPriorityAreas(newPas));
      }
    }
  }, [reportData, report, dashboard.enableAreaLevels]);

  // set default values if new
  React.useEffect(() => {
    if (isNew && reportData && reportData.priorityAreas) {
      const priorityAreasIds = reportData.priorityAreas.map(
        (pa: any) => `pa-${pa.id}`
      );
      const interventionsIds = reportData.priorityAreas.reduce(
        (acc: any, curr: any) => {
          return [
            ...acc,
            ...(curr.interventions?.map(
              (intervention: any) => `intv-${intervention.id}`
            ) || []),
          ];
        },
        []
      );
      setChecked([...priorityAreasIds, ...interventionsIds]);
    }
  }, [reportData, isNew]);

  //  set values from report
  React.useEffect(() => {
    if (!isNew && report && report.id) {
      setChecked(
        report.filterAreas
          .map((fa: any) => {
            if (fa.priorityArea) return `pa-${fa.priorityArea.id}`;
            if (fa.intervention) return `intv-${fa.intervention.id}`;
            return null;
          })
          .filter((fa: any) => fa)
      );
    }
  }, [report, isNew]);

  // set filterareas from checked
  React.useEffect(() => {
    setFilterAreas(checked);
  }, [checked, setFilterAreas]);

  // -------------------------------------------------------------------------------------------------------------

  if (!reportData) return <Skeleton variant="rectangular" height={400} />;

  // -------------------------------------------------------------------------------------------------------------

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      aria-labelledby="areas-list-subheader"
      subheader={
        <ListSubheader component="div" id="areas-list-subheader">
          {!dashboard.enableAreaLevels
            ? dashboard.priorityAreaName
            : dashboard.areaLevels[0].name}{' '}
          &amp; {dashboard.interventionName}
        </ListSubheader>
      }
    >
      {priorityAreas?.map((pa: any) => {
        const labelId = `checkbox-pa-label-${pa.id}`;

        return [
          <ListItem
            key={pa.id}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="open"
                onClick={() => handleOpen(pa.id)}
              >
                <ExpandMore
                  sx={{
                    transition: 'all 0.2s ease-in-out',
                    transform:
                      open.indexOf(pa.id) !== -1
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                  }}
                />
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton
              role={undefined}
              onClick={handleToggle(`pa-${pa.id}`)}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(`pa-${pa.id}`) !== -1}
                  indeterminate={
                    !pa.interventions
                      ?.map((i: any) => `intv-${i.id}`)
                      .every((iId: any) => checked.indexOf(iId) === -1) &&
                    checked.indexOf(`pa-${pa.id}`) === -1
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${pa.reference ? `${pa.reference} - ` : ''}${
                  pa.name
                }`}
              />
            </ListItemButton>
          </ListItem>,

          // children
          <Collapse
            key={`${pa.id}-children`}
            in={open.indexOf(pa.id) !== -1}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {pa.children?.map((child: any) => {
                const labelIdIntv = `checkbox-child-label-${child.id}`;
                return (
                  <ListItemButton
                    key={child.id}
                    role={undefined}
                    onClick={handleToggle(`pa-${child.id}`)}
                    dense
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(`pa-${child.id}`) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelIdIntv }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelIdIntv}
                      primary={`${
                        child.reference ? `${child.reference} - ` : ''
                      }${child.name}`}
                      secondary={child.level.name}
                    />
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>,

          // interventions
          <Collapse
            key={`${pa.id}-interventions`}
            in={open.indexOf(pa.id) !== -1}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {pa.interventions?.map((intv: any) => {
                const labelIdIntv = `checkbox-intv-label-${intv.id}`;
                return (
                  <ListItemButton
                    key={intv.id}
                    role={undefined}
                    onClick={handleToggle(`intv-${intv.id}`)}
                    dense
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(`intv-${intv.id}`) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelIdIntv }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelIdIntv}
                      primary={`${
                        intv.reference ? `${intv.reference} - ` : ''
                      }${intv.name}`}
                      secondary={dashboard.interventionName}
                    />
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>,
        ];
      })}
      {!priorityAreas && (
        <ListItem>
          <ListItemText primary={t('No areas found')} />
        </ListItem>
      )}
    </List>
  );
}

export default ReportsEditFiltersAreas;
