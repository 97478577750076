import { Rect, Svg } from '@react-pdf/renderer';

interface PartsProps {
  label: string;
  width: any;
  fill: string;
}

export function ReportsPdfUiProgressBar({
  parts,
  width,
  height,
}: {
  parts: PartsProps[];
  width?: any;
  height?: any;
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 100 10"
      preserveAspectRatio="none"
    >
      {parts.map((part, index) => {
        const x =
          index === 0
            ? 0
            : parts.slice(0, index).reduce((acc, curr) => acc + curr.width, 0);

        const partWidth = part.width > 100 ? 100 : part.width;

        if (partWidth <= 0) return null;
        return (
          <Rect
            key={part.fill}
            x={x}
            y={0}
            height={20}
            width={partWidth}
            fill={part.fill}
          />
        );
      })}
    </Svg>
  );
}

ReportsPdfUiProgressBar.defaultProps = {
  width: '100%',
  height: 10,
};
