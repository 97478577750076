import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import '@fontsource/roboto/latin.css';

import '../../../../assets/css/base.css';

// a custom theme for this app
export const theme = createTheme({
  palette: {
    primary: {
      main: '#007681',
    },
    secondary: {
      main: '#4DC3CE',
    },
    background: {
      default: '#fff',
    },
  },
});

export function ThemeProviderDefault({ children }: any) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
