import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import {
  Badge,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';

import { DashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

import { SWITCH_DASHBOARD } from '../../../_lib/graphql/mutations';

import { DialogTitle } from '../../../DialogTitle/dialog-title';

import TbiLogo from '../../../../assets/img/tbilogo/dashboard.jpg';

export function SwitchDashboardDialog({ open, setOpen }: any) {
  const { t } = useTranslation();

  const {
    dashboard,
    user: { dashboards },
    setSnackbarOpen,
    setSnackbarIsError,
  } = React.useContext(DashboardContext);

  const [dashboardsOptions, setDashboardsOptions] = React.useState(dashboards);

  const [switchDashboardMutation, { loading }] = useMutation(SWITCH_DASHBOARD);

  const switchDashboards = async (dashboardId: any) => {
    switchDashboardMutation({ variables: { dashboardId } })
      .then(() => {
        window.localStorage.clear();
        window.location.pathname = '/';
        window.location.reload();
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      });
  };

  const handleFilter = (event: any) => {
    const { value } = event.target;
    const filteredDashboards = dashboards.filter(
      (d: any) =>
        d.name?.toLowerCase().includes(value.toLowerCase()) ||
        d.country?.toLowerCase().includes(value.toLowerCase()) ||
        `${d.id}`.includes(value)
    );
    setDashboardsOptions(filteredDashboards);
  };

  return (
    <Dialog maxWidth="xs" fullWidth onClose={() => setOpen(false)} open={open}>
      <DialogTitle onClose={() => setOpen(false)}>
        <SwapHorizRoundedIcon sx={{ mr: 2 }} />
        {t('Switch Dashboards')}
      </DialogTitle>

      <DialogContent dividers>
        {!!loading && (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {!loading && (
          <>
            {dashboards?.length > 5 && (
              <Box sx={{ pt: 1, pb: 2, px: 1 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  label={`${t('Search')}/${t('Filter')}`}
                  InputProps={{
                    autoComplete: 'off',
                  }}
                  onChange={handleFilter}
                  autoFocus
                />
              </Box>
            )}

            <List
              sx={{
                border: 1,
                borderColor: 'grey.300',
                borderRadius: 1,
                p: 0,
              }}
            >
              {dashboardsOptions?.map((d: any, i: number) => (
                <React.Fragment key={d.id}>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => switchDashboards(d.id)}
                      id="choose-dashboard"
                      disabled={loading || d.id === dashboard.id}
                      sx={{ alignItems: 'flex-start' }}
                    >
                      <Badge
                        color="primary"
                        variant={d.id === dashboard.id ? 'dot' : 'standard'}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        sx={{
                          my: 1,
                          mr: 2,
                        }}
                      >
                        <img
                          alt="Dashboard Logo"
                          src={
                            d.logo.includes('/static/images/tbilogo.jpg') &&
                            process.env.REACT_APP_IS_V3 === 'true'
                              ? TbiLogo
                              : d.logo
                          }
                          height="40"
                          onError={(e: any) => {
                            if (e.target.src !== TbiLogo) {
                              e.target.src = TbiLogo;
                            }
                          }}
                        />
                      </Badge>
                      <ListItemText
                        primary={d.name}
                        secondary={`${
                          d.mode === 'TRACKER' ? `${t('Tracker Only')} . ` : ''
                        }${
                          d.mode === 'SUPRANATIONAL'
                            ? `${t('Dashboard of Dashboards')} . `
                            : ''
                        }${d.country || t('No Country')} . ${d.language}`}
                      />
                    </ListItemButton>
                  </ListItem>
                  {i < dashboards.length - 1 && <Divider component="li" />}
                </React.Fragment>
              ))}
            </List>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
