import { gql } from '@apollo/client';

import contextDashboard from './subscriptions/context/dashboard.graphql';
import contextUser from './subscriptions/context/user.graphql';
import contextUsers from './subscriptions/context/users.graphql';

import importExport from './subscriptions/import-export.graphql';
import importExports from './subscriptions/import-exports.graphql';

import dashboardOverview from './subscriptions/dashboard-overview.graphql';

import priorityAreasPlanning from './subscriptions/area/priority-area/priority-areas-planning.graphql';
import priorityAreaPlanningDelete from './subscriptions/area/priority-area/priority-area-planning-delete.graphql';
import priorityArea from './subscriptions/area/priority-area/priority-area.graphql';
import priorityAreas from './subscriptions/area/priority-area/priority-areas.graphql';
import priorityAreaTracker from './subscriptions/area/priority-area/priority-area-tracker.graphql';
import priorityAreasHome from './subscriptions/area/priority-area/priority-areas-home.graphql';
import priorityAreaSummary from './subscriptions/area/priority-area/priority-area-summary.graphql';

import intervention from './subscriptions/area/intervention/intervention.graphql';
import interventions from './subscriptions/area/intervention/interventions.graphql';
import interventionsPlanning from './subscriptions/area/intervention/interventions-planning.graphql';
import interventionsPriorityAreaPlanning from './subscriptions/area/intervention/interventions-priority-area-planning.graphql';
import interventionSummary from './subscriptions/area/intervention/intervention-summary.graphql';

import areaPermissions from './subscriptions/area/permissions.graphql';

import trackerLanding from './subscriptions/tracker-landing.graphql';
import activityTracker from './subscriptions/activity-tracker.graphql';

import getContent from './subscriptions/content/get-content.graphql';
import getMyContent from './subscriptions/content/get-my-content.graphql';

import getContentBlockers from './subscriptions/content/get-content-blockers.graphql';

import milestonesPlanning from './subscriptions/area/milestones-planning.graphql';

import contentGroup from './subscriptions/content-group.graphql';
import contentGroups from './subscriptions/content-groups.graphql';

import reports from './subscriptions/report/reports.graphql';

import areaLevels from './subscriptions/area/area-levels.graphql';

export const CONTEXT_DASHBOARD_SUBSCRIPTION = contextDashboard;
export const CONTEXT_USER_SUBSCRIPTION = contextUser;
export const CONTEXT_USERS_SUBSCRIPTION = contextUsers;

export const IMPORT_EXPORT_SUBSCRIPTION = importExport;
export const IMPORT_EXPORTS_SUBSCRIPTION = importExports;

export const DASHBOARD_OVERVIEW_SUBSCRIPTION = dashboardOverview;

export const AREA_PERMISSIONS_SUBSCRIPTION = areaPermissions;

export const PRIORITY_AREA_SUBSCRIPTION = priorityArea;
export const PRIORITY_AREAS_SUBSCRIPTION = priorityAreas;
export const PRIORITY_AREA_TRACKER_SUBSCRIPTION = priorityAreaTracker;
export const PRIORITY_AREAS_PLANNING_SUBSCRIPTION = priorityAreasPlanning;
export const PRIORITY_AREA_PLANNING_DELETE_SUBSCRIPTION =
  priorityAreaPlanningDelete;
export const PRIORITY_AREAS_HOME_SUBSCRIPTION = priorityAreasHome;
export const PRIORITY_AREA_SUMMARY_SUBSCRIPTION = priorityAreaSummary;

export const INTERVENTION_SUBSCRIPTION = intervention;
export const INTERVENTIONS_SUBSCRIPTION = interventions;
export const INTERVENTIONS_PLANNING_SUBSCRIPTION = interventionsPlanning;
export const INTERVENTIONS_PRIORITY_AREA_PLANNING_SUBSCRIPTION =
  interventionsPriorityAreaPlanning;
export const INTERVENTION_SUMMARY_SUBSCRIPTION = interventionSummary;

export const TRACKER_LANDING_SUBSCRIPTION = trackerLanding;
export const ACTIVITY_TRACKER_SUBSCRIPTION = activityTracker;

export const GET_CONTENT_SUBSCRIPTION = getContent;
export const GET_MY_CONTENT_SUBSCRIPTION = getMyContent;

export const GET_CONTENT_BLOCKERS_SUBSCRIPTION = getContentBlockers;

export const MILESTONES_PLANNING_SUBSCRIPTION = milestonesPlanning;

export const CONTENT_GROUP_SUBSCRIPTION = contentGroup;
export const CONTENT_GROUPS_SUBSCRIPTION = contentGroups;

export const REPORTS_SUBSCRIPTION = reports;

export const AREA_LEVELS_SUBSCRIPTION = areaLevels;

export const LAST_LOGIN_SUBSCRIPTION = gql`
  subscription LastLogin($userId: ID!) {
    user(id: $userId) {
      lastLogin
    }
  }
`;
