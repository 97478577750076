import React from 'react';
import { install } from 'resize-observer';

import { CommonProviders } from '@institutegc/digital-dashboard-ui/src/_lib/providers/common';

import { Routes } from './pages/_routes';

export function App() {
  // install missing polyfills
  React.useEffect(() => {
    install();
  });

  return (
    <CommonProviders>
      <Routes />
    </CommonProviders>
  );
}
