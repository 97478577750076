const isEqual = require('lodash/isEqual');

export const getFilterOptions = (dashboard: any, t: any) => {
  // Fetch intervention financiers
  const interventionFinanciersObject = {} as any;
  dashboard?.priorityAreas?.forEach((priorityArea: any) => {
    priorityArea?.interventions?.forEach((intervention: any) => {
      intervention?.financiers?.forEach((financier: any) => {
        interventionFinanciersObject[financier.name] = true;
      });
    });
  });
  const interventionFinanciers = Object.keys(interventionFinanciersObject);

  // ---------------------------------------------------------------------------

  // programme
  const filterOptionsProgramme = dashboard?.programmes?.map((project: any) => {
    return { ...project, type: 'project', typeLabel: t('PROGRAMME') };
  });

  // statuses
  const filterOptionsStatus = dashboard?.statuses?.map((status: any) => {
    return {
      name: status.name,
      id: status.id,
      type: 'status',
      typeLabel: t('STATUS'),
    };
  });

  // financiers
  const filterOptionsFinanciers = interventionFinanciers
    .filter((financier) => financier && financier !== 'null')
    .map((financier) => {
      return {
        name: financier,
        type: 'financier',
        typeLabel: t('FINANCIER'),
      };
    });

  // stakeholders
  const filterOptionsStakeholders = dashboard?.stakeholders?.map(
    (stakeholder: any) => {
      return {
        name: stakeholder.name,
        id: stakeholder.id,
        type: 'stakeholder',
        typeLabel: t('STAKEHOLDER'),
      };
    }
  );

  // ---------------------------------------------------------------------------

  // default
  const filterOptions = filterOptionsProgramme
    ?.concat(filterOptionsStatus)
    ?.concat(filterOptionsFinanciers);

  // stakeholders
  if (dashboard.enableAreaStakeholders) {
    filterOptions.push(...filterOptionsStakeholders);
  }

  return filterOptions;
};

// Get filters that are valid from the options
export const getValidFilters = ({ dashboard, t, filters, i18n }: any) => {
  const filterOptions = getFilterOptions(dashboard, t);

  return filters.filter((fi: any) => {
    let isOption = false;
    filterOptions?.forEach((fO: any) => {
      if (isEqual(fO, fi)) isOption = true;
      // language is enabled after load
      if (
        !isOption &&
        fO.type === fi.type &&
        fO.id === fi.id &&
        i18n &&
        i18n.language !== dashboard.language
      ) {
        isOption = true;
      }
    });
    return isOption;
  });
};

// Get filter object to use with sending to dashboard
export const getFiltersObject = ({ dashboard, t, filters, i18n }: any) => {
  const validFilters = getValidFilters({ dashboard, t, filters, i18n });

  const filtersObject = {} as any;
  validFilters.forEach((filter: any) => {
    if (filtersObject[filter.type.toLowerCase()]) {
      filtersObject[filter.type.toLowerCase()].push(filter);
    } else {
      filtersObject[filter.type.toLowerCase()] = [filter];
    }
  });
  return filtersObject;
};
