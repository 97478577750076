import { View, Text } from '@react-pdf/renderer';
import Boxed from '../ui/boxed';

export function PdftTemplatePreziCountrySectionsAreaHeader({
  t,
  dashboard,
  reportData,
  area,
  areaName,
}: any) {
  const priorityArea = !area.interventions
    ? reportData.priorityAreas?.find((pa: any) =>
        pa.interventions?.find((intv: any) => intv.id === area.id)
      )
    : null;

  const boxWidth = priorityArea ? '32%' : '48%';

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      {!!priorityArea && (
        <Boxed
          width={boxWidth}
          spacing={1}
          title={
            !dashboard.enableAreaLevels
              ? dashboard.priorityAreaName
              : priorityArea.level.name
          }
          content={`${
            priorityArea.reference && `${priorityArea.reference} - `
          }${priorityArea.name}`}
        />
      )}
      <Boxed
        width={boxWidth}
        spacing={1}
        title={areaName}
        content={`${area.reference && `${area.reference} - `}${area.name}`}
      />
      <Boxed
        width={boxWidth}
        spacing={1}
        title={t('Lead')}
        content={area.lead?.name || '-'}
      />
    </View>
  );
}
