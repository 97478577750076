import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Chip from '@mui/material/Chip';

import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LaunchIcon from '@mui/icons-material/Launch';
import Logout from '@mui/icons-material/Logout';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SettingsIcon from '@mui/icons-material/Settings';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AddchartIcon from '@mui/icons-material/Addchart';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useOnlineStatus } from '../_lib/offline/use-online-status';

import { DashboardAppBar } from './appbar';
import { ChangeUserDetails } from './change-user-details';
import { InterventionsFilter } from './interventions-filter';

import { SwitchDashboardDialog } from './navbar-menu/switch-dashboard/dialog';
import { SwitchMemberDialog } from './navbar-menu/switch-member/dialog';

import { DashboardContext } from '../_lib/context/dashboard-context';
import { CreateMember } from '../actions/create-member';
import { CreateDashboard } from '../actions/create-dashboard';
import { LaunchDashboard } from '../actions/launch-dashboard';

import BackgroundLetterAvatar from '../components/avatar-bg-letter';

declare const window: any;

export function DashboardNavbar() {
  const { t } = useTranslation();
  const history = useHistory();

  const isOnline = useOnlineStatus();

  const { dashboard, user } = React.useContext(DashboardContext);

  // ADMIN: Add Member
  const [openAddMemberDialog, setOpenAddMemberDialog] = React.useState(false);

  // Change Member Details Dialog
  const [openChangeMemberDetailsDialog, setOpenChangeMemberDetailsDialog] =
    React.useState(false);

  // Switch Dashboards Dialog
  const [openSwitchDashboardDialog, setOpenSwitchDashboardDialog] =
    React.useState(false);

  // SUPERUSER: Create Dashboard + Switch Member
  const [showCreateDashboardDialog, setShowCreateDashboardDialog] =
    React.useState(false);
  const [showSwitchMemberDialog, setShowSwitchMemberDialog] =
    React.useState(false);

  const path = useLocation().pathname;

  // Avatar Menu Items
  const avatarMenuItems = [];

  // Demo Site notification
  if (process.env.REACT_APP_IS_DEMO_SITE === 'true') {
    avatarMenuItems.push({
      type: 'component',
      component: (
        <Box sx={{ mt: 0.5, mb: 1, mx: 1.5 }}>
          <Alert severity="info">
            {t('This is a demo site. The data is deleted every hour.')}
          </Alert>
        </Box>
      ),
    });
  }

  // Account details
  avatarMenuItems.push({
    type: 'component',
    component: (
      <ListItem>
        <ListItemAvatar>
          <BackgroundLetterAvatar name={user.name} />
        </ListItemAvatar>
        <ListItemText
          primary={user.name}
          secondary={user.email}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>
    ),
  });
  avatarMenuItems.push({
    type: 'component',
    component: <Divider sx={{ my: 1 }} />,
  });

  // Admin options
  if (user.isAdmin) {
    // Dashboard planning and settings
    avatarMenuItems.push({
      type: 'item',
      id: 'view-planning',
      startIcon: <AssignmentIcon />,
      text: `${t('Planning')}`,
      component: Link,
      to: `${t('/planning')}/${dashboard.priorityAreasSlug}`,
    });
    avatarMenuItems.push({
      type: 'item',
      id: 'view-settings',
      startIcon: <SettingsIcon />,
      text: `${t('Dashboard Settings')}`,
      component: Link,
      to: t('/planning'),
    });

    // Add member
    avatarMenuItems.push({
      type: 'item',
      startIcon: <PersonAddIcon />,
      text: t('Add Member'),
      onClick: () => setOpenAddMemberDialog(true),
      disabled: !isOnline,
    });

    // Reports
    if (dashboard.mode !== 'TRACKER' && dashboard.enableReports) {
      avatarMenuItems.push({
        type: 'divider',
      });
      avatarMenuItems.push({
        type: 'item',
        id: 'view-reports',
        startIcon: <SummarizeIcon />,
        text: (
          <>
            {t('Reports')}
            <Chip
              label={t('BETA')}
              color="info"
              variant="outlined"
              size="small"
              sx={{ ml: 1, height: '20px', fontSize: '0.6rem' }}
            />
          </>
        ),
        component: Link,
        to: t('/reports'),
      });
    }

    avatarMenuItems.push({
      type: 'divider',
    });
  }

  // My Account Details
  // Edit my account
  avatarMenuItems.push({
    type: 'item',
    startIcon: <BadgeOutlinedIcon />,
    text: t('Edit My Account'),
    onClick: () => setOpenChangeMemberDetailsDialog(true),
  });
  // My Content
  avatarMenuItems.push({
    type: 'item',
    startIcon: <TableChartOutlinedIcon />,
    text: t('My Activities'),
    onClick: () => history.push(t('/my-content')),
  });

  // Switch dashboards
  if (user?.dashboards?.length > 1) {
    avatarMenuItems.push({
      type: 'divider',
    });
    avatarMenuItems.push({
      type: 'item',
      startIcon: <SwapHorizRoundedIcon />,
      text: t('Switch Dashboard'),
      onClick: () => setOpenSwitchDashboardDialog(true),
      disabled: !isOnline,
    });
  }

  // Create Dashbaords
  if (user?.canCreateDashboards) {
    avatarMenuItems.push({
      type: 'divider',
    });
    // Create Dashboard
    avatarMenuItems.push({
      id: 'create-dashboard',
      type: 'item',
      startIcon: <AddchartIcon />,
      text: (
        <>
          {t('Create Dashboard')}{' '}
          <Chip
            label={t('NEW')}
            color="success"
            size="small"
            sx={{ ml: 1, height: '20px', fontSize: '0.6rem' }}
          />
        </>
      ),
      onClick: () => setShowCreateDashboardDialog(true),
      disabled: !isOnline,
    });
  }

  // Superuser
  if (user?.superuser) {
    // Switch member
    avatarMenuItems.push({
      type: 'item',
      text: t('Switch Member'),
      onClick: () => setShowSwitchMemberDialog(true),
      disabled: !isOnline,
    });
  }

  // Other Actions
  avatarMenuItems.push({
    type: 'divider',
  });
  avatarMenuItems.push({
    type: 'item',
    text: t('Documentation'),
    onClick: () => {
      const w = window.open('https://docs.digitaldashboard.cc/', '_blank');
      if (w) w.focus();
    },
    endIcon: <LaunchIcon />,
  });

  // Sign Out
  avatarMenuItems.push({
    type: 'divider',
  });
  avatarMenuItems.push({
    type: 'item',
    id: 'logout',
    startIcon: <Logout />,
    text: t('Sign out'),
    onClick: () => {
      window.location.href = '/accounts/logout/';
    },
  });

  // Reload Page
  avatarMenuItems.push({
    type: 'item',
    id: 'reload-page',
    startIcon: <RefreshIcon />,
    text: t('Reload Page'),
    onClick: () => {
      window.localStorage.clear();
      window.location.reload();
    },
  });

  return (
    <>
      {/* ADMIN: Add Member */}
      <CreateMember
        showAddUserDialog={openAddMemberDialog}
        setShowAddUserDialog={setOpenAddMemberDialog}
      />

      {/* User Details Edit dialog */}
      <ChangeUserDetails
        open={openChangeMemberDetailsDialog}
        onClose={() => setOpenChangeMemberDetailsDialog(false)}
      />

      {/* Switch  dashboards dialog */}
      <SwitchDashboardDialog
        open={openSwitchDashboardDialog}
        setOpen={setOpenSwitchDashboardDialog}
      />

      {/* Create Dashboard dialog */}
      {user.canCreateDashboards && (
        <CreateDashboard
          open={showCreateDashboardDialog}
          onClose={() => setShowCreateDashboardDialog(false)}
        />
      )}

      {/* Superuser items */}
      {user.superuser && showSwitchMemberDialog && (
        <SwitchMemberDialog
          open={showSwitchMemberDialog}
          setOpen={setShowSwitchMemberDialog}
        />
      )}

      {/* The APPBAR */}

      <DashboardAppBar
        logoSrc={dashboard.logo}
        title={dashboard.name}
        showInterventionsFilter={
          !!dashboard.enableFilter &&
          !path.startsWith(t('/planning')) &&
          !path.startsWith('/reports')
        }
        interventionsFilter={<InterventionsFilter />}
        launchDashboard={<LaunchDashboard />}
        showLaunchDashboard={
          !!dashboard.inPlanning && path.startsWith(t('/planning'))
        }
        avatarMenuItems={avatarMenuItems}
      />
    </>
  );
}
