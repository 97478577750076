import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import Boxed from '../../ui/boxed';
import PieChart from '../../../../ui/pie-chart';

export function ReportsPdfTemplatesPreziCountrySectionsActivitiesChart({
  t,
  dashboard,
  area,
  boxWidth,
  chartIndex,
}: any) {
  const parts = [] as any;
  let { contentSummary } = area.summary;
  if (!contentSummary) return null;

  const { metaStatusColors } = dashboard;

  contentSummary = Object.keys(contentSummary)
    .filter((key: any) => !['__typename'].includes(key))
    .reduce((acc: any, key: any) => {
      return {
        ...acc,
        [key]: contentSummary[key],
      };
    }, {});

  const total = Object.keys(contentSummary).reduce(
    (acc: number, key: string) => acc + (contentSummary[key]?.total || 0),
    0
  );

  Object.keys(contentSummary)
    .filter((key: string) => contentSummary[key]?.total > 0)
    .forEach((key: string) => {
      const colorKey = key === 'issues' ? 'issue' : key;

      parts.push({
        label: t(metaStatusColors[colorKey].text),
        width: (contentSummary[key].total / total) * 100,
        fill: metaStatusColors[colorKey]?.bgcolor || '#000000',
        textColor: metaStatusColors[colorKey]?.textcolor || '#ffffff',
      });
    });

  const pieParts = parts.map((part: any, x: number) => {
    if (x === 0) {
      return {
        ...part,
        strokeDasharray: `${part.width}, 100`,
      };
    }

    const gap = parts
      .slice(0, x)
      .reduce((acc: number, prt: any) => acc + prt.width, 0);
    return {
      ...part,
      strokeDasharray: `0, ${gap}, ${part.width}, 100`,
    };
  });

  if (parts.length === 0 || total === 0) return null;

  return (
    <React.Fragment key={`${area.id}`}>
      <Boxed
        flexDirection="column"
        width={boxWidth}
        spacing={2}
        wrap={false}
        title={
          `${area.reference ? `${area.reference} - ` : ''}${area.name}` ||
          `${t('Area Name Missing')}`
        }
        content={
          <View
            style={{
              width: '100%',
            }}
          >
            {!!area.interventions && (
              <Text style={{ textAlign: 'center' }}>
                {dashboard.interventionName}: {area.interventions.length}
              </Text>
            )}
            <PieChart parts={pieParts} />
          </View>
        }
      />
      {!!chartIndex && chartIndex % 5 === 4 && (
        <View style={{ width: '100%', height: '10px' }} />
      )}
    </React.Fragment>
  );
}

export default ReportsPdfTemplatesPreziCountrySectionsActivitiesChart;
