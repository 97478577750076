import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReportsHome } from '../reports/home';
import { ReportsEdit } from '../reports/edit';

export function ReportsScreen() {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ReportsHome />
      </Route>
      <Route exact path={`${path}${t('/new')}`}>
        <ReportsEdit isNew />
      </Route>
      <Route path={`${path}/:reportId`}>
        <ReportsEdit />
      </Route>
    </Switch>
  );
}
