import { View } from '@react-pdf/renderer';

import { PreziPage } from '../ui/page';
import { ReportsPdfTemplatesPreziCountryUiHeader } from '../ui/header';
import { ReportsPdfTemplatesPreziCountryUiSectionTitle } from '../ui/section-title';

import { ReportsPdfTemplatesPreziCountrySectionsActivitiesPercentage } from '../sections/activities/percentage';
import { ReportsPdfTemplatesPreziCountrySectionsActivitiesMilestones } from '../sections/activities/milestones';
import { ReportsPdfTemplatesPreziCountrySectionsActivitiesAreasCharts } from '../sections/activities/areas-charts';
import { ReportsPdfTemplatesPreziCountrySectionsActivitiesChart } from '../sections/activities/chart';
import { ReportsPdfTemplatesPreziCountrySectionsActivitiesList } from '../sections/activities/lists';

import { ReportsPdfTemplatesPreziCountrySectionsTarget } from '../sections/targets';
import { ReportsPdfTemplatesPreziCountrySectionsFinance } from '../sections/finance';
import { PdftTemplatePreziCountrySectionsAreaHeader } from '../sections/area-header';

import { hasSomeFinance } from '../../../../../_utils/finance-utils';
import { getTargets } from '../../../../../_utils/targets-utils';

export function PdftTemplatePreziCountryPageArea({
  filterArea,
  dashboard,
  user,
  isNew,
  report,
  reportData,
  filterAreas,
  t,
}: any) {
  // determine the area
  const { priorityAreas } = reportData;
  let area = null as any;
  let areaName = '';

  priorityAreas.forEach((pa: any) => {
    if (filterArea === `pa-${pa.id}`) {
      area = pa;
      areaName = !dashboard.enableAreaLevels
        ? dashboard.priorityAreaName
        : pa.level.name;
    }
    pa.interventions?.forEach((intv: any) => {
      if (filterArea === `intv-${intv.id}`) {
        area = intv;
        areaName = dashboard.interventionName;
      }
    });
  });

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // finance + targets section
  // -------------------------------------------------------------------------------------------------

  let showFinance = true;
  if (!area || !hasSomeFinance([area])) {
    showFinance = false;
  }

  let showTargets = true;
  const targets = getTargets([area]);
  if (targets.length === 0) showTargets = false;

  // -------------------------------------------------------------------------------------------------

  return (
    <PreziPage>
      <ReportsPdfTemplatesPreziCountryUiHeader
        t={t}
        dashboard={dashboard}
        reportData={reportData}
        area={area}
        areaName={areaName}
        report={report}
      />
      <ReportsPdfTemplatesPreziCountryUiSectionTitle
        primary={t('Principal Characteristics')}
      />

      {/* Area Header */}
      <PdftTemplatePreziCountrySectionsAreaHeader
        t={t}
        dashboard={dashboard}
        reportData={reportData}
        area={area}
        areaName={areaName}
      />

      {/* Finance + Targets */}
      {showFinance ||
        (showTargets && (
          <>
            <ReportsPdfTemplatesPreziCountryUiSectionTitle
              primary={t('Finance and Targets')}
            />
            {showFinance && (
              <ReportsPdfTemplatesPreziCountrySectionsFinance
                t={t}
                dashboard={dashboard}
                reportData={reportData}
                areas={[area]}
              />
            )}
            {(showFinance || showTargets) && (
              <View style={{ height: '10px' }} />
            )}
            {showTargets && (
              <ReportsPdfTemplatesPreziCountrySectionsTarget
                t={t}
                dashboard={dashboard}
                reportData={reportData}
                areas={[area]}
              />
            )}
          </>
        ))}

      {/* Activities */}

      <ReportsPdfTemplatesPreziCountryUiSectionTitle primary={t('Progress')} />

      {/* Milestones */}
      <ReportsPdfTemplatesPreziCountrySectionsActivitiesMilestones
        t={t}
        dashboard={dashboard}
        area={area}
      />

      {/* Percentage Complete */}
      {dashboard.enableActivityPercentage && (
        <ReportsPdfTemplatesPreziCountrySectionsActivitiesPercentage
          t={t}
          dashboard={dashboard}
          area={area}
        />
      )}

      {/* Chart */}
      {filterArea.startsWith('pa-') ? (
        <ReportsPdfTemplatesPreziCountrySectionsActivitiesAreasCharts
          t={t}
          dashboard={dashboard}
          areas={area.interventions}
        />
      ) : (
        <View>
          <ReportsPdfTemplatesPreziCountrySectionsActivitiesChart
            t={t}
            dashboard={dashboard}
            area={area}
            boxWidth="100%"
            chartIndex={0}
          />
        </View>
      )}
      <View style={{ height: '20px' }} />

      {/* List */}
      <ReportsPdfTemplatesPreziCountrySectionsActivitiesList
        t={t}
        dashboard={dashboard}
        areas={[area]}
      />
    </PreziPage>
  );
}
