import { View } from '@react-pdf/renderer';

import { ReportsPdfTemplatesPreziCountrySectionsActivitiesChart } from './chart';

export function ReportsPdfTemplatesPreziCountrySectionsActivitiesAreasCharts({
  t,
  dashboard,
  areas,
}: any) {
  const areasFiltered = areas?.filter((area: any) => {
    let contentSummary = area.summary?.contentSummary;
    if (!contentSummary) return false;

    contentSummary = Object.keys(contentSummary)
      .filter((key: any) => !['__typename'].includes(key))
      .reduce((ac: any, key: any) => {
        return {
          ...ac,
          [key]: contentSummary[key],
        };
      }, {});

    const total = Object.keys(contentSummary).reduce(
      (sum: number, key: string) => sum + (contentSummary[key]?.total || 0),
      0
    );
    if (total === 0) return false;

    return true;
  });

  if (!areasFiltered) return <View />;

  const chartBoxWidth =
    areasFiltered.length < 5 && areasFiltered.length > 0
      ? `${98 / areasFiltered.length}%`
      : `${96 / 5}%`;

  return (
    <View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        {areasFiltered.map((area: any, x: number) => (
          <ReportsPdfTemplatesPreziCountrySectionsActivitiesChart
            key={area.id}
            t={t}
            dashboard={dashboard}
            area={area}
            boxWidth={chartBoxWidth}
            chartIndex={x}
          />
        ))}
      </View>
    </View>
  );
}

export default ReportsPdfTemplatesPreziCountrySectionsActivitiesAreasCharts;
