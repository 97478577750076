import { Svg, Circle, Text, View, Rect } from '@react-pdf/renderer';

import grey from '@mui/material/colors/grey';

interface PartsProps {
  label: string;
  width: number;
  fill: string;
  strokeDasharray: string;
}

export function ReportsPdfUiPieChart({
  parts,
  width,
  height,
}: {
  parts?: PartsProps[];
  width?: number;
  height?: number;
}) {
  const radius = 100 / (2 * Math.PI);
  const diamtr = radius * 2;
  const strokeWidth = 10;
  return (
    <>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          margin: '10px',
        }}
      >
        <Svg
          width={width}
          height={height}
          viewBox={`0 0 ${diamtr + strokeWidth} ${diamtr + strokeWidth}`}
          style={{
            transform: 'rotate(90deg)',
            maxWidth: '100%',
          }}
        >
          <Circle
            cx={radius + strokeWidth / 2}
            cy={radius + strokeWidth / 2}
            r={radius}
            fillOpacity={0}
            stroke={grey[500]}
            strokeWidth={strokeWidth}
          />
          {parts &&
            parts.map((part: PartsProps) => {
              return (
                <Circle
                  key={part.label}
                  cx={radius + strokeWidth / 2}
                  cy={radius + strokeWidth / 2}
                  r={radius}
                  fillOpacity={0}
                  stroke={part.fill}
                  strokeWidth={strokeWidth}
                  strokeDasharray={part.strokeDasharray}
                />
              );
            })}
        </Svg>
      </View>

      <View
        style={{
          fontSize: '10px',
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {parts &&
          parts.map((part: PartsProps) => {
            return (
              <View
                key={part.label}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                  margin: '3px 5px',
                  textAlign: 'left',
                }}
              >
                <Svg width="10px" height="10px" viewBox="0 0 10 10">
                  <Rect x={0} y={0} width={10} height={10} fill={part.fill} />
                </Svg>
                <Text style={{ marginLeft: '3px', flexGrow: 1 }}>
                  {part.label}
                </Text>
              </View>
            );
          })}
      </View>
    </>
  );
}

ReportsPdfUiPieChart.defaultProps = {
  parts: [],
  width: '100px',
  height: '100px',
};

export default ReportsPdfUiPieChart;
