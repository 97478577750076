import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import { DialogTitle } from '../../DialogTitle/dialog-title';

import * as serviceWorkerRegistration from '../../_lib/offline/serviceWorkerRegistration';
import { useOnlineStatus } from '../../_lib/offline/use-online-status';

export function ScreenInfoError() {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(true);

  const isOnline = useOnlineStatus();

  const handleClickReload = () => {
    if (isOnline) {
      window.localStorage.clear();
      serviceWorkerRegistration.unregister();
      window.location.reload();
    } else {
      window.location.reload();
    }
  };

  return (
    <Dialog open={openDialog} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpenDialog(false)} variant="h6">
        <Box component="span" sx={{ mr: 1.5 }}>
          ⚠️
        </Box>{' '}
        Error
      </DialogTitle>

      <DialogContent dividers>
        {t(
          'The application has encountered a problem. We have been notified. Please reload the page and try again.'
        )}
      </DialogContent>

      <DialogActions disableSpacing sx={{ p: 3 }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleClickReload}
              disableElevation
            >
              {t('Reload Page')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="info"
              fullWidth
              onClick={() => {
                if (isOnline) {
                  window.localStorage.clear();
                }
                window.location.href = '/';
              }}
              disableElevation
            >
              {t('Go to Homepage')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://tawk.to/chat/614ee0ad25797d7a8900c198/1fge1rail"
              >
                Send us a message
              </a>
            </Typography>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export function ScreenInfoErrorTest() {
  const [showError, setShowError] = React.useState(false);
  const ErrorTestError = () => {
    throw new Error('💥 CABOOM 💥');
  };

  return (
    <>
      <button type="button" onClick={() => setShowError(true)}>
        Launch Error Test
      </button>
      {showError && <ErrorTestError />}
    </>
  );
}

export function ScreenInfoErrorComponent() {
  const { t } = useTranslation();

  const isOnline = useOnlineStatus();
  return (
    <Box sx={{ p: 4, my: 3, textAlign: 'center', color: 'grey.700' }}>
      <CloudOffIcon fontSize="large" sx={{ mb: 1 }} />
      <Typography variant="h5">{t('Something went wrong')}</Typography>
      <Typography sx={{ mb: 1 }}>{t('Please try again')}</Typography>
      <Button
        onClick={() => {
          if (isOnline) {
            window.localStorage.clear();
          }
          window.location.reload();
        }}
      >
        {t('Reload Page')}
      </Button>
    </Box>
  );
}
