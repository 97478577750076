import { deepCopy } from './general-utils';

export const getFinanceSummary = (areas: any) => {
  return areas?.reduce(
    (acc: any, area: any) => {
      let financeProxy = {} as any;

      if (area.interventions) {
        financeProxy = area.summary;
      } else {
        financeProxy = {
          ...area,
          totalFunding: area?.financiers?.reduce((sum: any, f: any) => {
            return sum + f.amount;
          }, 0),
        };
      }

      acc.budget += financeProxy.budget || 0;
      acc.totalFunding += financeProxy.totalFunding || 0;
      acc.currentlySpent += financeProxy.currentlySpent || 0;

      return acc;
    },
    {
      budget: 0,
      totalFunding: 0,
      currentlySpent: 0,
    }
  );
};

export const getFinanciers = (areas: any) => {
  return areas
    .flatMap((area: any) => {
      if (area.financiers) {
        return area.financiers;
      }
      if (area.interventions) {
        return area.interventions.flatMap((intv: any) => {
          if (!intv.financiers) {
            return [];
          }
          return intv.financiers.map((f: any) => ({
            ...f,
            intervention: {
              id: intv.id,
              reference: intv.reference,
              name: intv.name,
            },
            priorityArea: {
              id: area.id,
              reference: area.reference,
              name: area.name,
            },
          }));
        });
      }
      return [];
    })
    .reduce((acc: any, cur: any) => {
      const fIndex = acc.findIndex((f: any) => f.name === cur.name);
      if (fIndex === -1) {
        acc.push(
          deepCopy({
            ...cur,
            interventions: [cur.intervention],
          })
        );
      } else {
        acc[fIndex].amount += cur.amount;
        acc[fIndex].interventions.push(cur.intervention);
      }
      return acc;
    }, [])
    .sort((a: any, b: any) => b.amount - a.amount);
};

export const getFinanceMethods = (areas: any) => {
  const financiers = getFinanciers(areas);
  return financiers
    .reduce((acc: any, f: any) => {
      const fmIndex = acc.findIndex((fm: any) => fm.name === f.financeMethod);
      if (fmIndex === -1) {
        acc.push(
          deepCopy({
            name: f.financeMethod,
            amount: f.amount,
          })
        );
      } else {
        acc[fmIndex].amount += f.amount;
      }
      return acc;
    }, [])
    .sort((a: any, b: any) => b.amount - a.amount)
    .slice(0, 5);
};

export const hasSomeFinance = (areas: any) => {
  const financeSummary = getFinanceSummary(areas);
  const financiers = getFinanciers(areas);
  const financeMethods = getFinanceMethods(areas);
  return [
    financeSummary.budget,
    financeSummary.totalFunding,
    financeSummary.currentlySpent,
    financiers.length,
    financeMethods.length,
  ].some((item: any) => item > 0);
};
