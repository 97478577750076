import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TextField, Link, Grid, Box, Typography, Paper } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { AuthScreenLayout } from '../../auth/_layout';
import { useAuth } from '../../_utils/use-auth';
import { logError } from '../../_lib/error';

export function ScreenAuthPasswordForgot() {
  const { forgotPassword } = useAuth();
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null as any);
  const [successful, setSuccessful] = React.useState(false);

  // Google Recaptcha
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [googleRecaptchaToken, setGoogleRecaptchaToken] = React.useState('');
  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      return null;
    }
    const token = await executeRecaptcha();
    setGoogleRecaptchaToken(token);
    setLoading(false);
    return token;
  }, [executeRecaptcha]);

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------

  // Validate the information
  const valid = (data: any) => {
    const newErrors = {} as any;
    if (!data.get('email')) {
      newErrors.email = true;
      setError(newErrors);
    }
    if (!googleRecaptchaToken) {
      newErrors.captcha = true;
      setError(newErrors);
    }
    return !newErrors.email && !newErrors.captcha;
  };

  const handleForgotPassword = (data: any) => {
    if (valid(data)) {
      setLoading(true);

      setError(null);
      setSuccessful(false);

      handleReCaptchaVerify().then(() => {
        forgotPassword(data.get('email'), googleRecaptchaToken)
          .then((r: any) => {
            if (!r.data.authForgotPassword.success) {
              setError({ other: true });
            } else {
              setSuccessful(true);
            }
            setLoading(false);
          })
          .catch((err: any) => {
            logError(err);
            setError({ other: true });
            setLoading(false);
          });
      });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    handleForgotPassword(data);
  };

  // -------------------------------------------------------------------------------------------------

  return (
    <AuthScreenLayout title="Forgot Password">
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1, width: '100%' }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label={t('Email Address')}
          name="email"
          autoComplete="email"
          autoFocus
          disabled={loading}
          error={(!!error && !!error.auth) || (!!error && !!error.email)}
        />

        {(!!error || !!successful) && (
          <Paper
            variant="outlined"
            sx={{
              backgroundColor: error ? '#ffcdd2' : '#c8e6c9',
              borderColor: error ? '#c62828' : '2e7d32',
              py: 1,
              px: 2,
              mt: 2,
            }}
          >
            <Typography variant="body2">
              {!!error && !!error.email && t(`Email cannot be empty.`)}
              {!!error &&
                !!error.captcha &&
                t(
                  `We are waiting to verify that you are human... Please try again.`
                )}
              {!!error &&
                !!error.other &&
                t(`Something went wrong. Please try again.`)}
              {!!successful && t(`Password reset instructions sent to email.`)}
            </Typography>
          </Paper>
        )}
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={loading}
        >
          {t('Recover Password')}
        </LoadingButton>
        <Grid container>
          <Grid item xs>
            <Link component={RouterLink} to="/" variant="body2">
              {t('Sign In')}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </AuthScreenLayout>
  );
}
