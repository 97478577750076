import { View, Text } from '@react-pdf/renderer';

import grey from '@mui/material/colors/grey';

import { CurrencyText } from '../../../../../../components/currency-text';
import { getFinanciers } from '../../../../../../_utils/finance-utils';

export function ReportsPdfTemplatesPreziCountrySectionsFinanceFinanciers({
  t,
  dashboard,
  areas,
}: any) {
  const allFinanciers = getFinanciers(areas);

  const financiers = allFinanciers.slice(0, 5);

  return (
    <View style={{ width: '100%', textAlign: 'left' }}>
      {financiers.map((f: any, i: number) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <View key={i} style={{ margin: '3px' }}>
            <Text
              style={{
                fontStyle: !f.name ? 'italic' : 'normal',
                color: !f.name ? grey[700] : 'black',
              }}
            >
              {f.name || t('Name Missing')}
            </Text>
            <View style={{ paddingLeft: '7px' }}>
              <Text style={{ fontSize: '10px' }}>
                <CurrencyText amount={f.amount} dashboard={dashboard} />
                {f.financeMethod && (
                  <>
                    {' . '}
                    {f.financeMethod}
                  </>
                )}
              </Text>
              {f.interventions?.length > 0 && (
                <Text
                  style={{
                    fontSize: '9px',
                    color: grey[700],
                    textOverflow: 'ellipsis',
                    maxLines: 1,
                  }}
                >
                  {f.interventions
                    .map((intv: any) => `${intv.reference}. ${intv.name}`)
                    .join(', ')}
                </Text>
              )}
            </View>
          </View>
        );
      })}
      {allFinanciers.length > 5 && (
        <View style={{ margin: '3px' }}>
          <Text>
            {t('and')}{' '}
            <Text style={{ fontWeight: 'bold' }}>
              {allFinanciers.length - 5}{' '}
            </Text>
            {allFinanciers.length - 5 > 1 ? t('others') : t('other')}...
          </Text>
        </View>
      )}
    </View>
  );
}

export default ReportsPdfTemplatesPreziCountrySectionsFinanceFinanciers;
