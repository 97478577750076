import { Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import enLocale from 'date-fns/locale/en-GB';

import LinearProgress from '@mui/material/LinearProgress';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import '../language/i18n';

import { ReactError } from '../react-error';

import { ProvideOnlineStatus } from '../offline/use-online-status';

import { ThemeProviderDefault } from './theme/default';
import { ProvideApollo } from '../../_utils/use-apollo';
import { ProvideAuth } from '../../_utils/use-auth';
import { FirebaseEffects, FirebaseProvider } from './firebase';

import { PortalContainerProvider } from './portal';

import { OfflineEffects } from '../offline/effects';
import { OfflineSnackbar } from '../offline/snackbar';

export function CommonProviders({ children }: any) {
  return (
    <Suspense fallback={<LinearProgress sx={{ width: '100%' }} />}>
      <PortalContainerProvider>
        <ProvideOnlineStatus>
          <FirebaseProvider>
            <ErrorBoundary FallbackComponent={ReactError}>
              <ThemeProviderDefault>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enLocale}
                >
                  <HelmetProvider>
                    <ProvideApollo>
                      <ProvideAuth>
                        <BrowserRouter>
                          {children}
                          <OfflineEffects />
                          <OfflineSnackbar />
                          <FirebaseEffects />
                        </BrowserRouter>
                      </ProvideAuth>
                    </ProvideApollo>
                  </HelmetProvider>
                </LocalizationProvider>
              </ThemeProviderDefault>
            </ErrorBoundary>
          </FirebaseProvider>
        </ProvideOnlineStatus>
      </PortalContainerProvider>
    </Suspense>
  );
}
