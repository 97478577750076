import { useQuery } from '@apollo/client';

import { CONTENT_GROUP_QUERY } from '../../queries';

export function GraphqlLoadDataQueriesContentGroup({ contentGroupId }: any) {
  const { data: dataCg } = useQuery(CONTENT_GROUP_QUERY, {
    variables: {
      contentGroupId,
    },
  });

  return null;
}
