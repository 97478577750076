import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { Snackbar } from '../components/snackbar';
import { CREATE_DASHBOARD } from '../_lib/graphql/mutations';
import { CreateDashboardDialog } from '../navbar/create-dashboard-dialog';
import { logError } from '../_lib/error';

export function CreateDashboard({ open, onClose }: any) {
  const { t } = useTranslation();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const [createDashboardMutation, { loading }] = useMutation(CREATE_DASHBOARD);

  const createDashboard = (dashboard: any) =>
    createDashboardMutation({ variables: dashboard })
      .then(() => {
        setOpenSnackbar(true);
        onClose();
        window.localStorage.clear();
        window.location.reload();
      })
      .catch((error) => {
        logError(error);
      });

  return (
    <>
      <CreateDashboardDialog
        loading={loading}
        onSubmit={createDashboard}
        onClose={() => onClose()}
        open={open}
      />

      <Snackbar
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={t(
          'Dashboard setup successfully. The admin user has been emailed with further instructions'
        )}
      />
    </>
  );
}
