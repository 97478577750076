import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import grey from '@mui/material/colors/grey';
import green from '@mui/material/colors/green';

import {
  replaceSpacesOfIntlNumberFormat,
  withCommas,
} from '../../../../../_utils/general-utils';

import Layout from './layout';
import Boxed from '../ui/boxed';

import { ReportsPdfUiProgressBar } from '../../../ui/progress-bar';

import { getTargets } from '../../../../../_utils/targets-utils';

export function ReportsPdfTemplatesPreziCountrySectionsTarget({
  t,
  dashboard,
  areas,
}: any) {
  const targets = getTargets(areas);

  if (targets.length === 0) return null;

  const boxWidth =
    targets.length < 3
      ? `${96 / 3}%`
      : targets.length < 5
      ? `${98 / targets.length}%`
      : `${96 / 5}%`;

  return (
    <Layout title={t('Targets')}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {targets.map((tgt: any, i: number) => {
          const progresssParts = [
            {
              label: t('Achieved'),
              width: (tgt.latestAmount / tgt.targetAmount) * 100,
              fill: green[800],
            },
            {
              label: t('Remaining'),
              width:
                (1 - tgt.latestAmount / tgt.targetAmount) * 100 > 0
                  ? (1 - tgt.latestAmount / tgt.targetAmount) * 100
                  : 0,
              fill: grey[500],
            },
          ];

          return (
            <React.Fragment
              // eslint-disable-next-line react/no-array-index-key
              key={`${tgt.targetUnit?.name}-${i}-${tgt.targetAmount}`}
            >
              <Boxed
                flexDirection="column"
                width={boxWidth}
                spacing={2}
                wrap={false}
                title={tgt.targetUnit?.name}
                titleFlexGrow={0}
                contentFlexGrow={1}
                content={
                  <View style={{ textAlign: 'left', width: '100%' }}>
                    <Text>
                      <Text style={{ fontSize: '10px' }}>
                        {`${t('Target')}: `}
                      </Text>
                      {replaceSpacesOfIntlNumberFormat(
                        withCommas(tgt.targetAmount, dashboard.language)
                      )}
                    </Text>
                    {tgt.startAmount > 0 && (
                      <Text>
                        <Text style={{ fontSize: '10px' }}>
                          {`${t('Start')}: `}
                        </Text>
                        {replaceSpacesOfIntlNumberFormat(
                          withCommas(tgt.startAmount, dashboard.language)
                        )}
                      </Text>
                    )}
                    <Text>
                      <Text style={{ fontSize: '10px' }}>{t('Deadline')}:</Text>{' '}
                      {tgt.deadline}
                    </Text>
                    <Text>
                      <Text style={{ fontSize: '10px' }}>
                        {`${t('Start Date')}: `}
                      </Text>
                      {tgt.startedOn}
                    </Text>

                    {/* Progress Bar */}
                    <View style={{ margin: '5px 0' }}>
                      <ReportsPdfUiProgressBar parts={progresssParts} />
                    </View>

                    {/* Latest Update */}
                    {tgt.latestDate !== tgt.startDate &&
                      tgt.latestAmount !== tgt.startAmount && (
                        <>
                          <View
                            style={{
                              borderBottom: `1px solid ${grey[500]}`,
                              margin: '5px 0',
                            }}
                          />
                          <Text
                            style={{
                              fontSize: '11px',
                              color: grey[700],
                              marginBottom: '3px',
                            }}
                          >
                            {t('Latest Update')}
                          </Text>
                          <Text>
                            <Text style={{ fontSize: '10px' }}>
                              {`${t('Date')}: `}
                            </Text>
                            {tgt.latestDate}
                          </Text>
                          <Text>
                            <Text style={{ fontSize: '10px' }}>
                              {`${t('Amt')}: `}
                            </Text>
                            {replaceSpacesOfIntlNumberFormat(
                              withCommas(tgt.latestAmount, dashboard.language)
                            )}{' '}
                            <Text style={{ fontSize: '10px' }}>
                              {tgt.targetUnit?.name}
                            </Text>
                          </Text>
                        </>
                      )}
                  </View>
                }
              />
              {i % 5 === 4 && (
                <View style={{ width: '100%', height: '10px' }} />
              )}
            </React.Fragment>
          );
        })}
      </View>
    </Layout>
  );
}
