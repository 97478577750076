import React from 'react';

import { useTranslation } from 'react-i18next';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';

import { getGraphQLDate } from '../_utils/general-utils';

export function DateInput({
  date,
  setDate,
  label,
  id,
  fullWidth,
  min,
  max,
  loading,
  errorText,
  clearable,
  size,
  disabled,
}: any) {
  const { t } = useTranslation();

  const handleChange = (newValue: Date | null) => {
    setDate(newValue ? getGraphQLDate(newValue) : null);
  };

  const handleClickClear = () => {
    setDate(null);
  };

  const handleMouseDownClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <DatePicker
      label={label}
      inputFormat="dd/MM/yyyy"
      value={(!!date && new Date(date)) || null}
      onChange={handleChange}
      maxDate={(!!max && new Date(max)) || undefined}
      minDate={(!!min && new Date(min)) || undefined}
      renderInput={(params: any) => (
        <TextField
          id={id}
          {...params}
          fullWidth={fullWidth}
          disabled={loading || disabled}
          error={!!errorText}
          helperText={errorText}
          size={size || 'medium'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {clearable && !!date && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t('Clear date')}
                      onClick={handleClickClear}
                      onMouseDown={handleMouseDownClear}
                      edge="end"
                      disabled={loading || disabled}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )}
                {params.InputProps?.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
