import React from 'react';

import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import { DashboardContext } from '../../../_lib/context/dashboard-context';

import { NavbarAppBarSearchDialog } from './dialog';

export function NavbarAppBarSearch() {
  const [openDialog, setOpenDialog] = React.useState(false);

  const { dashboard } = React.useContext(DashboardContext);

  // todo: remove once content search available
  if (dashboard.mode === 'TRACKER') return null;

  return (
    <>
      {/* dialog */}
      <NavbarAppBarSearchDialog open={openDialog} setOpen={setOpenDialog} />

      {/* button */}
      <IconButton
        aria-label="search"
        color="inherit"
        onClick={() => setOpenDialog(true)}
      >
        <SearchIcon />
      </IconButton>
    </>
  );
}
