import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function SwitchMemberMenuSecondary({ option }: any) {
  const pasNames =
    option.permissions?.priorityAreas?.map(
      (pa: any) => `${pa.reference ? `${pa.reference} - ` : ''}${pa.name}`
    ) || [];

  const intvNames =
    option.permissions?.interventions?.map(
      (i: any) => `${i.reference ? `${i.reference} - ` : ''}${i.name}`
    ) || [];

  const areaNames =
    pasNames.length > 0
      ? pasNames.join(', ')
      : intvNames.length > 0
      ? intvNames.join(', ')
      : '';

  const areaType = pasNames.length > 0 ? 'Priority Areas' : 'Interventions';

  return (
    <Tooltip title={areaNames ? `${areaType}: ${areaNames}` : ''}>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {option.email}
        </Typography>
        {areaNames && (
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {areaType}
            {': '}
            {areaNames}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
}
