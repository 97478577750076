import { View, Text } from '@react-pdf/renderer';

export function ReportsPdfTemplatesPreziCountrySectionsLayout({
  title,
  children,
}: any) {
  return (
    <View>
      <Text
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          marginBottom: '10px',
        }}
      >
        {title || 'Title Goes Here'}
      </Text>
      {children}
    </View>
  );
}

export default ReportsPdfTemplatesPreziCountrySectionsLayout;
