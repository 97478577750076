import { View, Text } from '@react-pdf/renderer';

import grey from '@mui/material/colors/grey';

export function ReportsPdfUiMilestones({ t, area, allContent }: any) {
  return (
    <View
      style={{
        marginTop: 40,
        paddingTop: 20,
        paddingBottom: 20,
        borderTop: 1,
        borderColor: grey[400],
      }}
    >
      <Text style={{ fontSize: 20, marginBottom: 5 }}>{t('Milestones')}</Text>

      {!area.milestones || area.milestones.length === 0 ? (
        <Text
          style={{
            marginBottom: 7,
            fontSize: 11,
            color: grey[600],
          }}
        >
          {t('No Milestones')}
        </Text>
      ) : (
        <Text
          style={{
            marginBottom: 7,
            fontSize: 11,
            color: grey[600],
          }}
        >
          {t('Milestones will be shown here')}
        </Text>
      )}
    </View>
  );
}
