import React from 'react';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import LinearProgress from '@mui/material/LinearProgress';

import { useLocalStorage } from '../../_utils/use-local-storage';
import { getFiltersObject } from '../../_utils/content-utils/filter-utils';

import {
  CONTEXT_DASHBOARD_QUERY,
  CONTEXT_USERS_QUERY,
  CONTEXT_USER_QUERY,
} from '../graphql/queries';
import {
  CONTEXT_DASHBOARD_SUBSCRIPTION,
  CONTEXT_USERS_SUBSCRIPTION,
  CONTEXT_USER_SUBSCRIPTION,
} from '../graphql/subscriptions';

import { ContextLoading } from './loading';

export const DashboardContext = React.createContext<any>({});

interface DashboardContextSubscribeToMoreProps {
  dashboardSubscribeToMore: () => () => void;
  userSubscribeToMore: () => () => void;
  usersSubscribeToMore: () => () => void;
}

function DashboardContextSubscribeToMore({
  dashboardSubscribeToMore,
  userSubscribeToMore,
  usersSubscribeToMore,
}: DashboardContextSubscribeToMoreProps) {
  React.useEffect(() => {
    return dashboardSubscribeToMore();
  }, [dashboardSubscribeToMore]);

  React.useEffect(() => {
    return userSubscribeToMore();
  }, [userSubscribeToMore]);
  React.useEffect(() => {
    return usersSubscribeToMore();
  }, [usersSubscribeToMore]);
  return null;
}

export function DashboardContextProvider({ children }: any) {
  const { i18n, t } = useTranslation();

  // Interventions filter
  const [interventionsFilter, setInterventionsFilter] = useLocalStorage(
    'interventionsFilter',
    []
  );

  // loading app bar
  const [loadingAppBar, setLoadingAppBar] = React.useState(false);

  // snackbar options
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarIsDelete, setSnackbarIsDelete] = React.useState(false);
  const [snackbarIsError, setSnackbarIsError] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  // data
  const [dashboard, setDashboard] = React.useState<any>({});
  const [user, setUser] = React.useState<any>({});
  const [users, setUsers] = React.useState<any>([]);

  // breadcrumb
  const [breadcrumb, setBreadcrumb] = React.useState({
    priorityArea: '',
    intervention: '',
  });

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // queries
  // ----------------------------------------------------------------------------------------------------------

  // dashboard
  const { data: dashboardData, subscribeToMore: dashboardSubscribeToMore } =
    useQuery(CONTEXT_DASHBOARD_QUERY);

  // user
  const { data: userData, subscribeToMore: userSubscribeToMore } =
    useQuery(CONTEXT_USER_QUERY);

  // users
  const { data: usersData, subscribeToMore: usersSubscribeToMore } =
    useQuery(CONTEXT_USERS_QUERY);

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // memo
  // ----------------------------------------------------------------------------------------------------------

  // context
  const contextValue = React.useMemo(() => {
    return {
      user,
      setUser,
      users,
      setUsers,
      dashboard,
      setDashboard,

      breadcrumb,
      setBreadcrumb,

      interventionsFilter,
      setInterventionsFilter,

      loading: loadingAppBar,
      setLoading: setLoadingAppBar,
      loadingAppBar,
      setLoadingAppBar,

      snackbarOpen,
      setSnackbarOpen,
      snackbarIsDelete,
      setSnackbarIsDelete,
      snackbarIsError,
      setSnackbarIsError,
      snackbarMessage,
      setSnackbarMessage,
    };
  }, [
    user,
    setUser,
    users,
    setUsers,
    dashboard,
    setDashboard,

    breadcrumb,
    setBreadcrumb,

    interventionsFilter,
    setInterventionsFilter,

    loadingAppBar,
    setLoadingAppBar,

    snackbarOpen,
    setSnackbarOpen,
    snackbarIsDelete,
    setSnackbarIsDelete,
    snackbarIsError,
    setSnackbarIsError,
    snackbarMessage,
    setSnackbarMessage,
  ]);

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------------

  // dashboardData.dashboard
  React.useEffect(() => {
    if (dashboardData?.dashboard) {
      const newDashboard = {
        ...dashboard,
        ...dashboardData.dashboard,
        ...{
          metaStatusColors: JSON.parse(
            dashboardData?.dashboard?.metaStatusColors || '{}'
          ),
          colorPalette: JSON.parse(
            dashboardData?.dashboard?.colorPalette || '{}'
          ),
        },
      };
      if (!isEqual(dashboard, newDashboard)) {
        setDashboard(newDashboard);
      }
    }
  }, [dashboardData, dashboard]);

  // userData.user
  React.useEffect(() => {
    if (userData?.user) {
      const newUser = {
        ...user,
        ...userData.user,
        ...{
          myContentOnly: userData.user.isRegular,
        },
      };
      if (!isEqual(user, newUser)) {
        setUser(newUser);
      }
    }
  }, [user, userData]);

  // usersData.users
  React.useEffect(() => {
    if (usersData?.users && !isEqual(users, usersData.users)) {
      setUsers(usersData.users);
    }
  }, [usersData, users]);

  // ----------------------------------------------------------------------------------------------------------

  // filters
  React.useEffect(() => {
    if (dashboard) {
      const filtersObject = getFiltersObject({
        dashboard,
        t,
        filters: interventionsFilter,
        i18n,
      });

      const newDashboard = {
        ...dashboard,
        ...{
          selectedProgramme: filtersObject.project,
          selectedInterventionStatuses: filtersObject.status,
          selectedInterventionFinancier: filtersObject.financier,
          filterStakeholders: filtersObject.stakeholder,
        },
      };

      if (!isEqual(dashboard, newDashboard)) {
        setDashboard(newDashboard);
      }
    }
  }, [dashboard, i18n, interventionsFilter, t]);

  // ----------------------------------------------------------------------------------------------------------

  // if not all data, return loading
  if (!dashboard?.id || !user?.id || users?.length === 0) {
    return <ContextLoading />;
  }

  // ----------------------------------------------------------------------------------------------------------

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}

      {/* subscribe to more */}
      <DashboardContextSubscribeToMore
        dashboardSubscribeToMore={() =>
          dashboardSubscribeToMore({
            document: CONTEXT_DASHBOARD_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
          })
        }
        userSubscribeToMore={() =>
          userSubscribeToMore({
            document: CONTEXT_USER_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
          })
        }
        usersSubscribeToMore={() =>
          usersSubscribeToMore({
            document: CONTEXT_USERS_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
          })
        }
      />
    </DashboardContext.Provider>
  );
}
