import React from 'react';
import { ApolloConsumer } from '@apollo/client';

import { ErrorBoundary } from 'react-error-boundary';

import LinearProgress from '@mui/material/LinearProgress';

import { DashboardContextProvider } from '../context/dashboard-context';
import { ReportsContextProvider } from '../context/reports-context';
import { ContentDialogProvider } from '../context/content-dialog-context';

import { ThemeProviderDashboard } from './theme/dashboard';

import { ReactError } from '../react-error';
import { GraphqlLoadData } from '../graphql/load/_data';

export function AppProviders({ children }: any) {
  return (
    <React.Suspense fallback={<LinearProgress />}>
      <DashboardContextProvider>
        <ReportsContextProvider>
          <ContentDialogProvider>
            <ApolloConsumer>
              {(client) => (
                <ErrorBoundary
                  FallbackComponent={ReactError}
                  onReset={() => {
                    client.resetStore();
                    window.localStorage.clear();
                  }}
                >
                  <ThemeProviderDashboard>{children}</ThemeProviderDashboard>
                  <GraphqlLoadData />
                </ErrorBoundary>
              )}
            </ApolloConsumer>
          </ContentDialogProvider>
        </ReportsContextProvider>
      </DashboardContextProvider>
    </React.Suspense>
  );
}
