import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    marginBottom: 5,
    flexWrap: 'wrap',
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
    paddingTop: 1,
  },
  itemContent: {
    flex: 1,
  },
});

const List = ({ children }: any) => children;

export function Item({ children, view }: any) {
  return (
    <View style={styles.item} wrap={false}>
      <Text style={styles.bulletPoint}>•</Text>
      {view ? (
        <View style={styles.itemContent}>{children}</View>
      ) : (
        <Text style={styles.itemContent}>{children}</Text>
      )}
    </View>
  );
}

export default List;
