import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Paper,
} from '@mui/material';

import { logError } from '../../_lib/error';
import { useAuth } from '../../_utils/use-auth';

import { AuthScreenLayout } from '../../auth/_layout';

export function ScreenAuthPasswordReset() {
  const location = useLocation();
  const uidb64 = location.pathname.split('/')[3];
  const token = location.pathname.split('/')[4];
  const { resetPassword } = useAuth();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null as any);
  const [successful, setSuccessful] = React.useState(false);

  // Validate the information
  const valid = (data: any) => {
    const newErrors = {} as any;
    if (!data.get('password')) {
      newErrors.password = true;
    }
    if (data.get('password') !== data.get('password_confirm')) {
      newErrors.passwordMatch = true;
    }
    if (!uidb64 || !token) {
      newErrors.userOrToken = true;
    }
    if (Object.keys(newErrors).length !== 0) setError(newErrors);
    return (
      !newErrors.password && !newErrors.passwordMatch && !newErrors.userOrToken
    );
  };

  const handleForgotPassword = (data: any) => {
    if (valid(data)) {
      setLoading(true);

      setError(null);
      setSuccessful(false);

      resetPassword(data.get('password'), uidb64, token)
        .then((r: any) => {
          const res = r.data.authResetPassword;
          if (!res.success && res.message.split('Password:').length === 2) {
            const msg = res.message.split('Password:')[1];
            let passwordInvalidMsgs = [];
            try {
              passwordInvalidMsgs = JSON.parse(msg.replace(/'/g, '"'));
            } catch (e) {
              passwordInvalidMsgs = [msg];
            }
            setError({ passwordInvalid: msg, passwordInvalidMsgs });
          } else if (
            !res.success &&
            (res.message === 'UserInvalid' || res.message === 'TokenInvalid')
          ) {
            setError({ userOrToken: true });
          } else if (!res.success) {
            setError({ other: true });
          } else {
            setSuccessful(true);
          }
        })
        .catch((err: any) => {
          logError(err);
          setError({ other: true });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    handleForgotPassword(data);
  };

  return (
    <AuthScreenLayout title="Reset Password">
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1, width: '100%' }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          disabled={loading}
          error={
            !!error &&
            (!!error.auth ||
              !!error.password ||
              !!error.passwordMatch ||
              !!error.passwordInvalid)
          }
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="password_confirm"
          label="Confirm Password"
          type="password"
          id="password-confirm"
          autoComplete="current-password-confirm"
          disabled={loading}
          error={
            !!error &&
            (!!error.auth || !!error.passwordMatch || !!error.passwordInvalid)
          }
        />

        {(!!error || !!successful) && (
          <Paper
            variant="outlined"
            sx={{
              backgroundColor: error ? '#ffcdd2' : '#c8e6c9',
              borderColor: error ? '#c62828' : '2e7d32',
              py: 1,
              px: 2,
              mt: 2,
            }}
          >
            {!!error && !!error.password && (
              <Typography variant="body2">Password cannot be empty.</Typography>
            )}

            {!!error && !error.password && !!error.passwordMatch && (
              <Typography variant="body2">
                The passwords do not match.
              </Typography>
            )}

            {!!error && !!error.userOrToken && (
              <Typography variant="body2">
                Something went wrong. Please click or copy the link again. Or
                try{' '}
                <Link component={RouterLink} to="/auth/forgot">
                  send instructions again
                </Link>
                .
              </Typography>
            )}

            {!!error && !!error.passwordInvalid && (
              <>
                {error.passwordInvalidMsgs.map((e: string, i: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Typography key={i} variant="body2">
                    {e}
                  </Typography>
                ))}
              </>
            )}

            {!!error && !!error.other && (
              <Typography variant="body2">
                Something went wrong. Please try again.
              </Typography>
            )}

            {!!successful && (
              <Typography variant="body2">
                Password reset successful.{' '}
                <Link component={RouterLink} to="/">
                  Sign In
                </Link>
              </Typography>
            )}
          </Paper>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading}
        >
          Change Password
        </Button>
        <Grid container>
          <Grid item xs>
            <Link component={RouterLink} to="/" variant="body2">
              Sign In
            </Link>
          </Grid>
        </Grid>
      </Box>
    </AuthScreenLayout>
  );
}
