import React from 'react';

export const ReportsContext = React.createContext<any>('');

export function ReportsContextProvider({ children }: any) {
  const [isNew, setIsNew] = React.useState(true);

  // data
  const [reports, setReports] = React.useState([]) as any;
  const [report, setReport] = React.useState({}) as any;
  const [reportData, setReportData] = React.useState({}) as any;

  // filters
  const [filterAreas, setFilterAreas] = React.useState(false);
  const [filterStartDate, setFilterStartDate] = React.useState<Date | null>(
    null
  );
  const [filterEndDate, setFilterEndDate] = React.useState<Date | null>(null);

  const [documentBlob, setDocumentBlob] = React.useState(null) as any;

  // context
  const contextValue = React.useMemo(
    () => ({
      isNew,
      setIsNew,

      reports,
      setReports,

      report,
      setReport,

      reportData,
      setReportData,

      filterAreas,
      setFilterAreas,
      filterStartDate,
      setFilterStartDate,
      filterEndDate,
      setFilterEndDate,

      documentBlob,
      setDocumentBlob,
    }),
    [
      isNew,
      setIsNew,

      reports,
      setReports,

      report,
      setReport,

      reportData,
      setReportData,

      filterAreas,
      setFilterAreas,
      filterStartDate,
      setFilterStartDate,
      filterEndDate,
      setFilterEndDate,

      documentBlob,
      setDocumentBlob,
    ]
  );

  return (
    <ReportsContext.Provider value={contextValue}>
      {children}
    </ReportsContext.Provider>
  );
}
