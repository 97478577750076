import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { DialogTitle } from '../DialogTitle/dialog-title';
import { DashboardContext } from '../_lib/context/dashboard-context';

export function UserCreateDialog({ onSubmit, onClose, loading, open }: any) {
  const { t } = useTranslation();
  const { user } = React.useContext(DashboardContext);

  const [newUser, setNewUser] = React.useState<any>({});
  const [error, setError] = React.useState<any>({});
  const [incomplete, setIncomplete] = React.useState(false);

  React.useEffect(() => {
    setIncomplete(!newUser.name || !newUser.position || !newUser.email);
  }, [newUser]);

  const handleConfirm = () => {
    setError({
      name: !newUser.name,
      position: !newUser.position,
      email: !newUser.email,
    });
    if (!incomplete)
      onSubmit(newUser).then(() => {
        setNewUser({});
      });
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle onClose={onClose}>{t('New Member')}</DialogTitle>

      <DialogContent dividers>
        <Box>
          <Stack spacing={2}>
            {/* Name */}
            <TextField
              label={t('Name')}
              variant="outlined"
              size="small"
              value={newUser.name || ''}
              onChange={(event) =>
                setNewUser({
                  ...newUser,
                  name: event.target.value,
                })
              }
              fullWidth
              required
              error={error.name}
              disabled={loading}
            />

            {/* title / position */}
            <TextField
              label={t('Position')}
              variant="outlined"
              size="small"
              value={newUser.position || ''}
              onChange={(event) =>
                setNewUser({
                  ...newUser,
                  position: event.target.value,
                })
              }
              fullWidth
              required
              error={error.position}
              disabled={loading}
            />

            {/* Email */}
            <TextField
              label={t('Email')}
              variant="outlined"
              size="small"
              value={newUser.email || ''}
              onChange={(event) =>
                setNewUser({
                  ...newUser,
                  email: event.target.value,
                })
              }
              inputProps={{
                type: 'email',
              }}
              fullWidth
              required
              error={error.email}
              disabled={loading}
            />

            {/* Is Admin ? */}
            {!!user.isAdmin && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newUser.isAdmin || false}
                      onChange={() =>
                        setNewUser({
                          ...newUser,
                          isAdmin: !newUser.isAdmin,
                        })
                      }
                      disabled={loading}
                    />
                  }
                  label={t('Member is dashboard admin?') as string}
                  disabled={loading}
                />
              </FormGroup>
            )}
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={() => onClose()} variant="outlined">
          {t('Cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleConfirm}
          variant="contained"
          startIcon={<DoneIcon />}
          disableElevation
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
