import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import { DialogModal } from '../components/dialog-modal';
import { DashboardContext } from '../_lib/context/dashboard-context';
import { LAUNCH_DASHBOARD } from '../_lib/graphql/mutations';
import { logError } from '../_lib/error';

export function LaunchDashboard({ isDark, fullWidth, inStep, disabled }: any) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarIsError, setSnackbarOpen } =
    React.useContext(DashboardContext);

  const [loading, setLoading] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const [launchDashboardMutation] = useMutation(LAUNCH_DASHBOARD);

  const launchDashboard = () => {
    setLoading(true);
    launchDashboardMutation()
      .then(() => {
        window.location.href = '/';
      })
      .catch((e) => {
        logError(e);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  // -------------------------------------------------------------------------------------------------

  return (
    <>
      <DialogModal
        loading={loading}
        onSubmit={launchDashboard}
        onDismiss={() => setConfirmDialogOpen(false)}
        title={t('Ready to launch?')}
        type=""
        show={confirmDialogOpen}
      />

      <Button
        id="launch-dashboard"
        onClick={() => setConfirmDialogOpen(true)}
        variant={inStep ? 'text' : 'contained'}
        color={!inStep ? (isDark ? 'secondary' : 'primary') : undefined}
        startIcon={!inStep && <PlayCircleOutlineIcon />}
        fullWidth={fullWidth}
        disableElevation
        size={inStep ? 'small' : 'medium'}
        disabled={disabled}
      >
        {inStep
          ? t('Launch')
          : `${t('Launch')} ${
              dashboard.mode === 'TRACKER'
                ? t('activity tracker')
                : t('dashboard')
            }`}
      </Button>
    </>
  );
}
