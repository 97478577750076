import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { GET_REPORT, GET_REPORT_DATA } from '../../_lib/graphql/queries';
import { UPDATE_REPORT } from '../../_lib/graphql/mutations';

import { DashboardContext } from '../../_lib/context/dashboard-context';
import { ReportsContext } from '../../_lib/context/reports-context';

import { ReportsTitle } from '../title';
import { ReportsEditText } from './text';
import { ReportsEditActions } from './actions';
import { ReportsEditFilters } from './filters';
import { ReportsReportPdf } from '../pdf';
import { ReportsEditActionSave } from './actions/save';
import { ReportsEditData } from './data';
import { logError } from '../../_lib/error';
import { deepCopy } from '../../_utils/general-utils';

export function ReportsEdit({ isNew }: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { reportId } = useParams() as any;

  const { setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);
  const {
    report,
    setReport,
    documentBlob,
    setReportData,
    filterAreas,
    setIsNew,
    filterStartDate,
    setFilterStartDate,
    filterEndDate,
    setFilterEndDate,
  } = React.useContext(ReportsContext);

  const formEl = React.useRef(null);
  const [loading, setLoading] = React.useState(true);

  // get report
  const {
    data,
    loading: loadingReportQuery,
    error,
  } = useQuery(GET_REPORT, {
    variables: {
      id: reportId,
    },
  });

  // get report data
  const {
    data: dataReportData,
    loading: loadingReportData,
    error: errorReportData,
  } = useQuery(GET_REPORT_DATA, {
    variables: {
      filterStartDate,
      filterEndDate,
    },
    // TODO: add subscribe to more for this
    fetchPolicy: 'no-cache',
  });

  // --------------------------------------------------------------------------------------------------------
  // actions / mutations
  // --------------------------------------------------------------------------------------------------------

  // save actions

  const [updateReportMutation] = useMutation(UPDATE_REPORT);

  const [formErrors, setFormErrors] = React.useState({}) as any;

  const fetchFormData = () => {
    const form = formEl.current as any;
    const formDatas = new FormData(form);

    return {
      title: formDatas.get('title') as string,
      description: formDatas.get('description') as string,
      filterMembers: !formDatas.get('filter-members'),
    };
  };

  const handleSubmit = (status: string) => {
    setLoading(true);
    setFormErrors({});

    const updatedFormData = fetchFormData();
    if (!updatedFormData.title || !updatedFormData.title.trim()) {
      setFormErrors({
        title: t('Title cannot be empty'),
      });
      setLoading(false);
      return;
    }

    updateReportMutation({
      variables: {
        id: reportId || 'new',
        title: updatedFormData.title,
        description: updatedFormData.description,
        filterProgramme: report.filterProgramme?.id,
        filterAreas: filterAreas.length > 0 ? filterAreas : ['delete_all'],
        filterMembers: updatedFormData.filterMembers,
        filterStartDate,
        filterEndDate,
        status,
        document:
          status === 'draft'
            ? null
            : new File(
                [documentBlob],
                `${reportId || 'new'}-${
                  updatedFormData.title
                }-${new Date().toISOString()}.pdf`
              ),
      },
    })
      .then((r: any) => {
        history.push(`${t('/reports')}/${r.data.updateReport.report.id}`);
      })
      .catch((err: any) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------

  // data
  React.useEffect(() => {
    if (data && !isNew) {
      setReport(data.getReport);
      setFilterStartDate(data.getReport?.filterStartDate);
      setFilterEndDate(data.getReport?.filterEndDate);
    }
    setIsNew(isNew);
  }, [data, setReport, isNew, setIsNew, setFilterStartDate, setFilterEndDate]);

  React.useEffect(() => {
    if (dataReportData?.getReportData) {
      const newReportData = deepCopy(dataReportData?.getReportData);
      newReportData.priorityAreas = newReportData.priorityAreas.map(
        (area: any) => {
          return {
            ...area,
            interventions: area.filteredInterventions,
          };
        }
      );
      setReportData(newReportData);
    }
  }, [dataReportData, setReportData]);

  // loading
  React.useEffect(() => {
    setLoading(loadingReportQuery);
  }, [loadingReportQuery]);

  // error
  React.useEffect(() => {
    if (error) {
      logError(error);
    }
    if (errorReportData) {
      logError(errorReportData);
    }
    if (error || errorReportData) {
      setSnackbarIsError(true);
      setSnackbarOpen(true);
    }
  }, [error, errorReportData, setSnackbarIsError, setSnackbarOpen]);

  // --------------------------------------------------------------------------------------------------------

  return (
    <ReportsEditData>
      <form ref={formEl} noValidate autoComplete="off">
        <ReportsTitle
          primary={isNew ? t('New Report') : t('Edit Report')}
          actions={
            <Stack spacing={1} direction="row">
              <Button onClick={() => history.push(`${t('/reports')}`)}>
                {t('Close')}
              </Button>

              <ReportsEditActionSave
                isDraft
                loading={loading}
                handleSubmit={() => handleSubmit('draft')}
              />
              <ReportsEditActionSave
                isPublish
                loading={loading}
                handleSubmit={() => handleSubmit('publish')}
              />
            </Stack>
          }
        />

        <Container sx={{ py: 2 }}>
          <Paper sx={{ my: 3, px: 3, py: 4 }}>
            <Grid container spacing={3}>
              <Grid item sm={8} xs={12}>
                {loading ? (
                  <Skeleton variant="rectangular" height={800} />
                ) : (
                  <>
                    <ReportsEditText formErrors={formErrors} />

                    <Box sx={{ my: 3 }}>
                      <Typography
                        variant="body1"
                        sx={{ mb: 1, fontWeight: 500 }}
                      >
                        {`${t('Preview')}:`}
                      </Typography>
                      {loadingReportData && (
                        <Skeleton variant="rectangular" height={800} />
                      )}
                      {!loadingReportData && <ReportsReportPdf />}
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item sm={4} xs={12}>
                <ReportsEditActions />
                <Divider sx={{ mt: 3, mb: 2 }} />
                <ReportsEditFilters />

                {/* <Divider sx={{ mt: 3, mb: 2 }} /> */}
                {/* <ReportsEditUpdates /> */}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </form>
    </ReportsEditData>
  );
}
