import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

export function ReportsEditActionSave({
  isDraft,
  isPublish,
  handleSubmit,
  loading,
}: any) {
  const { t } = useTranslation();

  return (
    <LoadingButton
      onClick={handleSubmit}
      variant={isPublish && 'contained'}
      loading={loading}
      disableElevation
    >
      {isDraft && t('Save draft')}
      {isPublish && t('Publish')}
    </LoadingButton>
  );
}
