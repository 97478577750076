import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export function ReportsTitle({ primary, secondary, actions }: any) {
  return (
    <Container>
      <Box sx={{ display: 'flex', alignItems: 'center', pt: 2, pb: 3 }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          {primary}
        </Typography>
        <Box sx={{ display: 'flex' }}>{actions}</Box>
      </Box>
      <Divider />
    </Container>
  );
}
