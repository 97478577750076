export const contentPermissions = (contentIsAllowed: any) => {
  // Check where content is
  // If content is somewhere accessible, reload page and return false
  if (contentIsAllowed && contentIsAllowed.reload) {
    // reload page for change of dashboard
    window.localStorage.clear();
    window.location.reload();
    return false;
  }

  if (contentIsAllowed && !contentIsAllowed.reload) {
    return true;
  }

  // Do not check route permissions until we confirm content permissions
  return false;
};
