import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Link from '@mui/material/Link';
import { DashboardContext } from '../_lib/context/dashboard-context';
import { getStringDate } from '../_utils/general-utils';
import { useLocalStorage } from '../_utils/use-local-storage';

function ParentDashboardAlertDetail({ pD }: any) {
  const { t } = useTranslation();

  const { dashboard } = React.useContext(DashboardContext);

  const [open, setOpen] = useLocalStorage(
    `parent-dashboard-alert-${pD.id}`,
    true
  );

  return (
    <Collapse in={open}>
      {' '}
      <Alert
        severity="warning"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {t("This dashboard's data has been shared with another dashboard by ")}
        <Link href={`mailto:${pD.createdBy.email}`} target="_blank">
          {pD.createdBy.name}
        </Link>
        {t(' since ')} {getStringDate(pD.createdAt, dashboard.language)}
      </Alert>
    </Collapse>
  );
}

function ParentDashboardAlertList() {
  const { dashboard } = React.useContext(DashboardContext);

  return (
    <Stack spacing={2} sx={{ my: 3 }}>
      {dashboard.parentDashboards.map((pD: any) => (
        <ParentDashboardAlertDetail key={pD.id} pD={pD} />
      ))}
    </Stack>
  );
}

export function ParentDashboardAlert({ includeContainer }: any) {
  const { dashboard, user } = React.useContext(DashboardContext);
  if (!dashboard.parentDashboards?.length || !user.isAdmin) return null;

  return includeContainer ? (
    <Container>
      <ParentDashboardAlertList />
    </Container>
  ) : (
    <ParentDashboardAlertList />
  );
}
