import React from 'react';

export const ContentDialogContext = React.createContext<any>('');

export function ContentDialogProvider({ children }: any) {
  // data
  const [contentId, setContentId] = React.useState<any>(null);
  const [content, setContent] = React.useState<any>(null);

  // context
  const contextValue = React.useMemo(
    () => ({
      contentId,
      setContentId,
      content,
      setContent,
    }),
    [contentId, content]
  );

  return (
    <ContentDialogContext.Provider value={contextValue}>
      {children}
    </ContentDialogContext.Provider>
  );
}
