import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Menu from '@mui/material/Menu';

import MenuItem from '@mui/material/MenuItem';
import { getStringDateTime } from '../../_utils/general-utils';
import { DashboardContext } from '../../_lib/context/dashboard-context';

import BgImage from '../../../assets/img/bg/Modern-Icon-Technology-Pattern.jpg';
import { ReportsEditActionsDelete } from '../edit/actions/delete';

export function ReportsHomeDetail({ report }: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const { dashboard } = useContext(DashboardContext);

  // more button
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMore = Boolean(anchorEl);
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item xs={12} sm={4} lg={3}>
      <Card>
        <CardHeader
          action={
            <>
              <IconButton
                id="more-button"
                aria-label="more"
                aria-controls="more-menu"
                aria-haspopup="true"
                aria-expanded={openMore ? 'true' : undefined}
                onClick={handleMoreClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="more-menu"
                anchorEl={anchorEl}
                open={openMore}
                onClose={handleMoreClose}
                MenuListProps={{
                  'aria-labelledby': 'more-button',
                }}
              >
                {report.isPublished && (
                  <MenuItem
                    onClick={() =>
                      history.push(`${t('/reports')}/${report.id}`)
                    }
                  >
                    {t('Edit')}
                  </MenuItem>
                )}
                <ReportsEditActionsDelete
                  reportId={report.id}
                  isMoreMenuItem
                  handleMoreMenuClose={handleMoreClose}
                />
              </Menu>
            </>
          }
          title={report.title}
          subheader={report.isPublished ? t('Published') : t('Draft')}
        />
        <CardActionArea
          onClick={() => history.push(`${t('/reports')}/${report.id}`)}
        >
          <CardMedia
            component="img"
            height="100"
            image={report.image_url || BgImage}
            alt="Report Image"
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" paragraph>
              {report.author.name}
              {' . '}
              {getStringDateTime(report.updatedAt, dashboard.language)}
            </Typography>
            {!!report.description && (
              <Typography variant="body1" color="textSecondary">
                {report.description}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="small"
            onClick={() => history.push(`${t('/reports')}/${report.id}`)}
          >
            {t('Edit')}
          </Button>
          {!!report.document && !!report.document.url && (
            <Button size="small" href={report.document.url}>
              {t('Download')}
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
}
