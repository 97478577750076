import React from 'react';
import { useQuery } from '@apollo/client/react';

import {
  ACTIVITY_TRACKER,
  GET_ACCEPTED_UPLOAD_FILE_TYPES_QUERY,
  CONTENT_GROUPS_QUERY,
  GET_REPORTS,
  PRIORITY_AREAS_PLANNING_QUERY,
  REGULAR_USER_CONTENT,
  TRACKER_LANDING,
} from '../../queries';

import {
  ACTIVITY_TRACKER_SUBSCRIPTION,
  CONTENT_GROUPS_SUBSCRIPTION,
  PRIORITY_AREAS_PLANNING_SUBSCRIPTION,
  REPORTS_SUBSCRIPTION,
  TRACKER_LANDING_SUBSCRIPTION,
} from '../../subscriptions';

import { GraphqlLoadDataQueriesPriorityArea } from './priority-area';
import { GraphqlLoadDataQueriesContentGroup } from './content-group';

// export const GET_REPORT = getReport;
// export const GET_REPORT_DATA = getReportData;
// export const GET_REPORTS_TEMPLATE_META = getReportsTemplateMeta;

export function GraphqlLoadDataQueries() {
  const {
    data: dataPriorityAreas,
    subscribeToMore: subscribeToMorePriorityAreas,
  } = useQuery(PRIORITY_AREAS_PLANNING_QUERY);

  const {
    data: dataPriorityAreaPlanning,
    subscribeToMore: subscribeToMorePriorityAreaPlanning,
  } = useQuery(PRIORITY_AREAS_PLANNING_QUERY);

  const {
    data: dataRegularContent,
    subscribeToMore: subscribeToMoreRegularContent,
  } = useQuery(REGULAR_USER_CONTENT);

  const {
    data: dataTrackerLanding,
    subscribeToMore: subscribeToMoreTrackerLanding,
  } = useQuery(TRACKER_LANDING);
  const {
    data: dataActivityTracker,
    subscribeToMore: subscribeToMoreActivityTracker,
  } = useQuery(ACTIVITY_TRACKER);
  const {
    data: dataGetContentGroups,
    subscribeToMore: subscribeToMoreGetContentGroups,
  } = useQuery(CONTENT_GROUPS_QUERY);

  const { data: dataGetReports, subscribeToMore: subscribeToMoreGetReports } =
    useQuery(GET_REPORTS);

  const {
    data: dataAcceptedUploadFileTypes,
    subscribeToMore: subscribeToMoreAcceptedUploadFileTypes,
  } = useQuery(GET_ACCEPTED_UPLOAD_FILE_TYPES_QUERY);

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    subscribeToMorePriorityAreaPlanning({
      document: PRIORITY_AREAS_PLANNING_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });

    // TODO: regular user content

    subscribeToMoreTrackerLanding({
      document: TRACKER_LANDING_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });

    subscribeToMoreActivityTracker({
      document: ACTIVITY_TRACKER_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });

    subscribeToMoreGetContentGroups({
      document: CONTENT_GROUPS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });

    subscribeToMoreGetReports({
      document: REPORTS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });
  }, []);

  // --------------------------------------------------------------------------------------------------------

  return (
    <>
      {dataPriorityAreas?.priorityAreas?.map((priorityArea: any) => (
        <GraphqlLoadDataQueriesPriorityArea
          key={priorityArea.id}
          priorityAreaId={priorityArea.id}
        />
      ))}

      {dataGetContentGroups?.contentGroups?.map((contentGroup: any) => (
        <GraphqlLoadDataQueriesContentGroup
          key={contentGroup.id}
          contentGroupId={contentGroup.id}
        />
      ))}
    </>
  );
}
