import { getAnalytics, logEvent } from 'firebase/analytics';
import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  AnalyticsProvider,
  FirebaseAppProvider,
  useAnalytics,
  useFirebaseApp,
  useInitPerformance,
} from 'reactfire';

const firebaseConfig = {
  apiKey: 'AIzaSyDulA0fmsKttF5UmthdyFncqW0kI5lT3NY',
  authDomain: 'digital-dashboard-ee795.firebaseapp.com',
  projectId: 'digital-dashboard-ee795',
  storageBucket: 'digital-dashboard-ee795.appspot.com',
  messagingSenderId: '430969023312',
  appId: '1:430969023312:web:47d48d5e5b754c9a4ada55',
  measurementId: 'G-XPT6907NDC',
};

const firebaseConfigDemo = {
  apiKey: 'AIzaSyDulA0fmsKttF5UmthdyFncqW0kI5lT3NY',
  authDomain: 'digital-dashboard-ee795.firebaseapp.com',
  projectId: 'digital-dashboard-ee795',
  storageBucket: 'digital-dashboard-ee795.appspot.com',
  messagingSenderId: '430969023312',
  appId: '1:430969023312:web:145017ef8d22efe54ada55',
  measurementId: 'G-VFC4DT98TF',
};

function FirebaseServices({ children }: any) {
  const app = useFirebaseApp();
  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>{children}</AnalyticsProvider>
  );
}

export function FirebaseProvider({ children }: any) {
  return (
    <FirebaseAppProvider
      firebaseConfig={
        process.env.REACT_APP_IS_DEMO_SITE === 'true'
          ? firebaseConfigDemo
          : firebaseConfig
      }
    >
      <FirebaseServices>{children}</FirebaseServices>
    </FirebaseAppProvider>
  );
}

export function FirebaseEffects() {
  const location = useLocation();
  const analytics = useAnalytics();

  // log analytics
  React.useEffect(() => {
    logEvent(analytics, 'page_view', { page_location: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // init performance
  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import('firebase/performance');
    return getPerformance(firebaseApp);
  });
  return null;
}
