import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { BreadcrumbLayout } from './_layout';
import { DashboardContext } from '../_lib/context/dashboard-context';

export function MyContentBreadcrumb() {
  const { t } = useTranslation();
  const { user } = React.useContext(DashboardContext);

  return (
    <BreadcrumbLayout id="my-content-breadcrumb">
      {!user.isRegular && (
        <Link
          id="home-crumb"
          underline="hover"
          component={RouterLink}
          color="inherit"
          to="/"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeRoundedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {t('Home')}
        </Link>
      )}

      <Typography color="text.primary" id="name-crumb">
        {user.name} - {t('Tracker')}
      </Typography>
    </BreadcrumbLayout>
  );
}
