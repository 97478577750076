import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import { Container, LinearProgress } from '@mui/material';

import { BaseLayout } from '@institutegc/digital-dashboard-ui/src/_layout/base';

import { DashboardContext } from '@institutegc/digital-dashboard-ui/src/_lib/context/dashboard-context';
import { AppProviders } from '@institutegc/digital-dashboard-ui/src/_lib/providers/app';
import { LocalizationProvider } from '@institutegc/digital-dashboard-ui/src/_lib/providers/localization';

import { MyContentBreadcrumb } from '@institutegc/digital-dashboard-ui/src/breadcrumbs/my-content-breadcrumb';
import { ActivityTrackerBreadcrumb } from '@institutegc/digital-dashboard-ui/src/breadcrumbs/activity-tracker-breadcrumb';
import { DashboardBreadcrumb } from '@institutegc/digital-dashboard-ui/src/breadcrumbs/dashboard-breadcrumb';
import { ReportsBreadcrumb } from '@institutegc/digital-dashboard-ui/src/breadcrumbs/reports-breadcrumb';

import { ActivityTrackerRouter } from './routers/activity-tracker-router';
import { FullDashboardRouter } from './routers/full-dashboard-router';

import { RoutePermissions } from './routers/permissions';

export function HomePage() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const { dashboard, user } = React.useContext(DashboardContext);

  const regularUser =
    !user?.isAdmin && !user?.priorityArea && !user?.intervention;

  const isReportScreens = location.pathname.startsWith(t('/reports'));
  const isPlanningScreens = location.pathname.startsWith(t('/planning'));

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------------

  // set up sentry
  React.useEffect(() => {
    if (user) {
      Sentry.setUser({
        username: user.email,
      });
    }
    if (dashboard) {
      Sentry.setContext('dashboard', {
        title: 'Digital Dashboard',
        name: dashboard?.name,
        country: dashboard?.country,
        dashboard_id: dashboard?.id,
      });
    }
  }, [user, dashboard]);

  // Set language
  React.useEffect(() => {
    i18n.changeLanguage(dashboard?.language);
  }, [dashboard, i18n]);

  // ----------------------------------------------------------------------------------------------------------

  if (!dashboard) return <LinearProgress />;

  return (
    <>
      {/* head */}
      <Helmet>
        <title>{dashboard?.name}</title>
        <link rel="icon" sizes="32x32" href={dashboard?.logo} />
        <link rel="icon" sizes="16x16" href={dashboard?.logo} />
        <link rel="icon" href={dashboard?.logo} />
      </Helmet>

      <LocalizationProvider>
        <BaseLayout>
          {/* breadcrumb */}
          <Container>
            {regularUser ||
              (location.pathname.startsWith(t('/my-content')) && (
                <MyContentBreadcrumb />
              ))}
          </Container>

          {/* Dashboard content */}
          {dashboard?.mode === 'TRACKER' ? (
            <>
              <Container>
                <ActivityTrackerBreadcrumb />
              </Container>

              <ActivityTrackerRouter />
            </>
          ) : (
            <FullDashboardRouter />
          )}
        </BaseLayout>
      </LocalizationProvider>
    </>
  );
}

export function AppLoader() {
  return (
    <React.Suspense fallback={<LinearProgress />}>
      <AppProviders>
        <RoutePermissions>
          <HomePage />
        </RoutePermissions>
      </AppProviders>
    </React.Suspense>
  );
}
