import { View, Text } from '@react-pdf/renderer';
import { compareAsc, sub, add } from 'date-fns';

import grey from '@mui/material/colors/grey';

import { getStringDate } from '../../../../../../_utils/general-utils';
import Boxed from '../../ui/boxed';

export function ReportsPdfTemplatesPreziCountrySectionsActivitiesMilestones({
  t,
  dashboard,
  area,
}: any) {
  const milestones = [] as any;

  const extractMilestones = (milestonesSummary: any) => {
    const extractedMilestones = [] as any;
    if (milestonesSummary) {
      Object.keys(milestonesSummary)
        .filter(
          (key: any) =>
            key !== '__typename' && key !== 'percDone' && key !== 'id'
        )
        .forEach((key: any) => {
          if (
            milestonesSummary[key] &&
            milestonesSummary[key].content &&
            milestonesSummary[key].content.length > 0
          ) {
            extractedMilestones.push(...milestonesSummary[key].content);
          }
        });
    }
    return extractedMilestones;
  };

  // eslint-disable-next-line no-underscore-dangle
  if (area.__typename === 'PriorityAreaType') {
    milestones.push(
      ...(area.interventions?.reduce((acc: any, intv: any) => {
        if (intv.summary?.milestonesSummary) {
          Object.keys(area.summary.milestonesSummary);
          return [
            ...acc,
            ...extractMilestones(intv.summary.milestonesSummary).map(
              (c: any) => ({
                ...c,
                intervention: {
                  id: intv.id,
                  name: intv.name,
                  reference: intv.reference,
                },
              })
            ),
          ];
        }
        return acc;
      }, []) || [])
    );
  } else if (area.summary?.milestonesSummary?.content?.length > 0) {
    milestones.push(...extractMilestones(area.summary.milestonesSummary));
  }

  if (milestones.length === 0) return null;

  const milestonesSorted = milestones.sort((a: any, b: any) =>
    compareAsc(new Date(a.deadline), new Date(b.deadline))
  );

  const milestonesSections = milestonesSorted
    .slice(0, 4)
    .map((m: any, i: number) => {
      // only one milestone
      if (i === 0 && milestonesSorted.length === 1) {
        return {
          ...m,
          sectionStart: sub(new Date(m.deadline), { months: 3 }),
          sectionPoint: new Date(m.deadline),
          sectionEnd: add(new Date(m.deadline), { months: 3 }),
        };
      }

      // first milestone
      if (i === 0) {
        return {
          ...m,
          sectionStart: sub(new Date(m.deadline), { months: 3 }).getTime(),
          sectionPoint: new Date(m.deadline).getTime(),
          sectionEnd: new Date(
            (new Date(m.deadline).getTime() +
              new Date(milestonesSorted[i + 1].deadline).getTime()) /
              2
          ).getTime(),
        };
      }

      // last milestone
      if (i === milestonesSorted.length - 1) {
        return {
          ...m,
          sectionStart: new Date(
            (new Date(m.deadline).getTime() +
              new Date(milestonesSorted[i - 1].deadline).getTime()) /
              2
          ).getTime(),
          sectionPoint: new Date(m.deadline).getTime(),
          sectionEnd: add(new Date(m.deadline), { months: 3 }).getTime(),
        };
      }

      // middle milestone
      return {
        ...m,
        sectionStart: new Date(
          (new Date(m.deadline).getTime() +
            new Date(milestonesSorted[i - 1].deadline).getTime()) /
            2
        ).getTime(),
        sectionPoint: new Date(m.deadline).getTime(),
        sectionEnd: new Date(
          (new Date(m.deadline).getTime() +
            new Date(milestonesSorted[i + 1].deadline).getTime()) /
            2
        ).getTime(),
      };
    });

  const sectionStartTotal = milestonesSections[0].sectionStart;
  const sectionEndTotal =
    milestonesSections[
      milestonesSections.length > 1 ? milestonesSections.length - 1 : 0
    ].sectionEnd;

  const sectionTotal = sectionEndTotal - sectionStartTotal;

  return (
    <View>
      <Boxed
        titleFlex={1}
        contentFlex={4}
        spacing={2}
        title={t('Milestones')}
        content={
          <View
            style={{
              width: '100%',
            }}
          >
            {/* Timeline */}
            <View>
              {/* Dates */}
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                {milestonesSections.map((m: any) => {
                  const { sectionStart, sectionEnd } = m;
                  const sectionWidth =
                    (sectionEnd - sectionStart) / sectionTotal;

                  return (
                    <View
                      key={m.id}
                      style={{
                        width: `${sectionWidth * 100}%`,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <View style={{ textAlign: 'center', padding: '3px' }}>
                        <Text
                          style={{
                            fontSize: 8,
                          }}
                        >
                          {getStringDate(m.deadline, dashboard.language, true)}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: '1px',
                          height: '5px',
                          backgroundColor: '#000',
                        }}
                      />
                    </View>
                  );
                })}
              </View>

              {/* Line */}
              <View
                style={{
                  width: '100%',
                  height: '3px',
                  backgroundColor: '#3f51b5',
                }}
              />
              {/* Title */}
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {milestonesSections.map((m: any) => {
                  const { sectionStart, sectionEnd } = m;
                  const sectionWidth =
                    (sectionEnd - sectionStart) / sectionTotal;

                  return (
                    <View
                      key={m.id}
                      style={{
                        width: `${sectionWidth * 100}%`,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <View
                        style={{
                          width: '1px',
                          height: '5px',
                          backgroundColor: '#000',
                        }}
                      />
                      <View style={{ textAlign: 'center', padding: '3px' }}>
                        <Text style={{ fontSize: 10 }}>{m.title}</Text>
                        {m.intervention && (
                          <Text style={{ fontSize: 8, color: grey[700] }}>
                            {m.intervention.reference &&
                              `${m.intervention.reference} - `}
                            {m.intervention.name}
                          </Text>
                        )}
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        }
      />

      <View style={{ height: '20px' }} />
    </View>
  );
}

export default ReportsPdfTemplatesPreziCountrySectionsActivitiesMilestones;
