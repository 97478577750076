import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';

import MuiBackdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '@mui/icons-material/Search';

import { NavbarAppBarSearchResultsList } from './results/list';

const Backdrop = styled(MuiBackdrop)({
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  backdropFilter: 'blur(1px)',
});

export function NavbarAppBarSearchDialog({ open, setOpen }: any) {
  const { t } = useTranslation();

  const [search, setSearch] = React.useState('');

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      components={{ Backdrop }}
      maxWidth="xs"
      fullWidth
      scroll="paper"
    >
      <DialogTitle sx={{ pt: 3 }}>
        <TextField
          fullWidth
          placeholder={`${t('Search')}...`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            autoFocus: true,
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <NavbarAppBarSearchResultsList
          search={search}
          setSearch={setSearch}
          setDialogOpen={setOpen}
        />
      </DialogContent>
    </Dialog>
  );
}
