import { View, Text } from '@react-pdf/renderer';

import green from '@mui/material/colors/green';

export function ReportsPdfTemplatesPreziCountryUiSectionTitle({
  primary,
}: any) {
  return (
    <View
      style={{
        width: '100%',
        backgroundColor: green[800],
        color: 'white',
        textAlign: 'center',
        padding: '5px',
        margin: '15px 0',
      }}
      wrap={false}
    >
      <Text>{primary}</Text>
    </View>
  );
}
