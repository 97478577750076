import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { compareAsc } from 'date-fns';

import grey from '@mui/material/colors/grey';

import {
  deepCopy,
  getStringDate,
} from '../../../../../../_utils/general-utils';

import { Item } from '../../../../ui/list';
import Boxed from '../../ui/boxed';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  box: {
    width: '32%',
  },
});

function ActivitiesList({ t, title, dashboard, activities }: any) {
  const { metaStatusColors } = dashboard;

  const sortedActivities = deepCopy(activities);
  Object.keys(activities).forEach((key: any) => {
    if (!activities[key]) return;
    let sortedActivitesContent = activities[key].content;
    if (
      sortedActivitesContent &&
      sortedActivitesContent.length > 0 &&
      sortedActivitesContent[0].createdAt
    ) {
      sortedActivitesContent = sortedActivitesContent?.sort((a: any, b: any) =>
        compareAsc(new Date(a.createdAt), new Date(b.createdAt))
      );
    }
    if (
      sortedActivitesContent &&
      sortedActivitesContent.length > 0 &&
      sortedActivitesContent[0].deadline
    ) {
      sortedActivitesContent = sortedActivitesContent?.sort((a: any, b: any) =>
        compareAsc(new Date(a.deadline), new Date(b.deadline))
      );
    }
    sortedActivities[key].content = sortedActivitesContent;
  });

  return (
    <Boxed
      flexDirection="column"
      spacing={2}
      title={title}
      titleFlexGrow={0}
      contentFlexGrow={1}
      content={
        <View
          style={{
            width: '100%',
            textAlign: 'left',
          }}
        >
          {Object.keys(sortedActivities).map((key: any) => {
            if (!sortedActivities[key]) return null;

            return (
              <View key={key} style={{ marginBottom: '10px' }}>
                <Text
                  style={{
                    marginBottom: '5px',
                    fontWeight: 'bold',
                    color: metaStatusColors[key].bgcolor,
                  }}
                >
                  {t(metaStatusColors[key].text)}
                </Text>

                {/* No activities */}
                {(!sortedActivities[key] ||
                  (!!sortedActivities[key].content &&
                    sortedActivities[key].content.length === 0)) && (
                  <Text>{t('-')}</Text>
                )}

                {/* Activities */}
                {!!sortedActivities[key] &&
                  !!sortedActivities[key].content &&
                  sortedActivities[key].content
                    .slice(0, 3)
                    .map((activity: any) => {
                      return (
                        <Item key={activity.id} view>
                          <Text>
                            {activity.title?.trim() || '-'}
                            {dashboard.enableActivityPercentage &&
                              key !== 'issue' &&
                              ` (${activity.percDone}%)`}
                          </Text>
                          {activity.deadline && (
                            <Text style={{ fontSize: '9px' }}>
                              {t('Deadline')}:{' '}
                              {getStringDate(
                                activity.deadline,
                                dashboard.language
                              )}
                            </Text>
                          )}
                          {!!activity.priorityArea && (
                            <Text
                              style={{
                                color: grey[500],
                                fontSize: '9px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxLines: 1,
                              }}
                            >
                              {activity.priorityArea.reference &&
                                `${activity.priorityArea.reference} - `}
                              {activity.priorityArea.name?.trim() || '--'}
                            </Text>
                          )}
                          {!!activity.intervention && (
                            <Text
                              style={{
                                color: grey[500],
                                fontSize: '8px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxLines: 1,
                                paddingLeft: '7px',
                              }}
                            >
                              {activity.intervention.reference &&
                                `${activity.intervention.reference} - `}
                              {activity.intervention.name?.trim() || '--'}
                            </Text>
                          )}
                        </Item>
                      );
                    })}
              </View>
            );
          })}
        </View>
      }
    />
  );
}

export function ReportsPdfTemplatesPreziCountrySectionsActivitiesList({
  t,
  dashboard,
  areas,
  debug,
}: any) {
  const combinedContentSummary = areas.reduce(
    (acc: any, area: any) => {
      const contentSummary = area.summary?.contentSummary;

      if (!contentSummary) return acc;

      const newContentSummary = {} as any;

      Object.keys(contentSummary)
        .filter(
          (key: string) =>
            key !== '__typename' && key !== 'percDone' && key !== 'id'
        )
        .forEach((key: any) => {
          newContentSummary[key] = {
            total: (contentSummary[key]?.total || 0) + (acc[key]?.total || 0),
            content: (contentSummary[key]?.content || [])
              .map((activity: any) => {
                const newActivity = { ...activity, type: key };

                if (area.interventions) {
                  return {
                    ...newActivity,
                    priorityArea: {
                      name: area.name,
                      id: area.id,
                      reference: area.reference,
                    },
                  };
                }

                if (!activity.intervention) {
                  return {
                    ...newActivity,
                    intervention: {
                      name: area.name,
                      id: area.id,
                      reference: area.reference,
                    },
                  };
                }

                return {
                  ...newActivity,
                };
              })
              .concat(acc[key]?.content || []),
          };
        });
      return newContentSummary;
    },
    {
      total: 0,
      content: [],
    }
  );

  return (
    <View style={styles.container}>
      <View style={styles.box}>
        <ActivitiesList
          t={t}
          dashboard={dashboard}
          title={t('Most Recent Activities')}
          activities={{
            ongoing: combinedContentSummary.ongoing,
            completed: combinedContentSummary.completed,
          }}
        />
      </View>
      <View style={styles.box}>
        <ActivitiesList
          t={t}
          dashboard={dashboard}
          title={t('Main Risks')}
          activities={{
            overdue: combinedContentSummary.overdue,
            issue: combinedContentSummary.issues,
          }}
        />
      </View>
      <View style={styles.box}>
        <ActivitiesList
          t={t}
          dashboard={dashboard}
          title={t('Planned Activities')}
          activities={{
            pending: combinedContentSummary.pending,
            unstarted: combinedContentSummary.unstarted,
          }}
        />
      </View>
    </View>
  );
}

export default ReportsPdfTemplatesPreziCountrySectionsActivitiesList;
