import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Skeleton from '@mui/material/Skeleton';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { ReportsTitle } from '../title';
import { ReportsIcon } from '../../components/icons/reports-icon';
import { GET_REPORTS } from '../../_lib/graphql/queries';
import { ReportsHomeList } from './list';
import { ReportsContext } from '../../_lib/context/reports-context';

import { ReactError } from '../../_lib/react-error';

export function ReportsHome() {
  const { t } = useTranslation();
  const history = useHistory();

  const { setReport, reports, setReports, setIsNew } =
    React.useContext(ReportsContext);

  const { loading, data, error } = useQuery(GET_REPORTS);

  // ----------------------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------------------------------

  // set reports
  React.useEffect(() => {
    if (data) {
      setReports(data.getReports);
    }
  }, [data, setReports]);

  // reset report and set isNew
  React.useEffect(() => {
    setReport({});
    setIsNew(true);
  }, [setReport, setIsNew]);

  // ----------------------------------------------------------------------------------------------------------------------------

  if (error) return <ReactError error={error} />;

  // ----------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <ReportsTitle
        primary={
          <>
            {t('Reports')}
            <Typography variant="caption" sx={{ ml: 1, display: 'inline' }}>
              {t('BETA')}
            </Typography>
          </>
        }
        actions={
          <Button
            variant="contained"
            onClick={() => history.push(`${t('/reports')}${t('/new')}`)}
            disableElevation
          >
            {t('Create')}
          </Button>
        }
      />
      <Container sx={{ py: 3 }}>
        {loading ? (
          <Skeleton variant="rectangular" height={300} />
        ) : (
          <>
            {(!reports || !reports.length) && (
              <Box sx={{ textAlign: 'center', color: 'text.secondary', my: 3 }}>
                <ReportsIcon sx={{ fontSize: 50, mb: 2, color: 'action' }} />
                <Typography variant="body1">
                  {t('Reports will be shown here.')}
                </Typography>
              </Box>
            )}
            <ReportsHomeList />
          </>
        )}
      </Container>
    </>
  );
}
