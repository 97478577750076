import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import { DashboardContext } from '@institutegc/digital-dashboard-ui/src/_lib/context/dashboard-context';
import { ROUTE_CONTENT_PERMISSION } from '@institutegc/digital-dashboard-ui/src/_lib/graphql/queries';

import { logError } from '@institutegc/digital-dashboard-ui/src/_lib/error';
import { routesPermissions } from './routes';
import { contentPermissions } from './content';

const ROUTE_CONTENT_PERMISSION_SUBSCRIPTION = gql`
  subscription RouteContentPermission($contentId: ID) {
    routeContentPermission(contentId: $contentId) {
      reload
    }
  }
`;

function RoutePermissionsSubscription({
  subscribeToMore,
}: {
  subscribeToMore: () => () => void;
}) {
  React.useEffect(() => {
    return subscribeToMore();
  }, [subscribeToMore]);
  return null;
}

export function RoutePermissions({ children }: any) {
  const { i18n, t } = useTranslation();
  const { dashboard, user } = React.useContext(DashboardContext);

  const location = useLocation();
  const history = useHistory();

  // Routes permissions
  const redirect = history.push;

  // Check if content ID passed
  const params = new URLSearchParams(location.search);
  const contentId = params.get('content');

  // fetch content permissions
  const {
    data: routeContentPermissionData,
    error,
    subscribeToMore,
  } = useQuery(ROUTE_CONTENT_PERMISSION, {
    variables: { contentId },
    fetchPolicy: 'no-cache',
  });

  // ---------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  React.useEffect(() => {
    const requestedUrl = location.pathname;

    if (i18n.language === dashboard?.language) {
      if (!contentId) {
        routesPermissions({
          user,
          dashboard,
          requestedUrl,
          redirect,
          t,
          allowedRoutes: [],
        });
      }
      if (
        contentId &&
        routeContentPermissionData &&
        contentPermissions(routeContentPermissionData.routeContentPermission)
      ) {
        routesPermissions({
          user,
          dashboard,
          requestedUrl,
          redirect,
          t,
          allowedRoutes: [],
        });
      }
    }
  }, [
    user,
    dashboard,
    i18n,
    t,
    redirect,
    routeContentPermissionData,
    contentId,
    location.pathname,
  ]);

  // ---------------------------------------------------------------------------------------------------------

  return (
    <>
      {children}

      {/* subscriptions */}
      {contentId && (
        <RoutePermissionsSubscription
          subscribeToMore={() =>
            subscribeToMore({
              document: ROUTE_CONTENT_PERMISSION_SUBSCRIPTION,
              variables: { contentId },
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                return subscriptionData.data;
              },
            })
          }
        />
      )}
    </>
  );
}
