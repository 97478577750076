import React from 'react';

import { DashboardContext } from '../_lib/context/dashboard-context';

import currencyListJson from '../_lib/data/currency-list.json';

export function CurrencyText({ amount, decimals, dashboard }: any) {
  const { dashboard: cDashboard } = React.useContext(DashboardContext);

  const language = dashboard ? dashboard.language : cDashboard.language;
  const currency = dashboard ? dashboard.currency : cDashboard.currency;

  const currencyList = React.useMemo(() => {
    return currencyListJson;
  }, []);

  const currencyFull = currencyList.find(
    (c: any) => `${c.entity} :: ${c.alpha_code}` === currency
  );

  const amountParsed = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyFull?.alpha_code || 'USD',
    minimumFractionDigits: decimals || 0,
  }).format(amount);

  const amountComponents = amountParsed
    .split(/[\u202f]/g)
    .flatMap((e) => [<span style={{ whiteSpace: 'nowrap' }}>&thinsp;</span>, e])
    .slice(1);
  return (
    <>
      {amountComponents.map((c: any, i: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={i}>{c}</React.Fragment>
      ))}
    </>
  );
}

export function CurrencySymbol() {
  const {
    dashboard: { language, currency },
  } = React.useContext(DashboardContext);

  const currencyList = React.useMemo(() => {
    return currencyListJson;
  }, []);
  const currencyFull = currencyList.find(
    (c: any) => `${c.entity} :: ${c.alpha_code}` === currency
  );

  const symbol = (0)
    .toLocaleString(language, {
      style: 'currency',
      currency: currencyFull?.alpha_code || 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
  return <span>{symbol}</span>;
}
