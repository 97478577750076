import React from 'react';
import { useHistory } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';

export function NavbarAppBarSearchResultsList({
  search,
  setSearch,
  setDialogOpen,
}: any) {
  const history = useHistory();
  const { dashboard, user } = React.useContext(DashboardContext);

  const [priorityAreas, setPriorityAreas] = React.useState<any>(
    user.priorityAreas
  );
  const [interventions, setInterventions] = React.useState<any>(
    user.interventions
  );

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------

  const handleNavigate = (url: any) => {
    history.push(url);
    setSearch('');
    setDialogOpen(false);
  };

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (!search) {
      setPriorityAreas(user.priorityAreas);
      setInterventions(user.interventions);
    } else {
      const pas = user.priorityAreas.filter(
        (area: any) =>
          `${area.reference} - ${area.name}`
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          `${area.dashboardOfDashboards?.dashboard?.name}`
            .toLowerCase()
            .includes(search.toLowerCase())
      );
      const intvs = user.interventions.filter(
        (area: any) =>
          `${area.reference} - ${area.name}`
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          `${area.priorityArea?.reference} - ${area.priorityArea?.name}`
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          `${area.priorityArea?.dashboardOfDashboards?.dashboard?.name}`
            .toLowerCase()
            .includes(search.toLowerCase())
      );
      setPriorityAreas(pas);
      setInterventions(intvs);
    }
  }, [search, user]);

  return (
    <Stack spacing={1}>
      {/* priority areas */}
      {priorityAreas?.length > 0 && (
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              {dashboard.priorityAreaName}
            </ListSubheader>
          }
        >
          {priorityAreas.map((pa: any) => (
            <ListItem key={pa.id} disablePadding>
              <ListItemButton onClick={() => handleNavigate(pa.urlRelative)}>
                <ListItemText
                  primary={`${pa.reference} - ${pa.name}`}
                  primaryTypographyProps={{
                    sx: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                  secondary={
                    dashboard.mode === 'SUPRANATIONAL'
                      ? pa.dashboardOfDashboards?.dashboard?.name
                      : undefined
                  }
                  secondaryTypographyProps={{
                    sx: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}

      {/* interventions */}
      {interventions?.length > 0 && (
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              {dashboard.interventionName}
            </ListSubheader>
          }
        >
          {interventions.map((intv: any) => (
            <ListItem key={intv.id} disablePadding>
              <ListItemButton onClick={() => handleNavigate(intv.urlRelative)}>
                <ListItemText
                  primary={`${intv.reference} - ${intv.name}`}
                  primaryTypographyProps={{
                    sx: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                  secondary={`${intv.priorityArea?.reference} - ${intv.priorityArea?.name}`}
                  secondaryTypographyProps={{
                    sx: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
}
