import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import PasswordStrengthBar from 'react-password-strength-bar';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { CHANGE_PASSWORD } from '../../../_lib/graphql/mutations';
import { logError } from '../../../_lib/error';
import { DialogTitle } from '../../../DialogTitle/dialog-title';

export function ChangePasswordDialog({ open, setOpen }: any) {
  const { t } = useTranslation();

  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [incorrectPassword, setIncorrectPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [score, setScore] = React.useState(0);
  const [error, setError] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD);

  const changePassword = async () => {
    if (newPassword.length < 6) return setError(t('Password too short'));
    if (newPassword !== passwordConfirm)
      return setError(t('Passwords do not match'));
    if (score < 3) return setError(t('Password not strong enough'));
    setError('');
    changePasswordMutation({
      variables: {
        oldPassword,
        newPassword,
      },
    })
      .then(() => {
        setOpen(false);
        window.localStorage.clear();
        window.location.reload();
      })
      .catch((err) => {
        setIncorrectPassword('Incorrect password entered');
        logError(err);
        setLoading(false);
      });
    return null;
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle onClose={() => setOpen(false)}>
        {t('Change Password')}
      </DialogTitle>

      <DialogContent dividers>
        <Box>
          <Stack spacing={2}>
            <TextField
              label={t('Old password')}
              id="old-password-input"
              type="password"
              value={oldPassword}
              onChange={(event) => setOldPassword(event.target.value)}
              error={!!incorrectPassword}
              helperText={incorrectPassword}
              fullWidth
              size="small"
            />

            <TextField
              label={t('New password')}
              id="password-input"
              type="password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              error={!!error}
              fullWidth
              size="small"
            />

            <TextField
              label={t('Confirm New password')}
              id="confirm-password-input"
              type="password"
              value={passwordConfirm}
              onChange={(event) => setPasswordConfirm(event.target.value)}
              error={!!error}
              helperText={error}
              fullWidth
              size="small"
            />

            <PasswordStrengthBar
              password={newPassword}
              onChangeScore={setScore}
              minLength={10}
              shortScoreWord={t('Password must be at least 10 characters')}
              scoreWords={[t('okay'), t('good'), t('strong'), t('very strong')]}
            />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => setOpen(false)}
          disabled={loading}
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={changePassword}
          loading={loading}
          disableElevation
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
