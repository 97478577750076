import { View, Text } from '@react-pdf/renderer';
import { CurrencyText } from '../../../../../../components/currency-text';
import { getFinanceMethods } from '../../../../../../_utils/finance-utils';

export function ReportsPdfTemplatesPreziCountrySectionsFinanceMethods({
  t,
  dashboard,
  reportData,
  areas,
}: any) {
  const financeMethods = getFinanceMethods(areas);
  return (
    <View style={{ width: '100%', textAlign: 'left' }}>
      {financeMethods.map((fm: any, i: number) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <View key={i} style={{ margin: '5px 3px' }}>
            <Text
              style={{
                fontSize: '10px',
                fontStyle: !fm.name ? 'italic' : 'normal',
              }}
            >
              {!fm.name ? t('Method type missing') : fm.name}
            </Text>
            <Text>
              <CurrencyText amount={fm.amount} dashboard={dashboard} />
            </Text>
          </View>
        );
      })}
    </View>
  );
}

export default ReportsPdfTemplatesPreziCountrySectionsFinanceMethods;
